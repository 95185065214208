<template>
  <LoadingButton :loading="loading" class="mr-3 ta-btn-submit" >
    {{ $t(label) }}
  </LoadingButton>
</template>

<script>
import LoadingButton from "../../core/LoadingButton";
export default {
  name: "SignupFormSaveButton",
  components: {LoadingButton},
  props: {
    label: {
      required: false,
      type: String,
      default: "Signup"
    },
    loading: {
      required: true,
      type: Boolean,
    },
    invalid: {
      required: true,
      type: Boolean,
    }
  }
}
</script>

<style scoped>
  button {
    width: 140px;
  }
</style>
