import { render, staticRenderFns } from "./LotActualPrice.vue?vue&type=template&id=89b2e744&scoped=true&"
import script from "./LotActualPrice.vue?vue&type=script&lang=js&"
export * from "./LotActualPrice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89b2e744",
  null
  
)

export default component.exports