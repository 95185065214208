<template>
  <div class="ta-image-picker">
    <div class="ta-image-list">
      <div v-for="(url, index) of imageUrls" :key="index" class="ta-image-item">
        <img :src="url">
        <span class="ta-delete-img" @click="deleteImage(url)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
          </svg>
        </span>
      </div>
    </div>
    <b-form-group :label="$t('Images')">
      <b-form-file id="file-large" accept="image/*" :placeholder="$t('Add Images')"
                   multiple @change="onAddImages"/>
    </b-form-group>
  </div>
</template>

<script>
import {compressAccurately} from 'image-conversion';

export default {
  name: "ImagePicker",
  props: ['value'],
  data: () => ({
    images: [],
    imageUrls: [],
  }),
  watch: {
    value() {
      this.imageUrls = [...this.value];
      // this.readImages();
    }
  },
  methods: {
    onAddImages(ev) {
      this.images = [...this.images, ...ev.target.files]
      // this.emitEvents();
      ev.target.value = '';
      this.readImages();
    },
    emitEvents() {
      this.$emit('input', this.imageUrls);
      this.$emit('change', this.imageUrls);
    },
    async readImages() {
      this.images = await this.resizeImages(this.images);
      this.imageUrls = [];
      const promises = [];
      for (let img of this.images) {
        const promise = this.readImage(img)
        promises.push(promise);
      }

      Promise.all(promises).then(resultOfUrls => {
        this.imageUrls = resultOfUrls;
        this.emitEvents()
      })
    },
    async resizeImages(files) {
      const returnFiles = [];
      for (let file of files) {
        const f = await this.resizeImage(file);
        returnFiles.push(f);
      }
      return returnFiles;
    },
    resizeImage(file) {
      return compressAccurately(file, {
        size: 900,
        accuracy: 0.9,
        type: "image/jpeg",
      })
        .then(blob => new File([blob], file.name, {type: "image/jpeg"}));
    },
    readImage(img) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        }
        reader.onabort = reader.onerror = reject;
        reader.readAsDataURL(img);
      })
    },
    deleteImage(url) {
      const index = this.imageUrls.findIndex(u => u === url);
      this.imageUrls.splice(index, 1)
      this.images.splice(index, 1)

      this.imageUrls = [...this.imageUrls]
      this.images = [...this.images];
      this.emitEvents();
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../bootstrap/import";

.ta-image-list {
  display: flex;
}

.ta-image-item {
  position: relative;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $gray-300;
  margin: 0.5rem;
  transition: 0.3s all;

  .ta-delete-img {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    > svg {
      width: 20px;
    }
  }

  > img {
    max-height: 100%;
    max-width: 100%;
  }

  &:hover {
    border-color: $info;

    .ta-delete-img {
      opacity: 1;
    }
  }
}
</style>
