<template>
  <div v-if="isPagingTypePagination" class="ta-lot-pagination-summary">
    {{
      $t('Showing {start} to {finish} of {total} entries',
        {
          start: (fields.queryParams.page - 1) * fields.queryParams.limit + 1,
          finish: Math.min(fields.queryParams.page * fields.queryParams.limit, lots.totalRows),
          total: lots.totalRows
        })
    }}
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import lotListFilterMixin from "../../../mixins/lotListFilterMixin";

export default {
  name: "LotListPaginationSummary",
  mixins: [lotListFilterMixin],
  computed: {
    ...mapState(['lots']),
    ...mapGetters(['isPagingTypePagination'])
  }
}
</script>

<style scoped>

</style>
