<template>
  <div v-if="auction.viewing_start_date && auction.viewing_end_date">
    <slot></slot>
    <div class="ta-auction-view-dates mb-1">{{ auction.viewing_start_date | datetime }} -
      {{ auction.viewing_end_date | datetime }}
    </div>
  </div>
</template>

<script>

export default {
  name: "AuctionViewingDates",
  components: {},

  props: {
    auction: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
