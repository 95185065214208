<script>
import Vue from 'vue';
import {mapState} from "vuex";
import logger from "../logger";
import templatable from "@/mixins/templatable";
import { getLotsQuery } from '@/helpers';

export default {
  name: "FavoriteLotListItem",
  mixins: [templatable('favoriteLotListItemTemplate')],
  props: {
    lot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    template: `
      <div class="ta-lot-list-item">
        <div class="ta-lot-list-item-inner">
          [[template]]
        </div>
      </div>
    `,
    components: {
      image: '<lot-list-item-image :lot="lot" :lot-inner-page-route="lotInnerPageRoute"/>',
      lotNumber: '<lot-no :lot="lot" />',
      numberOfBids: '<lot-number-of-bids :lot="lot" />',
      title: '<lot-list-item-title :lot="lot" :lot-inner-page-route="lotInnerPageRoute" />',
      collectionInformation: '<lot-collection-information :lot="lot" />',
      startPrice: '<lot-start-price :lot="lot" />',
      buyNowPrice: '<lot-buy-now-price :lot="lot" />',
      addToWatchlist: '<lot-add-to-watchlist :lot="lot" />',
      countdownTimer: '<lot-countdown-timer :lot="lot" />',
      actualPrice: '<LotActualPrice :lot="lot" />'
    },
  }),

  computed: {
    ...mapState(['mainConfig']),
    lotInnerPageRoute() {
      if (this.$route.name === 'PrivateTreatyLots') {
        return {
          name: 'PrivateTreatyLotView',
          params: {lotno: this.lot.uuid}
        }
      }
      return {name: 'LotView', params: {lotno: this.lot.uuid}, query:  getLotsQuery()}
    },
  },
};
</script>

<style lang="scss">
@import "../bootstrap/import.scss";

body.artisio-webapp-page-favorite-lots {
  #artisioTimedAuctions {
    .ta-lot-list-item {
      width: 100%;
      border-top: 1px solid $gray-300;

      &:hover {
        .ta-lot-list-item-inner {
          background-color: #f1f7fc;
        }
      }
    }

    .ta-lot-list-item {
      .ta-lot-start-price,
      .ta-lotno-bids-info {
        width: 140px;
      }
    }

    .ta-lot-list-item-inner {
      display: flex;
      padding: 0.5rem;
      height: 100%;
    }

    .ta-btn-watchlist {
      position: relative;
      top: -0.5rem;
      right: -0.5rem;
    }

    .ta-lot-list.view-type-grid {
      .ta-lot-list-item {
        //width: 280px;
        border: 1px solid $gray-300;
      }

      .ta-lot-start-price,
      .ta-lotno-bids-info {
        width: 100%;
      }

      .ta-lot-list-item-inner {
        flex-direction: column;
        align-items: initial;
        padding: 0;
      }

      .ta-lot-list-item-thumb {
        img,
        .b-skeleton-img {
          width: 100% !important;
        }
      }

      .ta-btn-watchlist {
        top: 0;
        right: 0;
        left: -0.5rem;
        bottom: -0.5rem;
      }

      .ta-lot-list-item-desc {
        padding-bottom: 0;
      }

      .ta-lot-list-item-buttons {
        width: auto;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ta-lot-list-item-title {
      font-size: $font-size-base;

      a {
        color: $gray-700;
        text-decoration: none;

        &:hover {
          color: $gray-900;
        }
      }

      /deep/ p {
        display: inline;
      }
    }

    .ta-lot-list-item-thumb {
      position: relative;

      img {
        width: 240px;
        height: 200px;
        object-fit: contain;
      }
    }

    .ta-lot-list-item-price {
      font-size: $font-size-lg;
      color: #007bff;
      font-weight: bold;
      line-height: 1;
    }

    .ta-lot-list-item-or {
      position: relative;
      text-align: center;
      width: 160px;
      height: 26px;

      &:before,
      &:after {
        content: " ";
        height: 2px;
        width: 40px;
        position: absolute;
        top: 14px;
        background-color: $gray-200;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }

    .ta-lot-list-item-desc {
      flex: 1;
      padding: 0.75rem 1rem;
    }

    .ta-lot-list-item-buttons {
      display: flex;
      //flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      //width: 60px;
      position: relative;
      padding: 0.75rem 1rem;
    }

    .ta-lot-last-bid {
      position: absolute;
      left: -0.75rem;
      top: 0.75rem;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      background: $primary;
      color: $gray-100;
      padding: 0.25rem 0.75rem;
    }

    @include media-breakpoint-down(sm) {
      .view-type-list {
        .ta-lot-list-item-thumb {
          img {
            width: 180px;
            height: 120px;
          }
        }

        .ta-lot-list-item-desc {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
        }

        .ta-lot-list-item-title,
        .ta-lot-list-item-info {
          line-height: 1.25rem;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      .view-type-list {
        .ta-lot-list-item {
          border: 1px solid $gray-300;
        }


        .ta-lot-start-price,
        .ta-lotno-bids-info {
          width: 100%;
        }

        .ta-lot-list-item-inner {
          flex-direction: column;
          align-items: initial;
          padding: 0;
        }

        .ta-lot-list-item-thumb {
          img {
            width: 100%;
            max-width: 100%;
            display: block;
            margin: 0 auto;
            height: 220px;
          }
        }

        .ta-lot-list-item-desc {
          padding: 0.75rem 0.75rem 0;
        }

        .ta-lot-list-item-title,
        .ta-lot-list-item-info,
        .ta-lot-list-item-price {
          line-height: $line-height-base;
        }


        .ta-lot-list-item-buttons {
          padding-top: 0;
          width: auto;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .ta-btn-watchlist {
          top: 0;
          right: 0;
          left: -0.5rem;
          bottom: -0.5rem;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
#artisioTimedAuctions {

  .ta-countdown {
    // position: absolute;
    bottom: 1rem;
    right: 0;
  }
}
</style>
