<template>
  <div class="ta-auction-list-item-thumb">
    <router-link :to="auctionViewRoute">
      <img v-if="imageSrc" :src="imageSrc"/>
      <img v-else src="https://cdn.artisio.co/no_image.jpg">
    </router-link>
  </div>
</template>

<script>
import auctionViewRoute from "@/mixins/auctionHelperMixin.js";

export default {
  name: "AuctionImage",
  mixins: [auctionViewRoute],
  props: {
    auction: {
      required: true,
      type: Object
    },
  },
}
</script>

<style scoped>

</style>
