<script>
import templatable from "@/mixins/templatable";
import LotListAndFilters from "@/components/LotListAndFilters";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "FavoriteLots",
  components: {
    LotListAndFilters
  },
  mixins: [templatable('favoriteLotListTemplate')],
  data: () => ({
    template: `
        [[template]]
    `
  }),
  methods: {
    ...mapActions(['getLots']),
    ...mapMutations(['setFavoriteLotFilters'])
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        this.setFavoriteLotFilters({
          ...this.$route.query,
          auction_uuid: this.auctionUuid
        });
        this.getLots();
      },
    },
  },
}
</script>

<style scoped>

</style>
