<template>
  <div v-if="auction.start_date && auction.end_date">
    <div class="ta-auction-dates mb-2 d-flex align-items-center">
      <small class="mr-2">
        {{$t('Sale Dates: ')}}
      </small>
      <div class="font-weight-semibold">
        {{ auction.start_date | datetime }} -
        {{ auction.end_date | datetime }}
      </div>
    </div>
    <count-down-timer
      v-if="mainConfig.showCountdown"
      :start_date="auction.start_date"
      :end_date="auction.end_date"
    ></count-down-timer>
    <!-- <h6 class="mt-1 font-weight-bolder">
      Found {{ auction.number_of_lots }} lots
    </h6> -->

    <!-- <h6 class="font-weight-bolder mb-2">
      {{
        $t("Currency") +
        ": " +
        auction.currency.code +
        " (" +
        auction.currency.symbol +
        ")"
      }}
    </h6> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import CountDownTimer from "../../components/CountDownTimer";

export default {
  name: "AuctionDates",
  components: { CountDownTimer },
  props: {
    auction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["mainConfig"]),
  },
};
</script>

<style scoped lang="scss">
  .ta-auction-dates {
    font-size: 1.25rem;;
  }
</style>
