<script>
import {mapActions, mapState} from 'vuex';
import FormAlert from "../../core/FormAlert";
import LoadingButton from "../../core/LoadingButton";
import templatable from "@/mixins/templatable";
import BankAccountAddress3 from "@/components/configuration/profile/bank-account/BankAccountAddress3";
import BankAccountAddress2 from "@/components/configuration/profile/bank-account/BankAccountAddress2";
import BankAccountAddress1 from "@/components/configuration/profile/bank-account/BankAccountAddress1";
import BankAccountBankName from "@/components/configuration/profile/bank-account/BankAccountBankName";
import BankAccountSwift from "@/components/configuration/profile/bank-account/BankAccountSwift";
import BankAccountIban from "@/components/configuration/profile/bank-account/BankAccountIban";
import BankAccountNumber from "@/components/configuration/profile/bank-account/BankAccountNumber";
import BankAccountSortCode from "@/components/configuration/profile/bank-account/BankAccountSortCode";
import BankAccountBeneficiary from "@/components/configuration/profile/bank-account/BankAccountBeneficiary";
import SubscribeToNewsletter from "@/components/configuration/profile/SubscribeToNewsletter.vue";

// Billing Address inputs
import BillingZipCode from "@/components/configuration/profile/billing-address/BillingZipCode";
import BillingCity from "@/components/configuration/profile/billing-address/BillingCity";
import BillingState from "@/components/configuration/profile/billing-address/BillingState";
import BillingCountry from "@/components/configuration/profile/billing-address/BillingCountry";
import BillingAddress2 from "@/components/configuration/profile/billing-address/BillingAddress2";
import BillingAddress1 from "@/components/configuration/profile/billing-address/BillingAddress1";

// shipping Address inputs
import ShippingZipCode from "@/components/configuration/profile/shipping-address/ShippingZipCode";
import ShippingCity from "@/components/configuration/profile/shipping-address/ShippingCity";
import ShippingState from "@/components/configuration/profile/shipping-address/ShippingState";
import ShippingCountry from "@/components/configuration/profile/shipping-address/ShippingCountry";
import ShippingAddress2 from "@/components/configuration/profile/shipping-address/ShippingAddress2";
import ShippingAddress1 from "@/components/configuration/profile/shipping-address/ShippingAddress1";
import ShippingSameAsBillingAddress
  from "@/components/configuration/profile/shipping-address/ShippingSameAsBillingAddress";

import IsCompany from "@/components/configuration/profile/profile-information/IsCompany";
import Phone from "@/components/configuration/profile/profile-information/Phone";
import PreferredLanguage from "@/components/configuration/profile/profile-information/PreferredLanguage";
import CompanyName from "@/components/configuration/profile/profile-information/CompanyName";
import PersonalId from "@/components/configuration/profile/profile-information/PersonalId";
import TitleInput from "@/components/configuration/profile/profile-information/TitleInput";
import LastName from "@/components/configuration/profile/profile-information/LastName";
import MiddleName from "@/components/configuration/profile/profile-information/MiddleName";
import FirstName from "@/components/configuration/profile/profile-information/FirstName";
import Email from "@/components/configuration/profile/account-details/Email";
import OldPassword from "@/components/configuration/profile/account-details/OldPassword";
import Password from "@/components/configuration/profile/account-details/Password";
import PasswordConfirmation from "@/components/configuration/profile/account-details/PasswordConfirmation";
import IdentificationNumber from "@/components/configuration/profile/identification-fields/IdentificationNumber.vue";
import IdentificationType from "@/components/configuration/profile/identification-fields/IdentificationType.vue";
import PlaceOfIssue from "@/components/configuration/profile/identification-fields/PlaceOfIssue.vue";
import DocumentIssueDate from "@/components/configuration/profile/identification-fields/DocumentIssueDate.vue";
import DocumentExpirationDate from "@/components/configuration/profile/identification-fields/DocumentExpirationDate.vue";

import PersonalIdentificationDetails from './PersonalIdentificationDetails.vue'
import BankAccountDetails from './BankAccountDetails.vue'
import BillingAddressDetails from './BillingAddressDetails.vue'
import ShippingAddressDetails from './ShippingAddressDetails.vue'

export default {
  name: "ProfileBody",
  mixins: [templatable('profileInputsTemplate')],
  components: {
    BankAccountAddress3,
    BankAccountAddress2,
    BankAccountAddress1,
    BankAccountBankName,
    BankAccountSwift,
    BankAccountIban,
    BankAccountNumber,
    BankAccountSortCode,
    BankAccountBeneficiary,
    SubscribeToNewsletter,

    BillingAddressDetails,
    ShippingAddressDetails,

    BillingZipCode,
    BillingCity,
    BillingState,
    BillingCountry,
    BillingAddress2,
    BillingAddress1,

    ShippingSameAsBillingAddress,
    ShippingZipCode,
    ShippingCity,
    ShippingState,
    ShippingCountry,
    ShippingAddress2,
    ShippingAddress1,

    IsCompany,
    Phone,
    PreferredLanguage,
    CompanyName,
    PersonalId,
    TitleInput,
    LastName,
    MiddleName,
    FirstName,
    Email,
    OldPassword,
    Password,
    PasswordConfirmation,
    LoadingButton,
    FormAlert,
    PersonalIdentificationDetails,
    BankAccountDetails,
    IdentificationNumber,
    IdentificationType,
    PlaceOfIssue,
    DocumentIssueDate,
    DocumentExpirationDate
  },
  data: () => ({
    bankAccountFilled: false,
    paymentCompleted: false,
    template: `
      <div v-if="model" class="ta-profile-body">
      [[template]]
      </div>
    `
  }),
  props: {
    errors: {
      required: false,
      type: Object
    }
  },
  computed: {
    ...mapState({
      model: state => state.profile.model
    })
  },
  watch: {
    // 'model.bank_account': {
    //   deep: true,
    //   handler(newValue) {
    //     this.bankAccountFilled = Object.keys(newValue).filter(key => newValue[key] !== '').length > 0
    //     this.$emit('back-account-update', this.bankAccountFilled)
    //   }
    // },
  },
  methods: {
    ...mapActions(["getCountries", "getTitles"]),
    // eraseBankAccount() {
    //   Object.keys(this.model.bank_account).forEach(key => {
    //     this.model.bank_account[key] = ""
    //   });
    // },
  },
  async beforeMount() {
    await this.getCountries();
    await this.getTitles();
  }
}
</script>

<style scoped>

</style>
