<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-subscribe_to_newsletter"
      type="checkbox"
      @change="inputChange"
      @input="onInput"
      :checkbox_checked_value="true"
      :checkbox_unchecked_value="false"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      rules=""
    />
  </div>
</template>

<script>
import BaseInputMixin from "../../../mixins/profileInputMixin";
import InputWidget from "../../core/InputWidget";

export default {
  name: "SubscribeToNewsletter",
  components: {InputWidget},
  mixins: [BaseInputMixin('subscribe_to_newsletter')],

  data() {
    return {
      defaultLabel: this.$t("Subscribe to Newsletter")
    }
  },
}
</script>

<style scoped>

</style>
