<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-is_company"
      type="checkbox"
      @change="inputChange"
      @input="onInput"
      :checkbox_checked_value="true"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      rules=""
    />
  </div>
</template>

<script>
import BaseInputMixin from "../../../../mixins/profileInputMixin";
import InputWidget from "../../../core/InputWidget";

export default {
  name: "IsCompany",
  components: {InputWidget},
  mixins: [BaseInputMixin('is_company')],

  data() {
    return {
      defaultLabel: this.$t("Is Company")
    }
  },
}
</script>

<style scoped>

</style>
