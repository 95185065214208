<template>
  <div v-if="lot.currency" class="ta-lot-actual-price">
    {{(lot.winning_bid ? lot.winning_bid.amount : (lot.last_bid_amount || lot.start_price)) | asCurrency(lot.currency.code)}}
  </div>
</template>

<script>
/**
 * This should be deleted and LotCurrentPrice should be used in the future
 * @deprecated
 */
export default {
  name: "LotActualPrice",
  props: {
    lot: {
      required: true,
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
