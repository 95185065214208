<script>
import {mapActions, mapMutations, mapState} from "vuex";
import AuctionListFilters from '../views/AuctionListPage/AuctionListFilters.vue'
import AuctionKeywordSearch from '../views/AuctionListPage/AuctionKeywordSearch.vue'
import SortingDropdown from '../views/AuctionListPage/SortingDropdown.vue'
import PerPageSelect from '../views/AuctionListPage/PerPageSelect.vue'
import FavoriteAuctionListItems from '../views/AuctionListPage/FavoriteAuctionListItems.vue'
import PaginationButtons from '../views/AuctionListPage/PaginationButtons.vue'
import PaginationSummary from '../views/AuctionListPage/PaginationSummary.vue'
import SwitchView from '../views/AuctionListPage/SwitchView.vue'
import templatable from "@/mixins/templatable";

export default {
  name: "FavoriteAuctions",
  components: {
    AuctionListFilters,
    SortingDropdown,
    AuctionKeywordSearch,
    FavoriteAuctionListItems,
    PaginationButtons,
    PaginationSummary,
    PerPageSelect,
    SwitchView
  },
  mixins: [templatable('favoriteAuctionListPageTemplate')],
  data: () => ({
    template: `
      <div class="ta-auction-list" >
        [[template]]
      </div>
    `,
  }),
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        this.setFavoriteAuctionFilters({
          ...this.$route.query,
        });
        this.getFavoriteAuctions();
      },
    },
  },
  computed: {
    ...mapState(['auctionListViewType']),
  },
  methods: {
    ...mapActions(['getFavoriteAuctions']),
    ...mapMutations(['setFavoriteAuctionFilters']),
  },
}
</script>


<style scoped lang="scss">
@import "../bootstrap/import";

.ta-auction-list {
  display: flex;
}

.ta-auction-section {
  flex: 1;
}


.ta-auction-search-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.ta-auction-list-header-top {
  display: flex;
}

.ta-auction-list-header,
.ta-auction-list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-per-page-wrapper {
  display: flex;
  align-items: center;
}

.ta-auction-list-footer {
  margin-top: 1rem;
}

.btn-switch-view {
  outline: none;

  &:hover,
  &:focus {
    box-shadow: none;
    border: 1px solid gray
  }
}

@include media-breakpoint-down(xs) {
  .ta-auction-list {
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .ta-auction-list-header-top,
  .ta-auction-list-header {
    flex-direction: column;
  }
}

</style>
