<template>
  <h4 class="ta-lot-list-item-title font-weight-bold">
    <router-link :to="auctionViewRoute" v-html="title">
    </router-link>
  </h4>
</template>

<script>
import auctionViewRoute from "@/mixins/auctionHelperMixin.js";

export default {
  name: "AuctionTitle",
  mixins: [auctionViewRoute],
  props: {
    auction: {
      required: true,
      type: Object
    },
  }
}
</script>

<style scoped>

</style>
