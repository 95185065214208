import i18n from "../i18n";
import {currentLocale} from "../filters/currentLocale";
import {PAGING_TYPE_LOAD_MORE, PAGING_TYPE_PAGINATION, PAGING_TYPE_SCROLL, SOCKET_RECONNECT_DELAY} from "../constants";
import {deepClone} from "../helpers";
import Vue from "vue";
import userConfig from "@/user-config";

export default {
  currentLocale: () => i18n.locale,
  fallbackLocale: () => i18n.fallbackLocale,
  dateFormat: () => 'DD MMM YYYY',
  datetimeFormat: () => 'DD MMM YYYY HH:mm',
  datetimeFormatSeconds: () => 'DD MMM YYYY HH:mm:ss',
  // If user is online and the socket is also opened or if disconnect happened in socket, but it is disconnected for less than it should be disconnected
  isOnline: (state) => state.isOnline && (state.socket.isOpened || (Date.now() - state.socket.disconnectTime) < SOCKET_RECONNECT_DELAY + 200),
  auctionFilterDetails(state) {
    const auction = state.auctions;
    return {
      'departments': auction.departments,
      'categories': auction.categories,
      'countries': auction.countries,
    }
  },
  lotObjectData(state) {
    return state.lotObject.data;
  },

  auctionObjectData(state) {
    return state.auctionObject.data;
  },

  customerMeta(state) {
    return state.customer;
  },

  lotFilterDetails(state) {
    const lot = state.lots;
    return {
      'departments': lot.departments,
      'categories': lot.categories,
    }
  },
  selectedDepartment(state) {
    return state.departments.data.find(d => (state.lotQueryParams.department || []).includes(d.key))
  },

  dynamicFields(state) {
    const fields = [];
    for (let key in state.dynamicFields.data) {
      fields.push({
        key,
        ...state.dynamicFields.data[key],
      });
    }
    return fields;
  },

  filterableDynamicFields(state) {
    const fields = [];
    for (let key in state.dynamicFields.data) {
      fields.push({
        key,
        ...state.dynamicFields.data[key],
      });
    }
    return fields.filter(f => !!f.filterType).sort((a, b) => a.position - b.position);
  },

  categoriesAsOptions(state) {
    return mapCategories(state.categories.data)
  },
  collectionInfoStatesAsOptions(state) {
    if (!state.locationStates.data) return [];

    return state.locationStates.data.map(op => ({
      value: op.state,
      text: {
        [i18n.locale]: op.state
      },
      count: op.items_count,
      children: op.children ? op.children.map(c => ({
        value: c.text,
        text: {
          [i18n.locale]: c.text
        },
        count: c.items_count
      })) : []
    }))
  },

  is_authorized(state) {
    return state.is_authorized;
  },

  isPagingTypePagination(state) {
    return state.mainConfig.paginationType === PAGING_TYPE_PAGINATION;
  },
  isPagingTypeLoadMore(state) {
    return state.mainConfig.paginationType === PAGING_TYPE_LOAD_MORE;
  },
  isPagingTypeScroll(state) {
    return state.mainConfig.paginationType === PAGING_TYPE_SCROLL;
  },
  countryOptions(state) {
    if (state.countries.length === 0) {
      return [];
    }
    let countries = deepClone(state.countries).map(x => {
      return {'value': x.code, 'text': Vue.options.filters.currentLocale(x.name)};
    }).sort((a, b) => {
      if (a.text < b.text) return -1;
      if (a.text > b.text) return 1;
      return 0;
    });
    return [{'text': i18n.t('Select Country') + '...', 'value': null}, ...countries];
  },
  titleOptions(state) {
    if (!state.titles || !state.titles.title_translations || Object.keys(state.titles.title_translations).length === 0) {
      return [];
    }
    const clonedTranslations = deepClone(state.titles).title_translations;
    const preferredLanguage = state.profile.model.preferred_language_code ? state.profile && state.profile.model && state.profile.model.preferred_language_code : i18n.locale;
    let titleData = clonedTranslations[preferredLanguage];
    if (!titleData && i18n.locale !== preferredLanguage) {
      titleData = clonedTranslations[i18n.locale];
    }
    if (!titleData) {
      titleData = clonedTranslations[Object.keys(clonedTranslations)[0]];
    }
    let titles = titleData.map(title => {
      return {'value': title, 'text': title}
    });
    return [{'text': i18n.t('Select Title') + '...', 'value': null}, ...titles];
  },
  signupTitleOptions(state) {
    if (!state.titles || !state.titles.title_translations  || Object.keys(state.titles.title_translations).length === 0) {
      return [];
    }
    const clonedTranslations = deepClone(state.titles).title_translations;
    const preferredLanguage = state.signup.model.preferred_language_code ? state.signup && state.signup.model && state.signup.model.preferred_language_code : i18n.locale;
    let titleData = clonedTranslations[preferredLanguage];
    if (!titleData && i18n.locale !== preferredLanguage) {
      titleData = clonedTranslations[i18n.locale];
    }
    if (!titleData) {
      titleData = clonedTranslations[Object.keys(clonedTranslations)[0]];
    }
    let titles = titleData.map(title => {
      return {'value': title, 'text': title}
    });
    return [{'text': i18n.t('Select Title') + '...', 'value': null}, ...titles];
  },
  availableLanguages(state) {
    if (!state.settings) return [];
    const languages = JSON.parse(state.settings.available_languages || '{}')
    return [
      ...Object.entries(languages).map(([value, text]) => ({value, text}))
    ]
  },
  lots: state => {
    return {
      ...state.lots,
      results: state.lots.results.sort((l1, l2) => {
        // sort lots by status
        let num1 = l1.status === 'published' ? 1 : 2;
        let num2 = l2.status === 'published' ? 1 : 2;

        return num1 - num2;
      })
    }
  },
  lotStatuses(_) {
    const OPTIONS = {
      all: i18n.t('All'),
      upcoming: i18n.t('Upcoming'),
      open: i18n.t('Current'),
      published: i18n.t('Published'),
      sold: i18n.t('Sold'),
      unsold: i18n.t('Unsold'),
      passed: i18n.t('Passed')
    };

    return Object.fromEntries(userConfig.filterLotStatusOptions.map(op => [op, OPTIONS[op]]))
  },
  auctionStatuses(_) {
    return {
      all: i18n.t('All'),
      published: i18n.t('Published'),
      ended: i18n.t('Ended'),
    };
  },
  settings(state) {
    return state.settings;
  },
  bankAccountFilledInSignup(state) {
    return state.signup.model.bank_account.account_name;
  },
  bankAccountFilledInProfile(state) {
    return state.profile.model.bank_account.account_name;
  },
  profilePasswordUpdateFilled(state) {
    return state.profile.model.password || state.profile.model.password_confirmation || state.profile.model.old_password
  }
}

function mapCategories(categories) {
  return categories.map(op => ({
    value: op.key ?? op.code,
    text: op.name,
    count: op.items_count,
    children: mapCategories(op.children || [])
  }));
}
