import {event, pageview} from 'vue-gtag'
import router from "@/router";
import i18n from "@/i18n";

// Triggers
export function auctionClearFilters() {
  triggerEvent('filters_clear', 'auctions', router.app.$route.fullPath)
}

export function auctionChangeFilters() {
  triggerEvent('filters_change', 'auctions', router.app.$route.fullPath)
}

export function auctionPerPageChange() {
  triggerEvent('perpage_change', 'auctions', router.app.$route.fullPath)
}

export function auctionSortingChange() {
  triggerEvent('sorting_change', 'auctions', router.app.$route.fullPath)
}

export function auctionSetViewType(view) {
  triggerEvent('set_grid_view', 'auctions', view)
}

export function auctionView(label) {
  triggerEvent('auction_view', 'auctions', label)
}

export function lotChangeFilters() {
  pageview({
    page_title: router.app.$route.fullPath,
    page_path: router.app.$route.fullPath,
  })
  triggerEvent('filters_change', 'lots', router.app.$route.fullPath)
}

export function lotPerPageChange(label) {
  triggerEvent('perpage_change', 'lots', `per_page=${label}`)
}

export function lotSortingChange(label) {
  triggerEvent('sorting_change', 'lots', label)
}

export function lotSetViewType(view) {
  triggerEvent('set_view_type', 'lots', view)
}

export function lotPrevClick(label) {
  triggerEvent('click_prev', 'lots', label)
}

export function lotNextClick(label) {
  triggerEvent('click_next', 'lots', label)
}

export function lotBackToListClick(label) {
  triggerEvent('click_back_to_list', 'lots', label)
}

export function lotView(label) {
  pageview({
    page_title: i18n.t(`Lot View - {lot_title}`, {lot_title: label})
  })
  triggerEvent('lot_view', 'lots', label)
}

export function lotAddToWatchlist(label) {
  triggerEvent('add_to_watchlist', 'lots', label)
}

export function lotRemoveFromWatchlist(label) {
  triggerEvent('remove_from_watchlist', 'lots', label)
}

export function placeBid(label) {
  triggerEvent('click_place_bid', 'lots', label);
}

export function buyNowClick(label) {
  triggerEvent('click_buy_now', 'lots', label);
}

export function placeBidConfirmYes(label) {
  triggerEvent('click_place_bid_yes', 'lots', label);
}

export function placeBidConfirmNo(label) {
  triggerEvent('click_place_bid_no', 'lots', label);
}

export function placeBidConfirmClose(label) {
  triggerEvent('click_place_bid_close', 'lots', label);
}

export function placeBidMouseEnter(label) {
  triggerEvent('mouse_enter_place_bid', 'lots', label);
}

export function placeBidMouseLeave(label) {
  triggerEvent('mouse_leave_place_bid', 'lots', label);
}

export function setMaxBidClick(label) {
  triggerEvent('click_set_max_bid_click', 'lots', label);
}

export function maxBidSave(label) {
  triggerEvent('set_max_bid', 'lots', label);
}

export function maxBidDelete(label) {
  triggerEvent('delete_max_bid', 'lots', label);
}

export function maxBidModalClose(label) {
  triggerEvent('close_max_bid', 'lots', label);
}

export function viewBidHistory(label) {
  triggerEvent('view_bid_history', 'lots', label);
}

export function imageFullScreen(image) {
  triggerEvent('image_full_screen', 'lots', image);
}

export function triggerEvent(action, category, label) {
  sendGaEvent(action, category, label);
}

export function sendGaEvent(action, category, label) {
  event(action, {
    event_category: category,
    event_label: label
  })
}
