<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-email"
      type="email"
      :label="computedLabel"
      rules="required|email"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
      class="mb-3"
      autocomplete="off"
    />
  </div>
</template>

<script>
import InputWidget from "../../../core/InputWidget";
import BaseInputMixin from "../../../../mixins/signupInputMixin";

export default {
  name: "Email",
  components: {InputWidget},
  mixins: [BaseInputMixin('email')],
  data() {
    return {
      defaultLabel: this.$t('Email Address'),
      defaultPlaceholder: 'name@example.com'
    }
  },
}
</script>

<style scoped>

</style>
