<template>
  <div class="col-sm-12 col-md">
    <abstract-profile-text-input
      id="input-place-of-issue"
      v-model="model"
      :error="errors"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
    />
  </div>
</template>

<script>
import AbstractProfileTextInput from "../core/AbstractProfileTextInput";
import BaseInputMixin from "../../../../mixins/profileInputMixin";
import {mapState} from "vuex";

export default {
  name: "PlaceOfIssue",
  components: {AbstractProfileTextInput},
  mixins: [BaseInputMixin('identification.place_of_issue')],

  data() {
    return {
      defaultLabel: this.$t("Place of Issue")
    }
  },
}
</script>

<style scoped>

</style>
