<template>
  <div class="ta-per-page-wrapper">
    {{ $t('Show') }}
    <b-form-select class="mx-2"
                   v-model="limit"
                   @change="updatePagination"
                   :options="perPageOptions"></b-form-select>
    {{ $t('entries') }}
  </div>
</template>

<script>
import {mapState} from "vuex";
import {LOT_LIST_DEFAULT_LIMIT} from "../../../constants";
import lotListFilterMixin from "../../../mixins/lotListFilterMixin";
import {lotPerPageChange} from "@/triggers";

export default {
  name: "LotListPerPageSelect",
  mixins: [lotListFilterMixin],
  data: () => ({
    limit: LOT_LIST_DEFAULT_LIMIT
  }),
  computed: {
    ...mapState(['perPageOptions']),
  },
  methods: {
    updatePagination() {
      this.fields.setFilters({limit: this.limit});
      this.fields.updateRoute();
      lotPerPageChange(this.limit)
    },
  },
  mounted() {
    this.limit = this.fields.queryParams.limit;
  }
}
</script>

<style lang="scss" scoped>
</style>
