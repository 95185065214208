<script>
import {mapActions, mapMutations, mapGetters, mapState} from "vuex";
import {getDepartmentsForHome} from "../store/actions";
import HomeDepartments from "../components/configuration/home/HomeDepartments";
import {emptyLotFilters} from "../store/mutations";
import templatable from "@/mixins/templatable";

export default {
  name: "Home",
  components: {HomeDepartments},
  mixins: [templatable('homePageTemplate')],
  data: () => ({
    template: `
        <div class="ta-home-page">
            [[template]]
        </div>
    `
  }),
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        this.emptyLotFilters();
        this.setLotFilters({
          ...this.$route.query,
        });
        this.getLots();
        this.getDepartmentsForHome();
      },
    },
  },
  methods: {
    ...mapActions(['getLots', 'getDepartmentsForHome']),
    ...mapGetters(['isPagingTypeLoadMore', 'isPagingTypePagination']),
    ...mapMutations(['emptyLotFilters', 'setLotFilters', 'emptyLots']),
  },
  beforeDestroy() {
    this.emptyLots();
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/mixins";

.ta-home-page {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  @include scrollbars();
}
</style>
