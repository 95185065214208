import {mapState, mapMutations} from "vuex";
import {updateAuctionsRoute, updateFavoriteAuctionsRoute} from "../helpers";

export default {
  computed: {
    ...mapState(['auctionQueryParams', 'favoriteAuctionQueryParams']),
    fields() {
      const mapper = {
        'FavoriteAuctions': {
          queryParams: this.favoriteAuctionQueryParams,
          setFilters: this.setFavoriteAuctionFilters,
          updateRoute: updateFavoriteAuctionsRoute
        },
        'default': {
          queryParams: this.auctionQueryParams,
          setFilters: this.setAuctionFilters,
          updateRoute: updateAuctionsRoute
        }
      }

      return this.$route.name in mapper ? mapper[this.$route.name] : mapper['default'];
    },
  },
  methods: {
    ...mapMutations(['setAuctionFilters', 'setFavoriteAuctionFilters'])
  },
}
