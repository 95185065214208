<template>
  <div class="ta-toast-container position-fixed">
    <b-toast id="main-notification-toast" v-model="toast.visible" :title="toast.title" :variant="toast.variant"
             :href="toast.href"
             :no-close-button="true" :auto-hide-delay="toast.autoHideDelay" solid static>
      <template slot="toast-title">
        <strong v-html="toast.title"></strong>
        <button class="ta-btn-close" @click="$bvToast.hide('main-notification-toast')">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </template>
      <div v-html="toast.text"></div>
    </b-toast>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AtaToast",

  computed: {
    ...mapState(['toast']),
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";

/deep/ .toast-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#artisioTimedAuctions {
  .ta-toast-container {
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 9999;
  }

  button.ta-btn-close {
    @include btn-close()
  }
}
</style>
