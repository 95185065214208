<template>
  <button class="btn btn-primary btn-bid-buy" v-b-modal.placeBidModal>
    {{ $t('Bid / Buy') }}
  </button>
</template>

<script>
export default {
  name: "TriggerBidBuyModalButton"
}
</script>

<style scoped>

</style>
