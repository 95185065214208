import Vue from 'vue';
import Rollbar from 'rollbar';

let token = process.env ? process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN : null

if (token) {
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: token,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: 'production',
    source_map_enabled: true,
    payload: {
      code_version: '1.0.0',
      client_id: window.artisioWebApp.clientId
    }
  });

  Vue.config.errorHandler = (err, vm, info) => {
    vm.$rollbar.error(err);
    throw err;
  };
}


