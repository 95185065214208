<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-billing_address_country"
      type="select"
      @change="inputChange"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      rules="required"
      :options="countryOptions"
    />
  </div>
</template>

<script>
import BaseSignupTextInput from "../core/AbstractSignupTextInput";
import AbstractSignupTextInput from "../core/AbstractSignupTextInput";
import BaseInputMixin from "../../../../mixins/signupInputMixin";
import {mapGetters, mapMutations} from "vuex";
import InputWidget from "../../../core/InputWidget";

export default {
  name: "BillingCountry",
  components: {InputWidget, AbstractSignupTextInput, BaseSignupTextInput},
  mixins: [BaseInputMixin('billing_address.country_code')],

  data() {
    return {
      name: 'billing_address.country_code',
      defaultLabel: this.$t("Country",)
    }
  },
  computed: {
    ...mapGetters(['countryOptions'])
  },
  methods: {
    ...mapMutations(['signupFieldOnInput']),
    inputChange(val) {
      this.signupFieldOnInput({field: this.name, value: val})
    }
  }
}
</script>

<style scoped>

</style>
