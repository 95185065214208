<template>
  <div>
    <div class="row">
      <div class="col-sm-8">
        <div class="d-flex justify-content-between align-items-center">
          <b-skeleton animation="wave" width="80%" height="30px"></b-skeleton>
          <b-skeleton animation="wave" width="10%" height="20px"></b-skeleton>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="d-flex justify-content-between">
          <b-skeleton animation="wave" width="120px" height="30px"></b-skeleton>
          <b-skeleton class="rounded-circle" animation="wave" width="30px" height="30px"></b-skeleton>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <b-skeleton-img no-aspect width="100%" height="600px"></b-skeleton-img>
      </div>
      <div class="col-md-4">
        <div class="p-0">
          <div class="mb-4 mt-4">
            <b-skeleton animation="wave" width="80%" height="20px"></b-skeleton>
            <div class="d-flex justify-content-between mt-4">
              <b-skeleton animation="wave" width="80px"></b-skeleton>
              <b-skeleton animation="wave" width="80px"></b-skeleton>
            </div>
            <b-skeleton animation="wave" width="100%" height="40px"></b-skeleton>
            <div class="d-flex justify-content-between">
              <b-skeleton animation="wave" width="90px"></b-skeleton>
              <b-skeleton animation="wave" width="40px"></b-skeleton>
            </div>
          </div>
          <b-skeleton class="mb-4" animation="wave" width="100%" height="50px"></b-skeleton>
          <div class="mb-4 d-flex justify-content-between">
            <b-skeleton animation="wave" width="45%" height="40px"></b-skeleton>
            <b-skeleton animation="wave" width="45%" height="40px"></b-skeleton>
          </div>
          <div>
            <b-skeleton class="mb-3" animation="wave" width="55%"></b-skeleton>
            <b-skeleton class="mb-3" animation="wave" width="85%"></b-skeleton>
            <b-skeleton class="mb-3" animation="wave" width="70%"></b-skeleton>
            <b-skeleton class="mb-3" animation="wave" width="55%"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LotViewSkeleton"
}
</script>

<style scoped>

</style>
