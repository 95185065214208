<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      id="input-identification-number"
      v-model="model"
      :error="errors"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
    />
  </div>
</template>

<script>
import AbstractSignupTextInput from "../core/AbstractSignupTextInput";
import BaseInputMixin from "../../../../mixins/signupInputMixin";
import InputWidget from "@/components/core/InputWidget.vue";

export default {
  name: "IdentificationNumber",
  components: {InputWidget, AbstractSignupTextInput},
  mixins: [BaseInputMixin('identification.personal_identification_number')],

  data() {
    return {
      defaultLabel: this.$t("Identification Number")
    }
  },
}
</script>

<style scoped>

</style>
