<template>
  <div class="ta-auth-card ta-login-form">
    <div class="text-center">
      <h3 class="font-weight-bold mt-3">{{ $t("Login to Your Account") }}</h3>
      <p>
        {{ $t("or") }}
        <router-link :to="{ name: 'Signup' }">
          {{ $t("Signup Now") }}
        </router-link>
      </p>
    </div>

    <FormAlert :success-message="successMessage" :error-message="errorMessage"/>
    <ResendEmail :email="model.email" v-model="resend"/>

    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <InputWidget
          v-model="model.email"
          :error="errors.email"
          id="input-email"
          type="email"
          :label="$t('Email Address')"
          rules="required|email"
          placeholder="name@example.com"
        >
        </InputWidget>

        <InputWidget
          id="input-password"
          v-model="model.password"
          :error="errors.password"
          type="password"
          :label="$t('Password')"
          :show-password-toggle="true"
          rules="required"
        />

        <div class="mb-3 d-flex justify-content-between">
          <b-form-checkbox
            v-model="model.remember"
            name="checkbox-1"
            value="accepted"
            unchecked-value="not_accepted"
          >
            {{ $t("Remember me") }}
          </b-form-checkbox>

          <router-link :to="{ name: 'ForgotPassword' }">
            {{ $t("Forgot Password?") }}
          </router-link>
        </div>

        <LoadingButton block :loading="loading" :disabled="invalid">
          {{ $t("Login") }}
        </LoadingButton>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CONFIG from "../../user-config";
import LoadingButton from "../../components/core/LoadingButton.vue";
import InputWidget from "../../components/core/InputWidget.vue";
import FormAlert from "../../components/core/FormAlert";
import ResendEmail from "../../components/core/ResendEmail";
export default {
  data() {
    return {
      loading: false,
      model: {
        email: null,
        password: null,
        remember: false,
      },
      errors: {
        email: null,
        password: null,
      },
      successMessage: "",
      errorMessage: "",
      resend: {
        messageCode: "",
        secondsLeft: null
      }
    };
  },
  methods: {
    ...mapActions(["login"]),
    onSubmit() {
      this.loading = true;
      this.login(this.model)
        .then((res) => {
          this.loading = false;
          this.successMessage = "";
          this.errorMessage = "";
          this.resend.messageCode = "";
          this.resend.secondsLeft = null;
          if (res.message_code) {
            this.resend.messageCode = res.message_code;
            this.resend.secondsLeft = res.seconds_left;
          } else {
            this.$router.push({
                path: CONFIG.defaultPage,
            });
          }
        })
        .catch((response) => {
          this.loading = false;

          switch (response.status) {
            case 422:
              this.errorMessage = "";
              this.successMessage = response.data.message;
              break;
            default:
              this.successMessage = "";
              this.errorMessage = response.data.message;
          }
        });
    },
  },
  components: {FormAlert, LoadingButton, InputWidget, ResendEmail },
  beforeMount() {
    this.successMessage = this.$route.params?.successMessage ?? "";
  }
};
</script>

<style lang="scss" scoped></style>
