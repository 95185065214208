<template>
  <b-form-checkbox
    class="mb-2 text-nowrap"
    id="sameAsBilling"
    v-model="model"
    :value="true"
    :unchecked-value="false"
    @change="inputChange"
  >
    {{ computedLabel }}
  </b-form-checkbox>
</template>

<script>
import BaseInputMixin from "../../../../mixins/profileInputMixin";
import {mapMutations, mapState} from "vuex";

export default {
  name: "ShippingSameAsBillingAddress",
  mixins: [BaseInputMixin('same_as_billing')],

  data() {
    return {
      defaultLabel: this.$t("Same As Billing Address")
    }
  },
  computed: {
    ...mapState(['profile'])
  },
  methods: {
    ...mapMutations(['profileFieldOnInput']),
    inputChange(val) {
      if (val) {
        this.profileFieldOnInput({field: 'shipping_address', value: this.profile.model.billing_address})
      }
    }
  }
}
</script>

<style scoped>

</style>
