<template>
  <div class="ta-lot-estimate d-flex justify-content-between align-items-center">
    {{ $t('Estimate') }}
    <span class="line-height-sm">
      {{ lot.low | asCurrency(lot.currency.code) }} - {{ lot.high | asCurrency(lot.currency.code) }}
    </span>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BiddingEstimate",
  computed: {
    ...mapState({
      lot: state => state.lotObject.data,
    })
  }
}
</script>

<style scoped>

</style>
