<template>
  <div class="ta-auction-info mb-3">
    <div class="row">
      <!-- <div class="col-sm-3">
        <b-img :src="auction.image_url" fluid></b-img>
      </div> -->
      <div class="col-sm">
        <div>
          <h1 class="ta-auction-view-title mb-3">
            {{ auction.auction_no }} -
            {{ auction.title | currentLocale }}
          </h1>
          <div class="bg-light py-2 px-3 mb-3">
            <div class="row">
              <div class="col-lg-9">
                <div class="d-flex justify-content-between">
                  <auction-countdown-timer :auction="auction" />
                  <div>
                    <b>{{ $t('Start Date') }}:</b> {{ auction.start_date | datetime }}
                  </div>
                  <div>
                    <b>{{ $t('End Date') }}:</b> {{ auction.end_date | datetime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <auction-description :auction="auction" class="mb-3" />
        </div>

        <auction-details />

<!--        <b-tabs content-class="mt-3 mb-5" align="right">-->
<!--          <b-tab :title="$t('Auction Details')" active>-->
<!--            <auction-details :auction="auction"/>-->
<!--          </b-tab>-->
<!--          <b-tab v-if="auction.premium_terms.length > 1" :title="$t('Auction Premiums')">-->
<!--            <auction-buyer-premiums :auction="auction"/>-->
<!--          </b-tab>-->
<!--          <b-tab :title="$t('Increments')">-->
<!--            <auction-increments :auction="auction"/>-->
<!--          </b-tab>-->
<!--        </b-tabs>-->
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AuctionDetails from "./AuctionDetails";
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";
import CountDownTimer from "@/components/CountDownTimer";
import AuctionDescription from "@/views/AuctionViewPage/AuctionDescription";
import AuctionCountdownTimer from "@/views/AuctionListPage/list-item/AuctionCountdownTimer.vue";

export default {
  name: "AuctionInfo",
  components: {
    AuctionCountdownTimer,
    AuctionDescription,
    CountDownTimer,
    AuctionDetails,
  },
  mixins: [auctionViewMixin],
  computed: {
    ...mapGetters(["is_authorized"]),
  }
}
</script>

<style scoped>

</style>
