<template>
  <div v-if="asPopover">
    <b-popover
      :target="target"
      container="artisioTimedAuctions"
      :show.sync="stateObject.show"
      :placement="placement"
      triggers=""
    >
      <template #title>
        <b-button @click="$emit('hidePopover')" variant="link" class="close btn-transparent" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        {{ $t("Invalid Bid Amount") }}
      </template>
      <div>
        <b-alert variant="danger" show>{{ text }}</b-alert>
        <div class="d-flex justify-content-around">
          <b-button v-if="stateObject.low" variant="primary" @click="setAmount(stateObject.low)">{{ $t("Bid") }} {{
              stateObject.low | asCurrency(currencyCode)
            }}</b-button>
          <span v-if="stateObject.low" class="my-auto">{{ $t("Or") }}</span>
          <b-button variant="primary" @click="setAmount(stateObject.high)">{{ $t("Bid") }} {{
              stateObject.high | asCurrency(currencyCode)
            }}</b-button>
        </div>
      </div>
    </b-popover>
  </div>
  <div v-else>
    <div v-if="stateObject.show">
      <b-alert variant="danger" show>{{ text }}</b-alert>
      <div class="d-flex justify-content-around">
        <b-button v-if="stateObject.low" variant="primary" @click="setAmount(stateObject.low)">{{ $t("Submit") }} {{
            stateObject.low | asCurrency(currencyCode)
          }}</b-button>
        <span v-if="stateObject.low" class="my-auto">{{ $t("Or") }}</span>
        <b-button variant="primary" @click="setAmount(stateObject.high)">{{ $t("Submit") }} {{
            stateObject.high | asCurrency(currencyCode)
          }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpcomingAmountPopover",
  props: {
    target: {
      type: String,
      required: true
    },
    stateObject: {
      type: Object,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    currencyCode: {
      type: String,
      required: true
    },
    asPopover: {
      type: Boolean,
      default: true
    },
    placement: {
      type: String,
      default: "bottom",
      required: false
    },
  },
  methods: {
    setAmount(amount) {
      this.$emit('hidePopover');
      this.$emit('setNewAmount', amount);
    }
  },
  beforeDestroy() {
    this.$emit('hidePopover');
  }
}
</script>

<style scoped>

</style>
