<script>
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
import Carousel from "../components/Carousel";
import LotViewBidding from "../components/configuration/lot-view/LotViewBidding";
import LotInfo from "../components/LotInfo";
import SocialShareIcons from "../components/lot-view/SocialShareIcons";
import LotViewSkeleton from "../components/lot-view/LotViewSkeleton"
import PageComponent from "../components/core/PageComponent";
import LatestBidsModal from "../components/lot-view/LatestBidsModal";
import SocialSharePopup from "@/components/lot-view/sidebar/SocialSharePopup";
import WatchlistButton from "@/components/lot-view/sidebar/WatchlistButton";
import WatchlistCount from "@/components/lot-view/sidebar/WatchlistCount";
import logger from "../logger";
import templatable from "@/mixins/templatable";
import PlaceBidModal from "@/components/lot-view/PlaceBidModal";
import TriggerBidBuyModalButton from "@/components/lot-view/TriggerBidBuyModalButton";
import dynamicFieldsMixin from "@/mixins/dynamicFieldsMixin";
import {lotView} from "@/triggers";
import lotHelperMixin from "@/mixins/lotHelperMixin";

export default {
  name: "LotView",

  components: {
    PageComponent,
    LotViewSkeleton,
    SocialShareIcons,
    LotInfo,
    LotViewBidding,
    Carousel,
    LatestBidsModal,
    WatchlistButton,
    WatchlistCount,
    SocialSharePopup,
    PlaceBidModal,
    TriggerBidBuyModalButton
  },
  mixins: [templatable('lotViewTemplate'), dynamicFieldsMixin, lotHelperMixin],
  data: () => ({
    notFound: false,
    scrolled: 0,
    template: `
      <page-component ref="page" class="ta-lot-view">
        <div v-if="notFound" class="ta-lot-not-found">
            <h3 class="text-center">404, {{ $t('Lot does not exist') }}</h3>
        </div>
        <div v-else-if="lotObject.loading || !auctionObject.data.increments">
          <lot-view-skeleton/>
        </div>
        <template v-else>
          [[template]]
        </template>
        <LatestBidsModal />
        <PlaceBidModal />
      </page-component>`
  }),
  computed: {
    ...mapState(['lotObject', 'auctionObject', 'mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data,
      loginFinished: state => state.socket.loginFinished
    }),
    ...mapGetters(['is_authorized', 'isOnline']),
  },
  watch: {
    loginFinished() {
      if (this.lot.is_dynamic) {
        this.getLotDataSocket([this.lot.uuid]);
      }
    },
    '$route.query': {
      immediate: true,
      async handler() {
        this.setLotFilters({
          ...this.$route.query
        });
        try {
          this.notFound = false;
          const {status} = await this.getLotData(this.$route.params.lotno);
          if (parseInt(status) === 404) {
            this.notFound = status;
            return;
          }
          lotView(this.title)
        } catch (e) {
          logger.error(e)
        }

        // await this.getAuctionData(this.lot.auction_uuid);

        const auction = this.auctionObject.data;
        if ((auction.manual_bidder_approval || auction.initial_deposit_amount || auction.type === 'live') && !auction.is_bidder && auction.status === 'published') {
          // Register as bidder button should be displayed
        } else {
          // Automatically register bidder
          await this.registerAsBidder({auction});
        }
      },
    }
  },
  methods: {
    ...mapActions(['getLotData', 'getAuctionData', 'getCustomerMeta', 'registerAsBidder', 'getLotDataSocket']),
    ...mapMutations(['setLotObjectData', 'setLotFilters']),
    onWindowScroll() {
      this.scrolled++;
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.onWindowScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onWindowScroll)
    this.setLotObjectData({})
  },
  render(h, context) {
    if (!this.templateRender) {
      return h('div', 'Loading...')
    }
    setTimeout(() => {
      if (this.scrolled < 5) {
        const html = document.querySelector('html')
        if (this.$refs.page) {
          const el = this.$refs.page.$el;
          if (el.offsetTop < html.scrollTop || el.offsetTop > html.scrollTop + window.outerHeight) {
            html.scrollTop = el.offsetTop;
          }
        }
      }
    }, 1000)

    window.localStorage.setItem('LotListItems.scroll', true)

    return this.templateRender();
  },
}
</script>

<style scoped lang="scss">
@import "../bootstrap/import";
@import "../scss/mixins";

#artisioTimedAuctions {
  .ta-lot-breadcrumbs-wrapper {
    border-bottom: 2px solid $gray-200;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
  }

  .ta-lot-breadcrumbs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ta-jump-to-lot {
    width: 9rem;
  }

  .ta-lot-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ta-lot-title {
    font-size: 1.5rem;
    min-height: 40px;
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    .ta-lot-title-wrapper {
      flex-direction: column;
      align-items: start;
    }
    .ta-lot-title {
      font-size: 1.25rem;
    }
    .ta-lot-next-previous {
      border-top: 2px solid $gray-200;
      //margin-top: 1rem;
    }
    .ta-lot-next-previous {
      margin-bottom: 1rem;
    }

  }

  @include media-breakpoint-down(xs) {
    .ta-lot-breadcrumbs {
      flex-direction: column;
      align-items: start;
    }
    .ta-jump-to-lot {
      width: 100%;
      margin-bottom: 1rem;
    }
  }


  @include media-breakpoint-down(sm) {

    .ta-lot-view-content {
      padding: 0;
    }
  }
}

.ta-lot-view {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ta-lot-view-wrapper {
  flex: 1;
  overflow: visible;
}

#artisioTimedAuctions {
  .ta-lot-view-content {
    height: 100%;
    overflow: auto;
    @include scrollbars();
  }
}

.ta-lot-view-bidding {
  height: 100%;
  overflow: visible;
  @include scrollbars();
}

.ta-bidding-actions-buttons-on-mobile {
  display: none;
  padding: 1rem;
  gap: 1rem;
  //position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1020;
  height: 80px;

  .btn-bid-buy {
    flex: 1
  }
}

@include media-breakpoint-down(md) {
  .ta-bidding-actions-buttons-on-mobile {
    display: flex;
  }
}
</style>
