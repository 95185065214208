<template>
  <div v-if="mainConfig.enableAuth && !is_authorized">
    <hr>
    <div class="row">
      <div class="col">
        <a @click="onSignup($event)"
           class="btn btn-primary ta-btn-signup btn-block justify-content-center d-flex align-items-center">
          <svg style="width: 20px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
          </svg>
          {{ $t('Sign up') }}
        </a>
      </div>
      <div class="col">
        <a @click="onLogin($event)"
           class="btn btn-secondary ta-btn-login btn-block justify-content-center d-flex align-items-center">
          <svg style="width: 20px;" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"/>
          </svg>
          {{ $t('Log in') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {openLogin, openSignup} from "@/helpers";

export default {
  name: "LoginSignupButtons",
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['is_authorized'])
  },
  methods: {
    ...mapActions(['loginHandler', 'signupHandler']),
    onSignup(ev) {
      openSignup(ev)
    },
    onLogin(ev) {
      openLogin(ev)
    }
  }
}
</script>

<style scoped>

</style>
