<template>
  <div class="ta-per-page-wrapper">
    {{ $t('Show') }}
    <b-form-select class="mx-2"
                   v-model="limit"
                   @change="updatePagination"
                   :options="perPageOptions"></b-form-select>
    {{ $t('entries') }}
  </div>
</template>

<script>
import {mapState} from "vuex";
import {AUCTION_LIST_DEFAULT_LIMIT} from "../../constants";
import auctionListFilterMixin from "../../mixins/auctionListFilterMixin";
import {auctionPerPageChange} from "@/triggers";

export default {
  name: "AuctionListPerPageSelect",
  mixins: [auctionListFilterMixin],
  data: () => ({
    limit: AUCTION_LIST_DEFAULT_LIMIT
  }),
  computed: {
    ...mapState(['perPageOptions'])
  },
  methods: {
    updatePagination() {
      this.fields.setFilters({limit: this.limit});
      this.fields.updateRoute();
      auctionPerPageChange();
    },
  },
  mounted() {
    this.limit = this.fields.queryParams.limit;
  }
}
</script>

<style lang="scss" scoped>
  .ta-per-page-wrapper {
    margin-bottom: 1rem;
  }
</style>
