<template>
  <a :href="`mailto:${mainConfig.makeAnEnquiryEmail}?Subject=${$options.filters.currentLocale(lot.title)}`"
     target="_blank"
     class="btn btn-primary btn-block ta-btn-make-an-enquiry"
      :class="{'disabled': lot.status !== 'published'}">
    {{ $t('Make an Enquiry') }}
  </a>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "MakeAnEnquiryButton",
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style scoped>

</style>
