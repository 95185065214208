<template>
  <div v-if="mainConfig.enableSorting" class="d-flex align-items-center flex-nowrap">
    <label class="mr-2 mb-0">{{ $t('Sort') }}: </label>
    <b-form-select
      :options="sortOptions"
      v-model="sort"
      @change="updateSorting"
    ></b-form-select>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import auctionListFilterMixin from "../../mixins/auctionListFilterMixin";
import {auctionSortingChange} from "@/triggers";

export default {
  name: "SortingDropdown",
  mixins: [auctionListFilterMixin],
  data: () => ({
    sort: 'end_date=desc',
  }),
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['dynamicFields']),
    sortOptions() {
      return [
        {text: this.$t('Newest (Published)'), value: 'publish_date=desc'},
        {text: this.$t('Least time Left'), value: 'end_date=asc'},
        {text: this.$t('Most time Left'), value: 'end_date=desc'},
        {text: this.$t('Lowest Auction Number'), value: 'auction_no=asc'},
        {text: this.$t('Highest Auction Number'), value: 'auction_no=desc'},
      ].sort((a, b) => a.text < b.text ? -1 : 1)
    }
  },
  methods: {
    updateSorting() {
      let [sort, sortBy] = this.sort.split('=');
      this.fields.setFilters({
        sort,
        'sort-by': sortBy
      });
      this.fields.updateRoute();
      auctionSortingChange()
    },
  },

  mounted() {
    const sort = this.fields.queryParams.sort;
    const sortBy = this.fields.queryParams['sort-by'];
    if (sort && sortBy) {
      this.sort = `${sort}=${sortBy}`;
    }
  }
}
</script>

<style scoped>

</style>
