<template>
  <div v-if="selectedDepartment">
    <div v-for="field of filterableDynamicFields" :key="field.key">
      <filter-group :title="field.label" :id="'collapse-for-' + field.key">
        <template v-if="field.filterType === 'checkbox'">
          <!--              <pre>{{dynamic_fields}}</pre>-->
          <checkbox-list
            v-model="dynamic_fields[field.key]"
            @change="filtersChanged"
            :label="field.label"
            :options="prepareDynamicFieldOptions(field.rules.choices)"/>
        </template>
        <div v-else-if="field.filterType === 'text'">
          <b-form-input v-model="dynamic_fields[field.key]" type="text" :placeholder="field.label"
                        @change="filtersChanged"/>
        </div>
        <div v-else-if="field.filterType === 'intrange'">
          <div class="row">
            <div class="col">
              <b-form-input v-model="dynamic_fields[field.key+'_from']" type="number" :placeholder="$t('Min')"
                            @change="filtersChanged"/>
            </div>
            <div class="col">
              <b-form-input v-model="dynamic_fields[field.key+'_to']" type="number" :placeholder="$t('Max')"
                            @change="filtersChanged"/>
            </div>
          </div>
        </div>
      </filter-group>
    </div>
  </div>
</template>

<script>
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import {updateLotsRoute} from "../../../helpers";
import {mapGetters, mapMutations, mapState} from "vuex";
import FilterGroup from "../../FilterGroup";
import CheckboxList from "../../lot-filters/CheckboxList";
import userConfig from "@/user-config";

export default {
  name: "FilterDynamicFields",
  components: {CheckboxList, FilterGroup},
  data: () => ({
    dynamic_fields: {}
  }),
  computed: {
    ...mapState(['lotQueryParams']),
    ...mapGetters(['selectedDepartment', 'filterableDynamicFields'])
  },
  watch: {
    lotQueryParams() {
      this.updateFiltersFromVuex()
    }
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged() {
      this.setLotFilters({
        dynamicFields: this.dynamic_fields,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      if (userConfig.autoApplyFilters) {
        updateLotsRoute();
      }
    },
    prepareDynamicFieldOptions(options) {
      return (options || []).map(op => ({
        value: op.value,
        text: op.key,
        count: op.items_count || 0,
      }))
    },

    updateFiltersFromVuex() {
      const query = this.lotQueryParams;
      this.dynamic_fields = {};
      for (let key in query) {
        // If query parameter starts with `dynamic_fields`
        if (key.indexOf('dynamic_fields-') === 0) {
          this.dynamic_fields[key.replace('dynamic_fields-', '')] = query[key];
        }
      }
      this.dynamic_fields = {...this.dynamic_fields};
    },
  },
  mounted() {
    this.updateFiltersFromVuex()
  },
  destroyed() {
    this.setLotFilters({
      dynamicFields: {},
    });
  }
}
</script>

<style scoped>

</style>
