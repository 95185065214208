<template>
  <h3 class="text-center">404, Not found</h3>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>