<template>
  <filter-group :title="$t('Lot Range')" id="collapse-for-lotno">
    <div class="row">
      <div class="col">
        <b-form-input
          type="number"
          v-model="lot_no_from"
          v-debounce:1000ms="filtersChanged"
          :placeholder="$t('Low')"
        ></b-form-input>
      </div>
      <div class="col">
        <b-form-input
          type="number"
          v-model="lot_no_to"
          v-debounce:1000ms="filtersChanged"
          :placeholder="$t('High')"
        ></b-form-input>
      </div>
    </div>
  </filter-group>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import {updateLotsRoute} from "../../../helpers";
import FilterGroup from "../../FilterGroup";
import userConfig from "@/user-config";

export default {
  name: "FilterLotRange",
  components: {FilterGroup},
  data: () => ({
    lot_no_from: null,
    lot_no_to: null,
  }),
  computed: {
    ...mapState(['lotQueryParams']),
  },
  watch: {
    lotQueryParams() {
      this.lot_no_from = this.lotQueryParams.lot_no_from
      this.lot_no_to = this.lotQueryParams.lot_no_to
    },
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged() {
      this.setLotFilters({
        lot_no_from: this.lot_no_from,
        lot_no_to: this.lot_no_to,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      if (userConfig.autoApplyFilters) {
        updateLotsRoute();
      }
    }
  },
  mounted() {
    this.lot_no_from = this.lotQueryParams.lot_no_from
    this.lot_no_to = this.lotQueryParams.lot_no_to
  },
  destroyed() {
    this.setLotFilters({
      lot_no_from: null,
      lot_no_to: null,
    });
  }
}
</script>

<style scoped>

</style>
