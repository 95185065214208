<template>
  <div v-if="isPagingTypePagination" class="ta-lot-pagination-summary">
    {{
      $t('Showing {start} to {finish} of {total} entries',
        {
          start: (fields.queryParams.page - 1) * fields.queryParams.limit + 1,
          finish: Math.min(fields.queryParams.page * fields.queryParams.limit, auctions.totalRows),
          total: auctions.totalRows
        })
    }}
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import auctionListFilterMixin from "../../mixins/auctionListFilterMixin";

export default {
  name: "PaginationSummary",
  mixins: [auctionListFilterMixin],
  computed: {
    ...mapState(['auctions']),
    ...mapGetters(['isPagingTypePagination'])
  }
}
</script>

<style scoped>

</style>
