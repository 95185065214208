<template>
  <button class="ta-filter-menu-btn d-lg-none" @click="filtersMenuClick">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
         style="width: 24px;">
      <path stroke-linecap="round" stroke-linejoin="round"
            d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"/>
    </svg>

  </button>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "LotListFilterMobileButton",
  methods: {
    ...mapMutations(['setFiltersMenuOpenMobile']),
    filtersMenuClick() {
      this.setFiltersMenuOpenMobile(true);
    },
  }
}
</script>

<style scoped>

</style>
