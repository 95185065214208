<template>
  <InputWidget
    v-model="model"
    :error="errors"
    :id="id"
    :type="type"
    @change="abstractChange"
    :label="label"
    :placeholder="placeholder"
    :rules="rules"
  >
  </InputWidget>
</template>

<script>
import InputWidget from "../../../core/InputWidget";
import VModelMixin from "@/mixins/vModelMixin";

export default {
  name: "AbstractSignupTextInput",
  components: {InputWidget},
  mixins: [VModelMixin],
  props: {
    id: {
      required: true,
      type: String
    },
    rules: {
      required: false,
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: 'text'
    },
    errors: {
      required: false,
    },
    label: {
      required: false,
      default: function () {
        return this.defaultLabel
      }
    },
    placeholder: {
      required: false,
      default: function () {
        return this.defaultPlaceholder
      }
    },
  },
  methods: {
    abstractChange() {
      this.inputChange();
      this.$emit('change');
    }
  }
}
</script>

<style scoped>

</style>
