<template>
  <div class="d-flex">
    <div class="ta-auth-card ta-signup-form">
    <div class="text-center">
      <h3 class="font-weight-bold mt-3">{{ $t("Create an Account") }}</h3>
      <p>
        {{ $t("or") }}
        <link-to-login>{{ $t("Login with existing account") }}</link-to-login>
      </p>
    </div>

    <div class="ta-signup">
      <ValidationObserver ref="signupValidationObserver" v-slot="{ handleSubmit, invalid, loading }">
        <form ref="signupForm" @submit.prevent="validateForm(handleSubmit)">
          <b-alert :show="$refs.wizard && $refs.wizard.activeTabIndex > 0 && hasValidationError" variant="danger">
            {{ $t('You have validation errors. Please go to the previous step and fix your errors.') }}
          </b-alert>
          <form-wizard color="" ref="wizard" v-if="payment_on_registration" title="" subtitle="">
            <tab-content title="Personal details">
              <FormAlert :success-message="successMessage" :error-message="errorMessage"/>
              <signup-body/>
            </tab-content>
            <tab-content title="Payment Details" :lazy="true">
              <registration-payment-form v-if="showPaymentFrame" @on-payment-complete="onPaymentComplete"/>
            </tab-content>
            <template slot="footer" slot-scope="props">
              <div class="wizard-footer-left">
                <b-button v-if="props.activeTabIndex > 0 && hasValidationError"
                          variant="primary"
                          @click="props.prevTab()">
                  {{ $t('Previous') }}
                </b-button>
              </div>
              <div class="wizard-footer-right">
                <signup-form-save-button v-if="!props.isLastStep"
                                         :loading="loading || false"
                                         class="mr-3 ta-btn-submit"
                                         :label="$t('Proceed')"
                                         @click.native.stop="onNextClick()"
                                         :invalid="invalid"/>
                <!--                <b-button v-if="!props.isLastStep"-->
                <!--                          @click.native="props.nextTab()"-->
                <!--                          class="wizard-footer-right"-->
                <!--                          :style="props.fillButtonStyle">-->
                <!--                  {{ $t('Proceed') }}-->
                <!--                </b-button>-->

                <!--                <wizard-button v-else @click.native="alert('Done')" class="wizard-footer-right finish-button"-->
                <!--                               :style=
                "props.fillButtonStyle"> {{ props.isLastStep ? 'Done' : 'Next' }}-->
                <!--                </wizard-button>-->
              </div>
            </template>
          </form-wizard>
          <div v-else>
            <FormAlert :success-message="successMessage" :error-message="errorMessage"/>
            <signup-body />
            <signup-form-save-button :loading="loading || false" class="mr-3 ta-btn-submit"
                                     :invalid="invalid"></signup-form-save-button>
            <signup-form-reset-button></signup-form-reset-button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import LoadingButton from "../../components/core/LoadingButton.vue";
import InputWidget from "../../components/core/InputWidget";
import {getGenericData} from "../../store/actions";
import FormAlert from "../../components/core/FormAlert";
import {deepClone} from "../../helpers";
import SignupBody from "../../components/configuration/signup/SignupBody";
import LinkToLogin from "../../components/configuration/signup/LinkToLogin";
import SignupFormResetButton from "../../components/configuration/signup/SignupFormResetButton";
import SignupFormSaveButton from "../../components/configuration/signup/SignupFormSaveButton";

import {FormWizard, TabContent, WizardStep} from 'vue-form-wizard'
import RegistrationPaymentForm from "@/components/RegistrationPaymentForm";
import {signupFieldErrors} from "@/store/mutations";

export default {
  name: "Signup",
  components: {
    RegistrationPaymentForm,
    LinkToLogin,

    SignupFormResetButton,
    SignupFormSaveButton,
    SignupBody,
    FormAlert,
    InputWidget,
    LoadingButton,
    FormWizard,
    TabContent,
    WizardStep
  },
  data() {
    return {
      showPaymentFrame: false,
      loading: false,
      hasValidationError: false,
      errorMessage: null,
      successMessage: null,
      // bankAccountFilled: false,
      passwordUpdateFilled: false,
      paymentCompleted: false,
      transactionId: null,
    };
  },
  computed: {
    ...mapGetters(["currentLocale"]),
    ...mapState(["customer", "mainConfig", "settings"]),
    ...mapState({
      model: state => state.signup.model,
      errors: state => state.signup.errors
    }),
    ...mapState({
      'payment_on_registration': state => state.settings && parseInt(state.settings.payment_on_registration)
    })
  },
  watch: {
    'settings.master_language': {
      immediate: true,
      handler() {
        if (this.settings) {
          this.signupFieldOnInput({field: 'preferred_language_code', value: this.settings.master_language})
        }
      }
    }
  },
  methods: {
    ...mapActions(["signup", "getGenericData", "getCustomerMeta",]),
    ...mapMutations(['signupFieldOnInput', 'signupFieldErrors']),
    constructPayload() {
      let payload = deepClone(this.model);
      // if (!this.bankAccountFilled) {
      //   payload.bank_account = {};
      // }
      payload.is_company = !!payload.is_company;
      payload.subscribe_to_newsletter = !!payload.subscribe_to_newsletter;

      return payload;
    },
    // onBankAccountUpdate(value) {
    //   this.bankAccountFilled = value
    // },
    async validateForm(handleSubmit) {
      // before validate
      await handleSubmit(this.onSubmit)
      // after validate
      const invalidInput = this.$refs.signupForm.querySelector('.is-invalid');
      if (invalidInput) {
        invalidInput.scrollIntoView();
      }
    },
    onSubmit() {
      const payload = this.constructPayload();
      if (this.transactionId) {
        payload.transaction_id = this.transactionId;
      }
      this.sendSignupRequest(payload);
    },
    sendSignupRequest(payload) {
      this.loading = true;
      this.signup({payload})
        .then((message) => {
          window.localStorage.removeItem('taSignupTransactionId')
          this.loading = false;
          this.$router.push({name: 'Login', params: {successMessage: message}});
        })
        .catch((errors) => {
          this.loading = false;
          this.hasValidationError = true;
          console.log(this.$refs.wizard);
          this.successMessage = null;
          let billing_address = [];
          let shipping_address = [];
          if (errors.addresses && errors.addresses[0]) {
            if (errors.addresses[0][0]) {
              billing_address = [...errors.addresses[0][0]]
            }
            if (errors.addresses[0][1]) {
              shipping_address = [...errors.addresses[0][1]]
            }
          }

          delete errors.addresses;
          this.signupFieldErrors({
            ...errors,
            billing_address,
            shipping_address
          })
          // Object.keys(errors).forEach(key => this.errors[key] = errors[key]);
        });
    },
    onPaymentComplete(txnId) {
      // window.localStorage.removeItem('txnId');
      // window.localStorage.removeItem('txnUrl');

      this.paymentCompleted = true;
      this.transactionId = txnId;
      const payload = this.constructPayload();
      payload.transaction_id = txnId;
      this.sendSignupRequest(payload);
    },
    onNextClick() {
      if (!this.paymentCompleted) {
        this.showPaymentFrame = true;
        this.$refs.wizard.nextTab()
      }
    }
  },
  mounted() {
    const transactionId = window.localStorage.getItem('taSignupTransactionId');
    if (transactionId) {
      this.paymentCompleted = true;
      this.transactionId = transactionId
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../bootstrap/import.scss";

#artisioTimedAuctions {
  .ta-signup-form {
    width: 800px;
    padding: 2rem;
  }

  .ta-signup-form {
    button {
      width: 140px;
    }
  }
}
</style>
<style lang="scss">
@import "../../bootstrap/import.scss";

#artisioTimedAuctions {
  .vue-form-wizard {
    .wizard-progress-bar {
      background-color: $primary;
    }

    .wizard-nav.wizard-nav-pills {
      > li {
        > a:hover {
          text-decoration: none;
        }

        &.active {
          .wizard-icon-circle {
            background-color: $primary;
          }
        }
      }
    }
  }
}

</style>
