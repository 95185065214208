<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-password-confirmation"
      type="password"
      :show-password-toggle="false"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      rules=""
      @change="inputChange"
      @input="onInput"
      autocomplete="off"
    />
  </div>
</template>

<script>
import BaseInputMixin from "../../../../mixins/profileInputMixin";
import InputWidget from "../../../core/InputWidget";

export default {
  name: "PasswordConfirmation",
  components: {InputWidget},
  mixins: [BaseInputMixin('password_confirmation')],
  data() {
    return {
      defaultLabel: this.$t("Password Confirmation")
    }
  }
}
</script>

<style scoped>

</style>
