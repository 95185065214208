import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['currentLocale', 'fallbackLocale',]),
    dynamicFieldValues() {
      if (!this.lot.dynamic_fields) return '';

      return this.lot.dynamic_fields[this.currentLocale] || this.lot.dynamic_fields[this.fallbackLocale]
    },
    dynamicFields() {
      let entries = Object.entries(this.lot.category.dynamic_fields)
        .map(([key, val]) => [key, val.label]);
      return Object.fromEntries(entries)
    }
  },
}
