<template>
  <filter-group :title="$t('Estimate Range')" id="collapse-for-estimate">
    <div class="row">
      <div class="col">
        <b-form-input
          type="number"
          v-model="estimate_low"
          v-debounce:1000ms="filtersChanged"
          :placeholder="$t('Low')"
        ></b-form-input>
      </div>
      <div class="col">
        <b-form-input
          type="number"
          v-model="estimate_high"
          v-debounce:1000ms="filtersChanged"
          :placeholder="$t('High')"
        ></b-form-input>
      </div>
    </div>
  </filter-group>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import {updateLotsRoute} from "../../../helpers";
import FilterGroup from "../../FilterGroup";
import userConfig from "@/user-config";

export default {
  name: "FilterEstimateRange",
  components: {FilterGroup},
  data: () => ({
    estimate_low: null,
    estimate_high: null,
  }),
  computed: {
    ...mapState(['lotQueryParams']),
  },
  watch: {
    lotQueryParams() {
      this.estimate_low = this.lotQueryParams.estimate_low
      this.estimate_high = this.lotQueryParams.estimate_high
    },
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged() {
      this.setLotFilters({
        estimate_low: this.estimate_low,
        estimate_high: this.estimate_high,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      if (userConfig.autoApplyFilters) {
        updateLotsRoute();
      }
    }
  },
  mounted() {
    this.estimate_low = this.lotQueryParams.estimate_low
    this.estimate_high = this.lotQueryParams.estimate_high
  },
  destroyed() {
    this.setLotFilters({
      estimate_low: null,
      estimate_high: null,
    });
  }
}
</script>

<style scoped>

</style>
