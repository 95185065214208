import Vue from "vue";
import store from '../store';

export function currentLocale(transObj) {
  const locale = store.getters.currentLocale;
  const fallbackLocale = store.getters.fallbackLocale;
  if (!transObj) {
    return transObj;
  }
  return transObj && transObj[locale] ? transObj[locale] :
    (transObj[fallbackLocale] ? transObj[fallbackLocale] : '');
}

Vue.filter('currentLocale', currentLocale)
