<template>
  <div class="ta-carousel" @mouseover="stopTimer" @mouseleave="restartTimer">
    <div class="progressbar" v-if="autoSlideInterval && showProgressBar">
      <div :style="{width: progressBar + '%' }"></div>
    </div>
    <a href="javascript:void(0)" class="ta-carousel-image"
       @click="!mainConfig.dontOpenOnImageClick && $emit('showGallery', activeImageIndex)">
      <div class="ta-carousel-img-wrapper">
        <img :src="activeImage"/>

        <button class="ta-btn-square btn-light ta-btn-back" v-if="mainConfig.backButtonOnImage" @click="goBackToList">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
          </svg>

        </button>

        <button class="ta-btn-square btn-light ta-btn-fullscreen" v-if="mainConfig.fullScreenButtonOnImage"
                @click="onFullScreenClick(activeImageIndex)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path fill="none" d="M0 0h24v24H0V0z"/>
            <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/>
          </svg>
        </button>
      </div>
      <div @click="prevImage" class="ta-carousel-arrow ta-carousel-arrow-left">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
      </div>
      <div @click="nextImage" class="ta-carousel-arrow ta-carousel-arrow-right">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
        </svg>
      </div>
    </a>
    <div class="ta-carousel-thumbnails">
      <div ref="thumbnails" class="">
        <div
          v-for="(image, index) in  images"
          :key="image.id"
          :class="['ta-carousel-thumbnail', (activeImageIndex === index) ? 'active' : '']"
          @mouseenter="activateOnHover(index)"
          @mouseleave="deactivateOnLeave(index)"
          @click="activateImage(index)"
        >
          <img :src="image.thumb">
        </div>
      </div>
      <div @click="scrollPrevThumbnails()" class="ta-carousel-arrow ta-carousel-arrow-left">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
      </div>
      <div @click="scrollNextThumbnails()" class="ta-carousel-arrow ta-carousel-arrow-right">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {goBackToList} from "@/helpers";
import {imageFullScreen} from "@/triggers";
import lotHelperMixin from "@/mixins/lotHelperMixin";

export default {
  name: 'Carousel',

  components: {},
  mixins: [lotHelperMixin],
  props: ['lot', 'startingImage', 'images', 'autoSlideInterval', 'showProgressBar'],
  data() {
    return {
      //Index of the active image
      activeImageIndex: 0,

      //Hold the timeout, so we can clear it when it is needed
      autoSlideTimeout: null,
      //If the timer is stopped e.g. when hovering over the carousel
      stopSlider: false,
      //Hold the time left until changing to the next image
      timeLeft: 0,
      //Hold the interval so we can clear it when needed
      timerInterval: null,
      //Every 10ms decrease the timeLeft
      countdownInterval: 10,

      hoverImageIndex: null
    }
  },

  watch: {
    activeImageIndex() {
      this.timeLeft = this.autoSlideInterval;
    }
  },

  computed: {
    ...mapState(['mainConfig']),
    progressBar() {
      //Calculate the width of the progressbar
      return 100 - (this.timeLeft / this.autoSlideInterval) * 100;
    },
    activeImage() {
      return (this.images[this.hoverImageIndex] || this.images[this.activeImageIndex]).big
    }
  },

  methods: {
    // Go forward on the images array
    // or go at the first image if you can't go forward
    nextImage(ev) {
      ev.stopPropagation()
      var active = this.activeImageIndex + 1;
      if (active >= this.images.length) {
        active = 0;
      }
      this.activateImage(active);
    },
    // Go backwards on the images array
    // or go at the last image
    prevImage(ev) {
      ev.stopPropagation()
      var active = this.activeImageIndex - 1;
      if (active < 0) {
        active = this.images.length - 1;
      }
      this.activateImage(active);
    },
    activateImage(imageIndex) {
      this.activeImageIndex = imageIndex;
    },
    activateOnHover(index) {
      this.hoverImageIndex = index;
    },
    deactivateOnLeave() {
      this.hoverImageIndex = null;
    },
    //Wait until 'interval' and go to the next image;
    startTimer(interval) {
      if (interval && interval > 0 && !this.stopSlider) {
        var self = this;
        clearTimeout(this.autoSlideTimeout);
        this.autoSlideTimeout = setTimeout(function () {
          self.nextImage();
          self.startTimer(self.autoSlideInterval);
        }, interval);
      }
    },
    //Stop the timer when hovering over the carousel
    stopTimer() {
      clearTimeout(this.autoSlideTimeout);
      this.stopSlider = true;
      clearInterval(this.timerInterval);
    },
    //Restart the timer(with 'timeLeft') when leaving from the carousel
    restartTimer() {
      this.stopSlider = false;
      clearInterval(this.timerInterval);
      this.startCountdown();
      this.startTimer(this.timeLeft);
    },
    //Start countdown from 'autoSlideInterval' to 0
    startCountdown() {
      if (!this.showProgressBar) return;
      var self = this;
      this.timerInterval = setInterval(function () {
        self.timeLeft -= self.countdownInterval;
        if (self.timeLeft <= 0) {
          self.timeLeft = self.autoSlideInterval;
        }
      }, this.countdownInterval);
    },
    scrollPrevThumbnails() {
      const el = this.$refs.thumbnails;
      el.scrollLeft -= 100;
    },
    scrollNextThumbnails() {
      const el = this.$refs.thumbnails;
      el.scrollLeft += 100;
    },
    goBackToList() {
      goBackToList(this.$route.query)
    },
    onFullScreenClick(index) {
      imageFullScreen(this.title)
      this.$emit('showGallery', index)
    }
  },
  created() {
    //Check if startingImage prop was given and if the index is inside the images array bounds
    if (this.startingImage
      && this.startingImage >= 0
      && this.startingImage < this.images.length) {
      this.activeImageIndex = this.startingImage;
    }
    //Check if autoSlideInterval prop was given and if it is a positive number
    if (this.autoSlideInterval
      && this.autoSlideInterval > this.countdownInterval) {
      //Start the timer to go to the next image
      this.startTimer(this.autoSlideInterval);
      this.timeLeft = this.autoSlideInterval;
      //Start countdown to show the progressbar
      this.startCountdown();
    }
  },
}
</script>


<style scoped lang="scss">
@import "../bootstrap/import";
@import "~vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";

.ta-carousel {
  user-select: none;
  position: relative;
}

.progressbar {
  display: block;
  width: 100%;
  height: 5px;
  position: absolute;
  background-color: rgba(221, 221, 221, 0.25);
  z-index: 1;
}

.progressbar > div {
  background-color: rgba(255, 255, 255, 0.52);
  height: 100%;
}

#artisioTimedAuctions {
  .ta-carousel-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    height: 600px;
    background-color: $gray-100;

    img {
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      display: block;
      margin: 0 auto;
      object-fit: contain;
    }

    .ta-carousel-img-wrapper {
      position: relative;
      height: 100%;

      .ta-btn-back {
        position: absolute;
        left: 1rem;
        top: 1rem;
        z-index: 1000;
      }

      .ta-btn-fullscreen {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        z-index: 1001;
      }
    }
  }

  .ta-carousel-image:hover,
  .ta-carousel-thumbnails:hover {
    .ta-carousel-arrow {
      background-color: rgba(0, 0, 0, 0.3);

      &:hover {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .ta-carousel-image {
      height: auto;

      img {
        height: 260px;
        width: 100%;
        object-fit: cover;
      }

      .ta-carousel-img-wrapper {
        height: auto;
        width: 100%;
      }
    }
  }
}

.ta-carousel-arrow {
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 250ms;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 100px;
  color: $gray-300;
  z-index: 20;

  svg {
    width: 40px;
  }

  &:hover {
    color: $white;
  }

  &-left {
    left: 0;
  }

  &-right {
    right: 0;
  }
}

.ta-carousel-thumbnails {
  position: relative;
  display: flex;
  overflow: hidden;
  padding: 2px;

  > div {
    overflow-x: auto;
    display: flex;
    padding: 1px 0;
    //margin-bottom: -16px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ta-carousel-arrow {
    width: 32px;
  }
}

.ta-carousel-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  margin: 1px -1px;
  min-width: 100px;
  height: 100px;
  border: 2px solid $gray-300;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &.active {
    z-index: 10;
    border: 2px solid $primary;
  }

  &:hover {
    z-index: 11;
    border: 2px solid $primary;
  }
}

</style>
