<template>
  <filter-group :title="$t('Location')"
                id="collapse-location"
                class="ta-filter-group ta-filter-group-location"
  >
    <location-state-list v-model="model"
                   @change="filtersChanged"
                   :label="$t('Locations')"
                   :options="collectionInfoStatesAsOptions"/>
  </filter-group>
</template>

<script>
import {mapMutations, mapActions, mapState, mapGetters} from "vuex";
import CheckboxList from "@/components/lot-filters/CheckboxList";
import {updateLotsRoute} from "@/helpers";
import {LOT_LIST_DEFAULT_PAGE} from "@/constants";
import FilterGroup from "@/components/FilterGroup";
import userConfig from "@/user-config";
import LocationStateList from "@/components/lot-filters/LocationStateList";

export default {
  name: "FilterLocationList",
  components: {LocationStateList, FilterGroup, CheckboxList},
  data: () => ({
    model: {
      parent: [],
      children: []
    }
  }),
  computed: {
    ...mapState(['lotQueryParams', 'mainConfig']),
    ...mapGetters(['collectionInfoStatesAsOptions'])
  },
  watch: {
    'lotQueryParams.collection_info_states': {
      immediate: true,
      handler() {
        this.model.parent = this.lotQueryParams.collection_info_states || []
      },
    },
    'lotQueryParams.collection_info_municipalities': {
      immediate: true,
      handler() {
        this.model.children = this.lotQueryParams.collection_info_municipalities || []
      },
    }
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged() {
      this.setLotFilters({
        collection_info_states: this.model.parent,
        collection_info_municipalities: this.model.children,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      if (userConfig.autoApplyFilters) {
        updateLotsRoute();
      }
    }
  },
  destroyed() {
    this.setLotFilters({
      collection_info_states: [],
      collection_info_municipalities: [],
    });
  }
}
</script>

<style scoped>

</style>
