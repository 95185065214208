<template>
  <div v-if="mainConfig.showBreadcrumbs || mainConfig.jumpToLot || mainConfig.showPrevNextList"
       class="ta-lot-breadcrumbs-wrapper">
    <slot></slot>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotViewHeader",
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style scoped>

</style>
