<template>
  <div class="ta-page-header">
    <h1 class="ta-page-title">{{ title }}</h1>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: String
  }
}
</script>

<style lang="scss">
#artisioTimedAuctions {
  .ta-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .ta-page-title {
      margin-bottom: 0;
    }
  }
}
</style>
