<template>
  <div>
    <b-form-input
      size="sm"
      v-if="filterable"
      v-model="filterText"
      :placeholder="$t('Type to Filter')"
    />
    <div v-if="computedOptions.length" class="ta-checkbox-list mt-1">
      <div v-for="option of computedOptions" :key="option.value">
        <div class="d-flex ta-checkbox-list-item">
          <span class="ta-checkbox-list-item-caret" v-b-toggle="`checkbox-list-child-${getTextSlug(option.value)}`">
            <svg xmlns="http://www.w3.org/2000/svg" style="width: 16px;" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7"/>
            </svg>
          </span>
          <b-form-checkbox
            :value="true"
            v-model="selected[option.value]"
            @change="onCheckboxChange(option)"
          >
            {{ option.text | currentLocale }} <small v-if="option.count !== undefined">({{ option.count }})</small>
          </b-form-checkbox>
        </div>
        <b-collapse v-model="childrenExpanded[option.value]" :id="`checkbox-list-child-${getTextSlug(option.value)}`"
                    class="ta-checkbox-list-children">
          <b-form-checkbox
            v-for="child of option.children"
            :key="child.value"
            :value="true"
            v-model="childrenSelected[`${option.value}__${child.value}`]"
            @change="onChildCheckboxChange(option, child)"
          >
            {{ child.text | currentLocale }} <small v-if="child.count !== undefined">({{ child.count }})</small>
          </b-form-checkbox>
        </b-collapse>

      </div>
    </div>
    <div v-else class="text-center text-muted pt-3 pb-1">
      {{ $t('No Data') }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NestedCheckboxList",
  props: {
    options: Array,
    label: String,
    filterable: {
      type: Boolean,
      default: true,
    },
    value: [Object]
  },
  data: () => ({
    filterText: null,
    selected: {},
    childrenSelected: {},
    childrenExpanded: {}
  }),
  computed: {
    computedOptions() {
      if (!this.filterText) {
        return this.options;
      }
      const cl = this.$options.filters.currentLocale;
      return this.options.filter((op) => cl(op.text).toLowerCase().includes(this.filterText.toLowerCase()));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.updateSelected()
      }
    },
    computedOptions: {
      immediate: true,
      handler(){
        this.expandChildren()
      }
    }
  },
  methods: {
    onCheckboxChange(option) {
      for (const op of option.children) {
        this.childrenSelected[`${option.value}__${op.value}`] = this.selected[option.value];
      }
      this.emitEvents();
    },
    onChildCheckboxChange(option, childOption) {
      this.selected[option.value] = this.hasAllChildrenSelected(option)
      this.emitEvents();
    },
    emitEvents(){
      const selected = Object.fromEntries(Object.entries(this.selected).filter(([key, value]) => value));
      const childrenSelected = Object.fromEntries(Object.entries(this.childrenSelected).filter(([key, value]) => value));
      this.$emit('input', {parent: Object.keys(selected), children: Object.keys(childrenSelected)});
      this.$emit('change', {parent: Object.keys(selected), children: Object.keys(childrenSelected)});
    },
    updateSelected() {
      const parent = this.value.parent;
      const children = this.value.children;
      if (parent) {
        if (Array.isArray(parent)) {
          parent.forEach(v => this.selected[v] = true);
          if (parent.length === 0) {
            for (let key in this.selected) {
              this.selected[key] = false;
            }
          }
          this.selected = {...this.selected};
        } else {
          this.selected = {
            [parent]: true
          }
        }
      }
      if (children) {
        if (Array.isArray(children)) {
          children.forEach(v => this.childrenSelected[v] = true);
          if (children.length === 0) {
            for (let key in this.childrenSelected) {
              this.childrenSelected[key] = false;
            }
          }
          this.childrenSelected = {...this.childrenSelected};
        } else {
          this.childrenSelected = {
            [children]: true
          }
        }
      }
    },
    getTextSlug(text) {
      return text.toLowerCase().replace(/\s+/g, '-')
    },
    hasChildSelected(option) {
      for (const op of option.children) {
        if (this.childrenSelected[`${option.value}__${op.value}`]) return true;
      }

      return false;
    },
    hasAllChildrenSelected(option) {
      for (const op of option.children) {
        if (!this.childrenSelected[`${option.value}__${op.value}`]) return false;
      }

      return true;
    },
    expandChildren() {
      for (const option of this.options) {
        if (this.hasChildSelected(option) && !this.selected[option.value]) {
          this.childrenExpanded[option.value] = true;
        }
      }
    }
  },
  mounted() {
    this.updateSelected();
  }
};
</script>

<style lang="scss" scoped>
.ta-checkbox-list {
  max-height: 300px;
  overflow: auto;
}

.ta-checkbox-list-item-caret {
  > svg {
    position: relative;
    top: 1px;
    margin-right: 0.5rem;
    transform-origin: center;
    transition: transform 0.3s;
  }
  &.not-collapsed > svg{
    transform: rotate(90deg);
  }
}

.ta-checkbox-list-children {
  padding-left: 2rem;
}

</style>
