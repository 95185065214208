import lotBiddingMixin from "./lotBiddingMixin";
import {mapState, mapMutations} from "vuex";
import {setMaxBidClick} from "@/triggers";
import lotHelperMixin from "@/mixins/lotHelperMixin";

export default {
  mixins: [lotBiddingMixin, lotHelperMixin],
  data: () => ({
  }),
  computed: {
    ...mapState({
      upcomingMaxBidPopover: state => state.lotObject.upcomingMaxBidPopover,
    }),
  },
  methods: {
    ...mapMutations(['setShowMaxBidModal']),
    showMaxBidModal() {
      setMaxBidClick(this.title)
      if (this.biddingFinished) {
        return;
      }
      if (this.is_authorized) {
        this.setShowMaxBidModal(true)
      } else {
        this.show401Notification();
      }
    },
  }
}
