import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState(["auctionObject", "mainConfig"]),
    auction() {
      return this.auctionObject.data;
    },
  }
}
