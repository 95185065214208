<template>
  <div>
    <div v-if="errorMessage" class="alert alert-danger">
      <div>{{ errorMessage }}</div>
      <slot name="errorMessageSlot">

      </slot>
    </div>

    <div v-if="successMessage" class="alert alert-success">
      <div>{{ successMessage }}</div>
      <slot name="successMessageSlot">

      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormAlert",
  props: {
    errorMessage: String,
    successMessage: String
  }
}
</script>

<style scoped>

</style>
