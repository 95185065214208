<template>
  <div v-if="mainConfig.enablePrivateTreaty">
    <template v-if="is_authorized">
      <make-an-offer-button :lot="lot" class="mb-3"/>
      <make-an-enquiry-button :lot="lot"/>
      <hr>
    </template>
    <div v-else class="text-muted text-center py-2">
      <p>{{ $t('Please login to make an offer') }}</p>
      <hr>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "PrivateTreatyActions",
  computed: {
    ...mapState(['mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data
    }),
    ...mapGetters(['is_authorized'])
  }
}
</script>

<style scoped>

</style>
