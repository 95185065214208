<template>
  <div>
    <router-view></router-view>

    <confirm-modal/>
  </div>
</template>

<script>
import ConfirmModal from "@/plugins/confirm-modal/ConfirmModal.vue";

export default {
  name: 'DefaultLayout',
  components: {ConfirmModal}
}
</script>

<style>

</style>
