<template>
  <div v-if="lot.currency" class="ta-lot-actual-price">
    <template v-if="lastBidAmount">
      {{ lastBidAmount | asCurrency(lot.currency.code) }}
    </template>
    <template v-else>
      ({{ $t('Empty') }})
    </template>
  </div>
</template>

<script>
export default {
  name: "LotLastBidAmount",
  props: {
    lot: {
      required: true,
      type: Object
    }
  },
  computed: {
    lastBidAmount() {
      return this.lot.winning_bid ? this.lot.winning_bid.amount : this.lot.last_bid_amount;
    }
  }
}
</script>

<style scoped>

</style>
