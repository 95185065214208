<template>
  <div>
    <b-form-input
      size="sm"
      v-if="filterable"
      v-model="filterText"
      :placeholder="$t('Type to Filter')"
    />
    <div v-if="computedOptions.length" class="ta-checkbox-list">
      <b-form-checkbox
        v-for="option of computedOptions"
        :key="option.value"
        :value="true"
        v-model="selected[option.value]"
        @change="onCheckboxChange(option)"
      >
        {{ option.text | currentLocale }} <small v-if="option.count !== undefined">({{ option.count }})</small>
      </b-form-checkbox>
    </div>
    <div v-else class="text-center text-muted pt-3 pb-1">
      {{ $t('No Data') }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxList",
  props: {
    options: Array,
    label: String,
    filterable: {
      type: Boolean,
      default: true,
    },
    value: [Array, String]
  },
  data: () => ({
    filterText: null,
    selected: {}
  }),
  computed: {
    computedOptions() {
      if (!this.filterText) {
        return this.options;
      }
      const cl = this.$options.filters.currentLocale;
      return this.options.filter((op) => cl(op.text).toLowerCase().includes(this.filterText.toLowerCase()));
    },
  },
  watch: {
    value() {
      this.updateSelected()
    }
  },
  methods: {
    onCheckboxChange(val) {

      const selected = Object.fromEntries(Object.entries(this.selected).filter(([key, value]) => value));
      this.$emit('input', Object.keys(selected));
      this.$emit('change', Object.keys(selected));
    },
    updateSelected() {
      if (this.value) {
        if (Array.isArray(this.value)) {
          this.value.forEach(v => this.selected[v] = true);
          if (this.value.length === 0) {
            for (let key in this.selected) {
              this.selected[key] = false;
            }
          }
          this.selected = {...this.selected};
        } else {
          this.selected = {
            [this.value]: true
          }
        }
      }
    }
  },
  mounted() {
    this.updateSelected();
  }
};
</script>

<style lang="scss" scoped>
.ta-checkbox-list {
  max-height: 300px;
  overflow: auto;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
}

</style>
