<template>
  <lot-view-bidding-timed v-if="lot.auction.type === 'timed'" />
  <lot-view-bidding-live v-else-if="lot.auction.type === 'live'" />
</template>

<script>

import lotBiddingMixin from "@/mixins/lotBiddingMixin";
import LotViewBiddingTimed from "@/components/configuration/lot-view/LotViewBiddingTimed.vue";
import LotViewBiddingLive from "@/components/configuration/lot-view/LotViewBiddingLive.vue";

export default {
  name: "LotViewBidding",
  components: {LotViewBiddingLive, LotViewBiddingTimed},
  mixins: [lotBiddingMixin],
}
</script>

<style lang="scss">
</style>
