<template>
  <div v-if="$options.filters.currentLocale(auction.description)">
    <slot></slot>
    <div v-html="$options.filters.currentLocale(auction.description)">
    </div>
  </div>
</template>

<script>
export default {
  name: "AuctionDescription",

  props: {
    auction: {
      type: Object,
      required: true
    }
  },
}
</script>

<style scoped>

</style>