<template>
  <div class="col-12 mb-3">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-old-password"
      type="password"
      :show-password-toggle="true"
      :label="computedLabel"
      @change="inputChange"
      @input="onInput"
      :rules="`${profilePasswordUpdateFilled ? 'required' : ''}`"
      autocomplete="new-password"
    ></InputWidget>
  </div>
</template>

<script>
import BaseInputMixin from "../../../../mixins/profileInputMixin";
import InputWidget from "../../../core/InputWidget";
import {mapGetters} from "vuex";

export default {
  name: "Password",
  components: {InputWidget},
  mixins: [BaseInputMixin('old_password')],
  data() {
    return {
      defaultLabel: this.$t('Old Password')
    }
  },
  props: {
    showPasswordPolicy: {
      required: false,
      type: Boolean,
      default: true
    },
    showPasswordToggle: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['profilePasswordUpdateFilled'])
  }
}
</script>

<style scoped>

</style>
