import IMadeBidLotListItems from "@/components/configuration/lot-list/IMadeBidLotListItems.vue";

const TEMPLATES = {
  components: {IMadeBidLotListItems},
  lotListItemTemplate: `
    <div class="ta-lot-list-item-image-wrapper">
        <lot-list-item-image :lot="lot" />
        <lot-notify-start-button :lot="lot" />
    </div>
    <lot-winning-status :lot="lot" />
    <div class="ta-lot-list-item-content">
      <div class="ta-lot-list-item-desc">
        <div class="ta-lotno-bids-info d-flex justify-content-between">
          <lot-no :lot="lot" />
          <lot-number-of-bids :lot="lot" />
        </div>
        <lot-list-item-title :lot="lot" />
        <lot-collection-information :lot="lot" />
        <lot-start-price :lot="lot" />
        <lot-buy-now-price :lot="lot" />
      </div>
      <div class="ta-lot-list-item-buttons">
        <lot-add-to-watchlist :lot="lot" />
        <lot-countdown-timer :lot="lot" />
      </div>
    </div>
  `,
  favoriteLotListItemTemplate: `
    <lot-list-item-image :lot="lot" />
    <lot-winning-status :lot="lot" />
    <div class="ta-lot-list-item-desc">
      <div class="ta-lotno-bids-info d-flex justify-content-between">
        <lot-no :lot="lot" />
        <lot-number-of-bids :lot="lot" />
      </div>
      <lot-list-item-title :lot="lot" />
      <lot-collection-information :lot="lot" />
      <lot-start-price :lot="lot" />
      <lot-buy-now-price :lot="lot" />
    </div>
    <div class="ta-lot-list-item-buttons">
      <lot-add-to-watchlist :lot="lot" />
      <lot-countdown-timer :lot="lot" />
    </div>
  `,
  iMadeBidLotListItemTemplate: `
    <lot-list-item-image :lot="lot" />
    <lot-winning-status :lot="lot" />
    <div class="ta-lot-list-item-desc">
      <div class="ta-lotno-bids-info d-flex justify-content-between">
        <lot-no :lot="lot" />
        <lot-number-of-bids :lot="lot" />
      </div>
      <lot-list-item-title :lot="lot" />
      <lot-collection-information :lot="lot" />
      <lot-start-price :lot="lot" />
      <lot-buy-now-price :lot="lot" />
    </div>
    <div class="ta-lot-list-item-buttons">
      <lot-add-to-watchlist :lot="lot" />
      <lot-countdown-timer :lot="lot" />
    </div>
  `,
  lotFiltersTemplate: `
    <!-- Number of items found based on the search criteria -->
    <lot-list-items-count />
    <!-- Lot filtering by status radio list -->
    <filter-lot-status />
    <!-- Department List to filter lots based on -->
    <filter-department-list />
    <!-- Category list to filter lots based on. This component is only displayed if department is selected and categories are filtered by department -->
    <filter-category-list />
    <!-- State and municipality list to filter lots by -->
    <filter-location-list />
    <!-- Min/Max input fields to filter lots by price range -->
    <filter-price-range />
    <!-- Min/Max input fields to filter lots by reserve -->
    <filter-reserve-range />
    <!-- Min/Max input fields to filter lots by low/high estimate -->
    <filter-estimate-range />
    <!-- Min/Max input fields to filter lots by lot no range -->
    <filter-lot-range />
    <!-- Checkbox field to filter lots whether it has image or not -->
    <filter-with-image />
    <!-- The whole dynamic fields to filter lots -->
    <filter-dynamic-fields />
  `,
  lotListTemplate: `
    <lot-list-and-filters>
      <lot-list-filters />
      <div class="ta-lot-section">
        <div class="ta-lot-list-header-top">
          <lot-keyword-search />
          <jump-to-lot />
        </div>
        <hr class="mt-0">
        <div class="ta-lot-list-header ">
          <lot-list-per-page-select />
          <div class="ta-lot-search-wrapper mb-3">
            <sorting-dropdown/>
            <lot-list-switch-view/>
            <lot-list-filter-mobile-button/>
          </div>
        </div>
        <lot-list-items />
      </div>
    </lot-list-and-filters>
  `,
  favoriteLotListTemplate: `
    <lot-list-and-filters>
      <div class="ta-lot-section">
        <div class="ta-lot-list-header-top">
          <lot-keyword-search />
        </div>
        <hr class="mt-0">
        <div class="ta-lot-list-header ">
          <lot-list-per-page-select />
          <div class="ta-lot-search-wrapper mb-3">
            <sorting-dropdown/>
            <lot-list-switch-view/>
            <lot-list-filter-mobile-button/>
          </div>
        </div>
        <favorite-lot-list-items />
      </div>
    </lot-list-and-filters>
  `,
  iMadeBidsLotListTemplate: `
    <lot-list-and-filters>
      <lot-list-filters />
      <div class="ta-lot-section">
        <div class="ta-lot-list-header-top">
          <lot-keyword-search />
        </div>
        <hr class="mt-0">
        <div class="ta-lot-list-header ">
          <lot-list-per-page-select />
          <div class="ta-lot-search-wrapper mb-3">
            <sorting-dropdown/>
            <lot-list-switch-view/>
            <lot-list-filter-mobile-button/>
          </div>
        </div>
        <i-made-bid-lot-list-items />
      </div>
    </lot-list-and-filters>
  `,
  lotViewTemplate: `
    <lot-view-header>
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="ta-lot-breadcrumbs">
            <lot-view-breadcrumbs />
            <jump-to-lot />
          </div>
        </div>
        <div class="col-md-4">
          <lot-view-prev-next-buttons />
        </div>
      </div>
    </lot-view-header>
    <div class="row ta-lot-view-wrapper">
      <div class="col-lg-8 ta-lot-view-content">
        <div class="ta-lot-title-wrapper">
          <lot-view-title/>
          <lot-view-lot-no/>
        </div>
        <lot-view-images/>
        <lot-view-social-share/>
        <lot-view-description class="d-none d-lg-block" />
      </div>
      <div class="col-lg-4 ta-lot-view-bidding">
        <lot-view-bidding/>
      </div>
      <div class="col-lg-8 ta-lot-description-mobile d-block d-lg-none mt-3">
        <lot-view-description />
      </div>
    </div>
  `,
  lotBiddingTemplate: `
    <div class="ta-lot-bidding d-flex flex-column">
      <div class="ta-lot-bidding-timer d-flex align-items-center justify-content-between">
        <lot-countdown-timer :lot="lot"/>
        <watchlist-button :lot="lot"/>
      </div>
      <div class="ta-lot-bidding-content flex-grow-1">
        <lot-collection-information :lot="lot"/>
        <bidding-latest-notification/>
        <bidding-reserve-not-met/>
        <hr>
        <bidding-start-price/>
        <bidding-actions>
          <bidding-current-bid/>
          <bidding-input/>
          <bidding-max-bid/>
          <bidding-buy-now-button/>
        </bidding-actions>
        <bidding-limit />
        <login-signup-buttons/>
        <hr>
        <private-treaty-actions/>
        <LatestBids/>
        <div v-if="mainConfig.descriptionInSidebar">
          <lot-view-description/>
        </div>
        <div v-if="mainConfig.sharesInSidebar">
          <hr>
          <SocialShareIcons :lot="lot"/>
        </div>
        <bidding-additional-links/>
      </div>
    </div>
  `,
  lotBiddingTemplateLive: `
    <div class="ta-lot-bidding d-flex flex-column">
      <div class="ta-lot-bidding-timer d-flex align-items-center justify-content-between">
        <lot-countdown-timer :lot="lot"/>
        <watchlist-button :lot="lot"/>
      </div>
      <div class="ta-lot-bidding-content flex-grow-1">
        <bidding-latest-notification/>
        <hr>
        <bidding-start-price/>
        <bidding-actions>
          <bidding-commission-bid-input />
          <hr>
          <stream-bid-button />
          <bidding-limit />
          <login-signup-buttons/>
        </bidding-actions>
        <div v-if="mainConfig.descriptionInSidebar">
          <hr>
          <lot-view-description/>
        </div>
        <div v-if="mainConfig.sharesInSidebar">
          <hr>
          <SocialShareIcons :lot="lot"/>
        </div>
        <bidding-additional-links/>
      </div>
    </div>
  `,
  homePageTemplate: `
    <home-departments />

    <lot-list-items />
  `,
  signupInputsTemplate: `
    <fieldset class="mb-4">
      <legend>{{ $t("Account Details") }}</legend>
      <div class="row">
        <email />
      </div>
      <div class="row">
        <password />
        <password-confirmation />
      </div>
    </fieldset>

    <fieldset class="mb-4">
      <legend>{{ $t("Profile information") }}</legend>

      <div class="row">
        <first-name />
        <middle-name />
        <last-name />
      </div>

      <div class="row">
        <title-input />
        <personal-id />
        <preferred-language/>
      </div>
      
      <div class="row">
        <company-name/>
        <phone />
      </div>
      <div class="row">
        <is-company />
      </div>
    </fieldset>

    <billing-address-details>
      <legend>{{ $t("Billing Address") }}</legend>
      
      <div class="row">
        <billing-address1 />
        <billing-address2 />
      </div>
      <div class="row">
        <billing-country />
        <billing-state />
      </div>
      <div class="row">
        <billing-city />
        <billing-zip-code />
      </div>
    </billing-address-details>

    <shipping-address-details :title="$t('Shipping Address')">
      
      <div class="row">
        <shipping-address1 />
        <shipping-address2 />
      </div>
      <div class="row">
        <shipping-country />
        <shipping-state />
      </div>
      <div class="row">
        <shipping-city />
        <shipping-zip-code />
      </div>
    </shipping-address-details> 

    <bank-account-details>
      <div class="d-flex justify-content-between align-items-center">
        <legend>{{ $t("Bank Account Details") }}</legend>
      </div>
      <div class="row">
        <bank-account-beneficiary />
        <bank-account-sort-code />
        <bank-account-number />
      </div>
      <div class="row">
        <bank-account-iban />
        <bank-account-swift />
        <bank-account-bank-name />
      </div>
      <div class="row">
        <bank-account-address1 />
        <bank-account-address2 />
        <bank-account-address3 />
      </div>
    </bank-account-details>
    
    <personal-identification-details >
      <div class="d-flex justify-content-between align-items-center">
        <legend>{{ $t("Personal Identification Details") }}</legend>
      </div>
      <div class="row">
        <identification-number />
        <identification-type />
      </div>
      <div class="row">
        <place-of-issue />
        <document-issue-date />
      </div>
      <div class="row">
        <document-expiration-date />
      </div>
    </personal-identification-details>
    
    <div class="row">
        <subscribe-to-newsletter />
    </div>
</div>
  `,
  profileInputsTemplate: `
     <fieldset class="mb-4">
      <legend>{{ $t("Account Details") }}</legend>
      <div class="row">
        <email />
      </div>
      <div class="row">
        <old-password />
      </div>
      <div class="row">
        <password />
        <password-confirmation />
      </div>
    </fieldset>

    <fieldset class="mb-4">
      <legend>{{ $t("Profile information") }}</legend>

      <div class="row">
        <first-name />
        <middle-name />
        <last-name />
      </div>

      <div class="row">
        <title-input />
        <personal-id />
        <preferred-language/>
      </div>
      
      <div class="row">
        <company-name/>
        <phone />
      </div>
      <div class="row">
        <is-company />
      </div>
    </fieldset>

    <billing-address-details>
      <legend>{{ $t("Billing Address") }}</legend>
      
      <div class="row">
        <billing-address1 />
        <billing-address2 />
      </div>
      <div class="row">
        <billing-country />
        <billing-state />
      </div>
      <div class="row">
        <billing-city />
        <billing-zip-code />
      </div>
    </billing-address-details>

    <shipping-address-details :title="$t('Shipping Address')">
      
      <div class="row">
        <shipping-address1 />
        <shipping-address2 />
      </div>
      <div class="row">
        <shipping-country />
        <shipping-state />
      </div>
      <div class="row">
        <shipping-city />
        <shipping-zip-code />
      </div>
    </shipping-address-details> 

    <bank-account-details>
      <div class="d-flex justify-content-between align-items-center">
        <legend>{{ $t("Bank Account Details") }}</legend>
      </div>
      <div class="row">
        <bank-account-beneficiary />
        <bank-account-sort-code />
        <bank-account-number />
      </div>
      <div class="row">
        <bank-account-iban />
        <bank-account-swift />
        <bank-account-bank-name />
      </div>
      <div class="row">
        <bank-account-address1 />
        <bank-account-address2 />
        <bank-account-address3 />
      </div>
    </bank-account-details>
    
    <personal-identification-details >
      <div class="d-flex justify-content-between align-items-center">
        <legend>{{ $t("Personal Identification Details") }}</legend>
      </div>
      <div class="row">
        <identification-number />
        <identification-type />
      </div>
      <div class="row">
        <place-of-issue />
        <document-issue-date />
      </div>
      <div class="row">
        <document-expiration-date />
      </div>
    </personal-identification-details>
    
    <div class="row">
        <subscribe-to-newsletter />
    </div>
  `,
  auctionListItemTemplate: `
    <auction-image :auction="auction"/>
    <div class="ta-auction-list-item-desc">
      <div class="ta-auction-no-info d-flex justify-content-between">
        <auction-no :auction="auction"/>
        <number-of-lots :auction="auction"/>
      </div>
      <auction-title :auction="auction"/>
      <div class="ta-auction-list-item-dates">
        {{ auction.start_date | datetime }} <span v-if="auction.end_date"> - {{ auction.end_date | datetime }}</span>
      </div>
    </div>
    <div class="ta-auction-list-item-buttons">
      <auction-add-to-watchlist :auction="auction"/>
      <auction-countdown-timer :auction="auction" />
    </div>
  `,
  favoriteAuctionListItemTemplate: `
    <auction-image :auction="auction"/>
    <div class="ta-auction-list-item-desc">
      <div class="ta-auction-no-info d-flex justify-content-between">
        <auction-no :auction="auction"/>
        <number-of-lots :auction="auction"/>
      </div>
      <auction-title :auction="auction"/>
      <div class="ta-auction-list-item-dates">
        {{ auction.start_date | datetime }} <span v-if="auction.end_date"> - {{ auction.end_date | datetime }}</span>
      </div>
    </div>
    <div class="ta-auction-list-item-buttons">
      <auction-add-to-watchlist :auction="auction"/>
      <auction-countdown-timer :auction="auction" />
    </div>
  `,
  favoriteAuctionListPageTemplate: `
    <div class="ta-auction-section">
      <div class="ta-auction-list-header-top">
        <auction-keyword-search />
      </div>
      <hr class="mt-0">
      <div class="ta-auction-list-header ">
        <per-page-select />
        <div class="ta-auction-search-wrapper mb-3">
          <sorting-dropdown/>
          <switch-view/>
        </div>
      </div>
      <favorite-auction-list-items />
      <div class="ta-auction-list-footer">
        <pagination-summary/>
        <pagination-buttons/>
      </div>
    </div>
  `,
  auctionListPageTemplate: `
    <auction-list-filters />
    <div class="ta-auction-section">
      <div class="ta-auction-list-header-top">
        <auction-keyword-search />
      </div>
      <hr class="mt-0">
      <div class="ta-auction-list-header ">
        <per-page-select />
        <div class="ta-auction-search-wrapper mb-3">
          <sorting-dropdown/>
          <switch-view/>
        </div>
      </div>
      <auction-list-items />
      <div class="ta-auction-list-footer">
        <pagination-summary/>
        <pagination-buttons/>
      </div>
    </div>
  `,
  auctionFiltersTemplate: `
    <filter-auction-status />
    <filter-department-list />
  `,
  auctionViewPageTemplate: `
    <auction-info/>
    <lot-list-and-filters>
        <lot-list-filters/>
        <div class="ta-lot-section">
            <div class="ta-lot-list-header-top">
                <lot-keyword-search/>
                <jump-to-lot/>
            </div>
            <hr class="mt-0">
            <div class="ta-lot-list-header ">
                <lot-list-per-page-select/>
                <div class="ta-lot-search-wrapper mb-3">
                    <sorting-dropdown/>
                    <lot-list-switch-view/>
                    <lot-list-filter-mobile-button/>
                </div>
            </div>
            <lot-list-items/>
        </div>
    </lot-list-and-filters>
  `,
  placeBidModalTemplate: `
    <ModalLotDetails :lot="lot"/>

    <div class="ta-lot-bidding lot-bidding-layout-grid">
      <lot-view-lot-no :label="$t('Lot Number')"/>
      <lot-countdown-timer :lot="lot"/>
      <bidding-start-price/>
      <div class="mb-2">
        <label class="muted-label">{{ $t('Current Bid') }}</label>
        <LotActualPrice :lot="lot"/>
      </div>
      <div class="mb-2">
        <label class="muted-label">{{ $t('Buy now') }}</label>
        <div class="text-black-600">{{ lot.buy_now_price || '(Empty)' }}</div>
      </div>
      <div class="mb-2">
        <label class="muted-label">{{ $t('No of bids') }}</label>
        <LotNumberOfBids :lot="lot"/>
      </div>
    </div>

    <bidding-actions>
      <div class="ta-lot-bidding-max-bid-wrapper">
        <bidding-input :label="true" class="mb-3" :for-mobile="true"/>
      </div>
      <bidding-max-bid-input/>
      <bidding-buy-now-button :for-mobile="true"/>
    </bidding-actions>
  `,
  bankAccountDetailsTemplate: `
    <div class="d-flex justify-content-between align-items-center">
      <legend>{{ $t("Bank Account Details") }}</legend>
    </div>
    <div class="row">
      <identification-number />
      <identification-type />
      <place-of-issue />
      <document-issue-date />
      <document-expiration-date />
    </div>
  `,
  sellAnItemModalTemplate: `
    <div class="row">
      <div class="col-6">
        <DepartmentInput />
      </div>
      <div class="col-6">
        <CategoryInput />
      </div>
      <div class="col-6">
        <QuantityInput />
      </div>
      <div class="col-6">
        <ReserveInput />
      </div>
    </div>
    <ImageInput />
    <DynamicFieldInputs />
  `
}

export default TEMPLATES
