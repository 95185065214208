<template>
  <vue-tel-input :value="value" @input="onInput" mode="international"/>
</template>

<script>
export default {
  name: "PhoneInput",
  props: {
    value: String
  },
  methods: {
    onInput(val){
      if (this.value !== val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../bootstrap/import";


.vue-tel-input {
  width: 100%;

  &.is-invalid {
    border-color: $danger;
  }

  &.is-valid {
    border-color: $success;
  }
}

/deep/ {
  .vti__dropdown {
    padding: 9px;
  }
}

</style>

<style lang="scss">
@import "../../bootstrap/import";
</style>
