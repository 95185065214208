<template>
  <div v-if="mainConfig.showLatestBids" class="ta-lot-latest-bids card">
    <table class="table table-sm table-bordered mb-0">
      <thead>
      <tr>
        <th colspan="4">
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ $t("Latest Bids") }}</span>
            <a href="javascript:void(0)" v-b-modal.latestBidsModal>{{ $t('View All') }} ({{ lot.num_of_bids }})</a>
          </div>
        </th>
      </tr>
      </thead>
      <tbody v-if="latestBids && latestBids.length">
      <tr v-for="bid in latestBids" :key="bid.id">
        <td>{{ bid.auto_bid ? 'A' : '' }}</td>
        <td>{{ bid.paddle_no }}</td>
        <td>{{ bid.amount | asCurrency(lot.currency.code) }}</td>
        <td>{{ (bid.bid_date || bid.created_at) | datetimeWithSeconds }}</td>
      </tr>
      <tr>
        <td colspan="4" class="text-muted text-left">
          A - {{ $t('Automatic bid placed through Max Bid') }}
        </td>
      </tr>
      </tbody>
      <tbody v-else>
      <tr>
        <td colspan="4" class="text-muted text-center py-3">
          {{ $t('There are no bids on this lot') }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapState} from "vuex";
import lotBiddingMixin from "../../mixins/lotBiddingMixin";
import LatestBidsModal from "@/components/lot-view/LatestBidsModal";

export default {
  name: "LatestBids",
  components: {LatestBidsModal},
  mixins: [lotBiddingMixin],
  data: () => ({
    modalShow: false,
  }),
  computed: {
    ...mapState(['mainConfig']), 
    ...mapState({
      lot: state => state.lotObject.data
    }),
    latestBids() {
      return this.lot.last_bids ? this.lot.last_bids.slice(0, 5) : []
    }
  }
}
</script>

<style scoped lang="scss">
.ta-lot-latest-bids {
  font-size: 90%;
}
</style>

<style lang="scss">
@import "../../scss/mixins";

</style>
