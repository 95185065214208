<template>
  <fieldset v-if="mainConfig.personalIdentificationDetails">
    <slot></slot>
  </fieldset>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: "PersonalIdentificationDetails",
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style scoped>

</style>
