<template>
  <div class="ta-lot-list-item-thumb">
    <router-link :to="lotInnerPageRoute">
      <img
        v-if="thumbnailSrc"
        :src="thumbnailSrc"
      />
      <img v-else src="https://cdn.artisio.co/no_image.jpg">
    </router-link>
    <span v-if="lot.winning_bid" class="ta-lot-last-bid">
          {{ lot.winning_bid.amount | asCurrency(lot.currency.code) }}
        </span>
  </div>
</template>

<script>
import lotHelperMixin from "@/mixins/lotHelperMixin";
import thumbnailMixin from "@/mixins/thumbnailMixin";

export default {
  name: "LotListItemImage",
  mixins: [lotHelperMixin, thumbnailMixin],
    props: {
    lot: {
      required: true,
      type: Object
    },
  },
}
</script>

<style scoped>

</style>
