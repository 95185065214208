<template>
  <div></div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import {hideHTMLElements, showHTMLElements} from "../../helpers";
import CONFIG from "../../user-config";

export default {
  name: "SignOut",

  methods: {
    ...mapActions(['sendSocketLogout']),
    ...mapMutations(['logoutUser'])
  },

  mounted() {
    this.sendSocketLogout();
    this.logoutUser();
    hideHTMLElements(CONFIG.visibleForAuthSelector);
    showHTMLElements(CONFIG.visibleForGuestsSelector);

    this.$router.push({
      name: 'Login'
    }).catch(() => {});
  }
}
</script>

<style scoped>

</style>
