<template>
  <div class="ta-lot-social-shares-popup">
    <button class="btn btn-link ta-lot-social-shares-button">
      <b-icon icon="reply-fill"></b-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: "SocialSharePopup"
}
</script>

<style lang="scss" scoped>
.ta-lot-social-shares-popup {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ta-lot-social-shares-button {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
