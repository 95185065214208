import { render, staticRenderFns } from "./LastName.vue?vue&type=template&id=258b4af6&scoped=true&"
import script from "./LastName.vue?vue&type=script&lang=js&"
export * from "./LastName.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "258b4af6",
  null
  
)

export default component.exports