import userConfig from "@/user-config";

export const COOKIE_AUTH_KEY = 'OTA_COOKIE_AUTH_KEY' //TODO This might change
export const LOT_LIST_DEFAULT_PAGE = 1;
export const LOT_LIST_DEFAULT_LIMIT = userConfig.lotPaginationLimit;
export const LOT_DEFAULT_SORT_FIELD = userConfig.lotDefaultSortField;
export const LOT_DEFAULT_SORT_BY = userConfig.lotDefaultSortBy;
export const LOT_DEFAULT_STATUS = userConfig.defaultLotStatus

export const FAVORITE_LOT_LIST_DEFAULT_PAGE = 1;
export const FAVORITE_LOT_LIST_DEFAULT_LIMIT = userConfig.favoriteLotPaginationLimit;
export const FAVORITE_LOT_DEFAULT_SORT_FIELD = userConfig.favoriteLotDefaultSortField;
export const FAVORITE_LOT_DEFAULT_SORT_BY = userConfig.favoriteLotDefaultSortBy;
export const FAVORITE_LOT_DEFAULT_STATUS = 'all';

export const I_MADE_BID_LOT_LIST_DEFAULT_PAGE = 1;
export const I_MADE_BID_LOT_LIST_DEFAULT_LIMIT = userConfig.favoriteLotPaginationLimit;
export const I_MADE_BID_LOT_DEFAULT_SORT_FIELD = userConfig.favoriteLotDefaultSortField;
export const I_MADE_BID_LOT_DEFAULT_SORT_BY = userConfig.favoriteLotDefaultSortBy;
export const I_MADE_BID_LOT_DEFAULT_STATUS = 'published';

export const AUCTION_LIST_DEFAULT_PAGE = 1;
export const AUCTION_LIST_DEFAULT_LIMIT = userConfig.auctionPaginationLimit;
export const AUCTION_DEFAULT_STATUS = 'published';
export const AUCTION_DEFAULT_SORT_FIELD = userConfig.auctionDefaultSortField;
export const AUCTION_DEFAULT_SORT_BY = userConfig.auctionDefaultSortBy;

export const FAVORITE_AUCTION_LIST_DEFAULT_PAGE = 1;
export const FAVORITE_AUCTION_LIST_DEFAULT_LIMIT = userConfig.favoriteAuctionPaginationLimit;
export const FAVORITE_AUCTION_DEFAULT_STATUS = 'all';
export const FAVORITE_AUCTION_DEFAULT_SORT_FIELD = userConfig.favoriteAuctionDefaultSortField;
export const FAVORITE_AUCTION_DEFAULT_SORT_BY = userConfig.favoriteAuctionDefaultSortBy;

export const INVOICE_PAGINATION_PAGE = 1
export const INVOICE_PAGINATION_LIMIT = 25
export const SETTLEMENT_PAGINATION_PAGE = 1
export const SETTLEMENT_PAGINATION_LIMIT = 25
export const ITEM_PAGINATION_PAGE = 1
export const ITEM_PAGINATION_LIMIT = 25
export const TEMPORARY_ITEM_PAGINATION_PAGE = 1
export const TEMPORARY_ITEM_PAGINATION_LIMIT = 25
export const PAGINATION_LIMIT = 25
export const PAGINATION_PAGE = 1

export const PAGING_TYPE_PAGINATION = 'PAGINATION';
export const PAGING_TYPE_LOAD_MORE = 'LOAD_MORE';
export const PAGING_TYPE_SCROLL = 'SCROLL';

export const BIDDING_TYPE_EXACT_UPCOMING_AMOUNT = 'exact_upcoming_amount';
export const MAX_BIDDING_TYPE_EXACT_UPCOMING_AMOUNT = 'exact_upcoming_amount';

export const SOCKET_RECONNECT_DELAY = 500;
export const IS_CONTINUOUS_AUCTION = 'is_continuous_auction';

export const TEMPLATE_KEYWORD_COMPONENT_MAP = {
  filterDepartmentList: '<filter-department-list />',
  filterCategoryList: '<filter-category-list />',
  filterPriceRange: '<filter-price-range />',
  filterReserveRange: '<filter-reserve-range />',
  filterEstimateRange: '<filter-estimate-range />',
  filterLotNoRange: '<filter-lot-range />',
  filterWithImage: '<filter-with-image />',
  filterDynamicFields: '<filter-dynamic-fields />',
  filterLotStatus: '<filter-lot-status />',
  filters: '<lot-list-filters />',
  keywordSearch: '<lot-keyword-search/>',
  jumpToLot: '<jump-to-lot />',
  perPageSelect: '<lot-list-per-page-select />',
  sortingDropdown: '<sorting-dropdown />',
  switchViewButton: '<lot-list-switch-view />',
  filtersMobileButton: '<lot-list-filter-mobile-button />',
  items: '<lot-list-items />',
  paginationSummary: '<lot-list-pagination-summary/>',
  paginationButtons: '<lot-list-pagination-buttons/>',
  itemsCount: '<lot-list-items-count />',

}
