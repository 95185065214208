import DEFAULT_CONFIG, {STRING_OPTIONS, BOOLEAN_OPTIONS, OBJECT_OPTIONS} from './user-config/default-config'
import {mergeDeep, convertHandlerToFn} from './user-config/helpers'
import PRESETS from './user-config/presets'

const CONFIG = {};
const USER_CONFIG = {};
let PRESET_CONFIG = {};

const el = document.querySelector("#artisioTimedAuctions");
let dataset = {};
if (!el) {
  console.error(`Artisio Webapp HTML Element with id "artisioTimedAuctions" does not exist`);
} else {
  dataset = {
    ...el.dataset
  };
}

// Prepare boolean variables
for (const field of BOOLEAN_OPTIONS) {
  if (dataset[field] !== undefined) {
    dataset[field] = ["true", "1"].includes(dataset[field].toLowerCase());
  }
}

// Merge properties taken from div data attributes with JavaScript
dataset = {
  ...dataset,
  ...window.artisioWebApp
}
for (let field of [...BOOLEAN_OPTIONS, ...STRING_OPTIONS]) {
  if (dataset[field] !== undefined) {
    USER_CONFIG[field] = dataset[field];
  }
}

for (const option of OBJECT_OPTIONS) {
  if (dataset[option]) {
    USER_CONFIG[option] = dataset[option];
  }
}

if (dataset.links !== undefined) {
  try {
    if (typeof dataset.links === 'string') {
      USER_CONFIG.links = JSON.parse(dataset.links);
      USER_CONFIG.links.forEach((link) => (link.handler = convertHandlerToFn(link.handler)));
    } else if (typeof dataset.links === 'object' && dataset.links.length !== undefined) {
      USER_CONFIG.links = dataset.links;
    } else {
      console.error(`Invalid value for "links". It must be array or JSON`)
    }
  } catch (e) {
    console.error(`"links" is not valid JSON`);
  }
}
if (dataset.loginHandler) {
  USER_CONFIG.loginHandler = convertHandlerToFn(dataset.loginHandler);
}

if (dataset.signupHandler) {
  USER_CONFIG.signupHandler = convertHandlerToFn(dataset.signupHandler);
}
if (dataset.printButton !== undefined) {
  if (typeof dataset.printButton === 'boolean') {
    USER_CONFIG.printButton = dataset.printButton;
  } else {
    if (["true", "1"].includes(dataset.printButton.toLowerCase())) {
      USER_CONFIG.printButton = true;
    } else if (["false", "0"].includes(dataset.printButton.toLowerCase())) {
      USER_CONFIG.printButton = false;
    } else {
      USER_CONFIG.printButton = convertHandlerToFn(dataset.printButton);
    }
  }
}

if (dataset.descriptionDynamicFields !== undefined) {
  if (typeof dataset.descriptionDynamicFields === 'boolean') {
    USER_CONFIG.descriptionDynamicFields = dataset.descriptionDynamicFields;
  } else {
    if (typeof dataset.descriptionDynamicFields === 'string') {
      if (["true", "1"].includes(dataset.descriptionDynamicFields.toLowerCase())) {
        USER_CONFIG.descriptionDynamicFields = true;
      } else if (["false", "0"].includes(dataset.descriptionDynamicFields.toLowerCase())) {
        USER_CONFIG.descriptionDynamicFields = false;
      }
    } else {
      USER_CONFIG.descriptionDynamicFields = dataset.descriptionDynamicFields;
    }
  }
}

if (dataset.translations) {
  USER_CONFIG.translations = dataset.translations;
}

if (dataset.templates) {
  USER_CONFIG.templates = dataset.templates;
}

const preset = USER_CONFIG.preset || DEFAULT_CONFIG.preset;
if (preset && PRESETS[preset]) {
  PRESET_CONFIG = {...PRESETS[preset]};
}

mergeDeep(CONFIG, DEFAULT_CONFIG, PRESET_CONFIG, USER_CONFIG)

if (!CONFIG.clientId) {
  throw new Error('Please provide "clientId"');
}

if (!(typeof CONFIG.translations === 'object')) {
  throw new Error(`"translations" config must be object`)
}

export default CONFIG;
export {DEFAULT_CONFIG, USER_CONFIG};
