<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      id="input-document-expiration-date"
      type="date"
      v-model="model"
      :error="errors"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
    />
  </div>
</template>

<script>
import AbstractSignupTextInput from "../core/AbstractSignupTextInput";
import BaseInputMixin from "../../../../mixins/signupInputMixin";
import {mapState} from "vuex";
import InputWidget from "@/components/core/InputWidget.vue";

export default {
  name: "DocumentExpirationDate",
  components: {InputWidget, AbstractSignupTextInput},
  mixins: [BaseInputMixin('identification.document_expiry_date')],
  data() {
    return {
      defaultLabel: this.$t("Document Expiration Date")
    }
  },
}
</script>

<style scoped>

</style>
