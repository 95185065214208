<template>
  <buy-now-button v-if="lot.buy_now_price"
                  :for-mobile="forMobile"
                  :disabled="buyNowDisabled"
                  @confirm="place_bid"/>
</template>

<script>
import {mapState} from "vuex";
import BuyNowButton from "../../lot-view/sidebar/BuyNowButton";
import lotBiddingMixin from "../../../mixins/lotBiddingMixin";

export default {
  name: "BiddingBuyNowButton",
  components: {BuyNowButton},
  mixins: [lotBiddingMixin],
  props: {
    forMobile: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState({
      lot: state => state.lotObject.data,
    })
  }
}
</script>

<style scoped>

</style>
