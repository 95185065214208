<template>
  <fieldset class="mb-4">
    <div class="d-flex justify-content-between align-items-center">
      <legend>{{ title }}</legend>
      <shipping-same-as-billing-address/>
    </div>
    <slot></slot>
  </fieldset>
</template>

<script>
import {mapState} from "vuex";
import ShippingSameAsBillingAddress
  from "@/components/configuration/profile/shipping-address/ShippingSameAsBillingAddress.vue";

export default {
  name: "ShippingAddressDetails",
  components: {ShippingSameAsBillingAddress},
  props: {
    title: {
      required: false,
      type: String
    }
  },
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style scoped>

</style>
