<template>
  <div v-if="lots.loading" class="ta-lot-list" :class="['view-type-' + (lotListViewType)]">
    <div v-for="i in skeletonNumbers" v-bind:key="`skeleton-${i}`" class="mb-2">
      <div class="ta-lot-list-item">
        <div class="ta-lot-list-item-inner">

          <div class="ta-lot-list-item-thumb">
            <b-skeleton-img no-aspect width="240px" height="180px"></b-skeleton-img>
          </div>
          <div class="ta-lot-list-item-desc">
            <b-skeleton class="mb-2" animation="wave" width="100%" height="30px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="200px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="60px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="80px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="120px" height="30px"></b-skeleton>
          </div>
          <div class="ta-lot-list-item-buttons">
            <b-skeleton class="rounded-circle" animation="wave" width="30px" height="30px"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="lots.results.length" class="ta-lot-list-items">
    <div class="ta-lot-list" :class="['view-type-' + (lotListViewType)]">
      <lot-list-item v-for="lot in lots.results" :key="lot.uuid" :lot="lot">
      </lot-list-item>
    </div>
    <lot-list-load-more/>
    <div v-if="isPagingTypePagination" class="ta-lot-list-footer">
      <lot-list-pagination-summary/>
      <lot-list-pagination-buttons/>
    </div>
  </div>
  <div v-else>
    <p class="ta-no-lots-text">
      {{ $t('There are no lots') }}
    </p>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import LotListItem from "../../LotListItem";
import LotListLoadMore from "./LotListLoadMore";

export default {
  name: "LotListItems",
  components: {LotListLoadMore, LotListItem},
  computed: {
    ...mapGetters(['lots']),
    ...mapState(['lotListViewType', 'lotQueryParams']),
    ...mapGetters(['isPagingTypeLoadMore', 'isPagingTypePagination']),
  },
  data: () => ({
    timeoutId: null,
    skeletonNumbers: []
  }),
  methods: {
    onScrollChange() {
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        window.localStorage.setItem('LotListItems.scrollPosition', window.scrollY)
      }, 200);
    }
  },
  mounted() {
    const scroll = window.localStorage.getItem('LotListItems.scroll')

    if (scroll) {
      window.localStorage.removeItem('LotListItems.scroll')
      const scrollY = parseInt(window.localStorage.getItem('LotListItems.scrollPosition'))
      if (scrollY) {
        setTimeout(() => {
          window.scrollTo(0, scrollY)
          setTimeout(() => {
            window.addEventListener('scroll', this.onScrollChange)
          }, 500)
        }, 200)
      }
    } else {
      window.addEventListener('scroll', this.onScrollChange)
    }

    for (let i = 0; i < this.lotQueryParams.limit; i++) {
      this.skeletonNumbers.push(i)
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScrollChange)
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import.scss";
@import "../../../scss/mixins";

.ta-no-lots-text {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: $gray-600
}

.ta-lot-list {
  display: grid;
  grid-template-columns: 1fr;
  overflow: auto;
  @include scrollbars();

  &.view-type-grid {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
  }
}

.ta-lot-list-items {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

@include media-breakpoint-down(xl) {
  .ta-lot-list.view-type-grid {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
}

@include media-breakpoint-down(md) {
  .ta-lot-list {
    flex-direction: column;
  }
}

@include media-breakpoint-down(xs) {
  .ta-lot-list {
    column-gap: 1rem;
    row-gap: 1rem;
  }
}
</style>
