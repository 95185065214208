<template>
  <b-button type="reset" @click="scrollToTop" variant="secondary">
    {{ $t(label) }}
  </b-button>
</template>

<script>
export default {
  name: "SignupFormResetButton",
  props: {
    label: {
      required: false,
      type: String,
      default: "Reset"
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  }
}
</script>

<style scoped>
  button {
    width: 140px;
  }
</style>
