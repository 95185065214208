export default {
  computed: {
    thumbnailSrc() {
      if (!this.lot.images) {
        return "";
      }
      const firstImage = this.lot.images[0];
      // If the image is broken
      if (!firstImage || !firstImage.original) {
        return "";
      }
      return (firstImage.sm || firstImage.original).url || "";
    },
  }
}
