<template>
  <div v-if="mainConfig.enableBidding && showLatestBidNotification" class="bidding-latest-notification mb-3">
    <!-- If bidding is finished on the lot but it is unsold. No one made bid on it -->
    <div v-if="lot.status === 'unsold'" class="ta-lot-unsold alert alert-danger">
      <small v-if="reserveNotMet">
        {{ $t('The item has not been sold due to not reaching reserve price.') }}
      </small>
      <small v-else>
        {{ $t('The item has not been sold.') }}
      </small>
    </div>
    <!-- If bidding is finished on the lot and lot is sold -->
    <div v-else-if="lot.status === 'sold'" class="ta-lot-sold" :class="[iWonTheItem ? 'ta-lot-sold-by-me' : '']">
      <!-- If I am the buyer-->
      <div v-if="iWonTheItem" class="alert alert-success">
        <strong>{{ $t('Congratulations') }}.</strong>
        <div>
          <small>{{ $t('You have bought this item for') }}
            <strong v-if="lot.winning_bid">{{ lot.winning_bid.amount | asCurrency(lot.currency.code) }}</strong>
          </small>
        </div>
      </div>
      <!-- If I am NOT the buyer -->
      <div v-else class="alert alert-info">
        <div>
          {{ $t('The item was sold for') }}
          <strong v-if="lot.winning_bid">{{ lot.winning_bid.amount | asCurrency(lot.currency.code) }}</strong>
          <strong v-else-if="lot.last_bid_amount">{{ lot.last_bid_amount | asCurrency(lot.currency.code) }}</strong>
        </div>
      </div>
    </div>
    <div v-else-if="lot.status === 'published'">
      <!-- If bidding continues on the lot -->
      <div v-if="!biddingFinished">
        <!-- If I am the latest bidder -->
        <div v-if="isCurrentUserLastBidder"
             class="alert alert-success py-2 px-3 d-flex align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" style="width: 20px" fill="none" viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
          </svg>
          <small class="mx-2">{{ $t('You are the highest bidder') }}</small>
          <a href="javascript:void(0)" @click="showMaxBidModal" class="btn-link text-sm btn-increase-max-bid">
            {{ $t('Increase max bid') }}
          </a>
        </div>
        <!-- If someone else is latest bidder but I made a bid also -->
        <div v-else-if="lot.has_my_bid && !isCurrentUserLastBidder"
             class="ta-you-have-been-outbid-notification alert alert-danger ">
          <svg xmlns="http://www.w3.org/2000/svg" class="mt-1" style="min-width: 20px; width: 20px" fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"/>
          </svg>
          <div class="ml-2" style="flex: 1">
            <div>
              <small>
                <b>{{ $t('You have been outbid') }}</b>
              </small>
            </div>
            <div v-if="lot.winning_bid && lot.max_bid >= lot.winning_bid.amount">
              <small>
                {{
                  $t('Someone placed winning bid amount earlier than your bid. That\'s why your bid is not winning')
                }}
              </small>
            </div>
            <div v-if="lot.max_bid" class="mt-2 text-right">
              <a href="javascript:void(0)" @click="showMaxBidModal"
                 class="btn btn-sm btn-danger text-sm btn-increase-max-bid">
                {{ $t('Increase max bid') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import maxBidMixin from "../../../mixins/maxBidMixin";

export default {
  name: "BiddingLatestNotification",
  mixins: [maxBidMixin],
  computed: {
    ...mapState(['mainConfig']),
  }
}
</script>

<style lang="scss" scoped>
.ta-you-have-been-outbid-notification {
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: start;
}
</style>
