import VModelMixin from "./vModelMixin";
import {mapMutations, mapState} from "vuex";

export default (fieldName) => ({
  mixins: [VModelMixin],

  data() {
    return {
      defaultLabel: null,
      defaultPlaceholder: null,
    }
  },

  props: {
    label: {
      required: false,
      default: function () {
        return this.defaultLabel
      }
    },
    placeholder: {
      required: false,
      default: function () {
        return this.defaultPlaceholder
      }
    },
  },
  computed: {
    ...mapState(['profile']),
    ...mapState({
      errors: state => {
        if (fieldName.startsWith('billing_address.') || fieldName.startsWith('shipping_address.') || fieldName.startsWith('identification.') || fieldName.startsWith('bank_account.')) {
          const fields = fieldName.split('.');
          let iden = state.profile.errors[fields[0]];
          if (iden && iden.length > 0) {
            return iden[0][fields[1]];
          }
          return '';
        }

        return state.profile.errors[fieldName]
      }
    }),
    computedLabel() {
      return this.label || this.defaultLabel
    },
    computedPlaceholder() {
      return this.placeholder || this.defaultPlaceholder
    }
  },
  watch: {
    [`profile.model.${fieldName}`]: {
      immediate: true,
      handler() {
        const fields = fieldName.split('.')
        if (fields.length === 1) {
          this.model = this.profile.model[fieldName]
        } else {
          this.model = this.profile.model[fields[0]][fields[1]]
        }
      }
    }
  },
  methods: {
    ...mapMutations(['profileFieldOnInput']),
    onInput(val) {
      this.profileFieldOnInput({field: fieldName, value: val || ''})
    }
  }
})
