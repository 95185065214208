<template>
  <div v-if="value.messageCode === 'RESEND_EMAIL'" class="alert alert-success">
    <div>
      {{ $t("Verification link has been sent to your email address") }}
      <a href="#" @click.prevent="resendVerification">{{ $t("click here") }}</a>
    </div>
  </div>
  <div
    v-else-if="value.messageCode === 'RESEND_PASSWORD'"
    class="alert alert-success">
    <div>
      {{ $t("Password reset link has been sent to your email address") }}
      <a href="#" @click.prevent="resendPassword">{{ $t("click here") }}</a>
    </div>
  </div>
  <div v-else-if="value.messageCode === 'TIMEOUT_EMAIL' || value.messageCode === 'TIMEOUT_PASSWORD'" class="alert alert-success">
    <div>
      {{ $t("You have made too many attempts. Please try again in {x} minute(s)", {x: minutesLeft}) }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import i18n from "../../i18n";

export default {
  name: "ResendEmail",
  props: {
    value: Object,
    email: String
  },
  computed: {
    minutesLeft() {
      return this.value.secondsLeft ? Math.ceil(this.value.secondsLeft / 60) : 0;
    }
  },
  methods: {
    ...mapActions(["resendVerificationEmail", "resendVerificationPassword"]),
    ...mapMutations(['showNotification']),
    resendVerification() {
      this.resendVerificationEmail({email: this.email})
        .then(res => {
          this.handleSuccess(res);
        })
        .catch(res => {
          this.handleError(res);
        })
    },
    resendPassword() {
      this.resendVerificationPassword({email: this.email})
        .then(res => {
          this.handleSuccess(res);
        })
        .catch(res => {
          this.handleError(res);
        })
    },
    handleSuccess(res) {
      this.value.messageCode = res.message_code;
      this.value.secondsLeft = res.seconds_left;

      this.showNotification({
        title: i18n.t('Success.'),
        text: i18n.t('Email sent.'),
        variant: 'success',
        duration: 5000
      });
    },
    handleError(res) {
        this.showNotification({
          title: i18n.t('Error.'),
          text: res.data.Error,
          variant: 'danger',
          duration: 5000
        });
    }
  }
};
</script>

<style scoped>
</style>
