import Vue from 'vue';
import EventBus from "../event-bus";
import {ON_CONFIRM_DIALOG_HIDE, ON_CONFIRM_DIALOG_OPEN} from "../event-bus/event-bus-names";
import i18n from "@/i18n";

const ConfirmModal = {
  install(Vue) {
    Vue.prototype.$confirm = (
      message,
      successMessage = null,
      handler = null,
      handlerParams = null,
      title = i18n.t('Please Confirm'),
      submitBtnText = i18n.t('Yes'),
      cancelBtnText = i18n.t('No'),
      extraHandlers = null
    ) => {
      return new Promise((resolve) => {
        EventBus.$emit(ON_CONFIRM_DIALOG_OPEN, {message, successMessage, handler, handlerParams, title, submitBtnText, cancelBtnText, extraHandlers});
        EventBus.$off(ON_CONFIRM_DIALOG_HIDE);
        EventBus.$on(ON_CONFIRM_DIALOG_HIDE, (payload) => {
          resolve(payload);
        })
      });
    }
  }
};

Vue.use(ConfirmModal);
