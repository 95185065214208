<template>
  <div class="ta-auth-card ta-password-reset-form">
    <div class="text-center">
      <h3 class="mb-3">
        {{ $t("Reset Your Password") }}
      </h3>
    </div>

    <FormAlert :success-message="successMessage" :error-message="errorMessage">
      <template #successMessageSlot>
        <div>
          <router-link :to="{ name: 'Login' }">
            {{ $t("Go to Login Page") }}
          </router-link>
        </div>
      </template>
    </FormAlert>

    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <input-widget
          id="input-password"
          type="password"
          :label="$t('Password')"
          v-model="model.password"
          :error="errors.password"
          :show-password-toggle="true"
          rules="required|min:8|at_least_lowercase_alpha|at_least_uppercase_alpha|at_least_digit|at_least_special"
        >
          <template #append>
            <b-input-group-text id="input-password-append">
              <span class="d-inline-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="width: 1.25rem"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </b-input-group-text>
          </template>
        </input-widget>

        <b-popover
          target="input-password-append"
          triggers="hover"
          placement="bottomleft"
          container="artisioTimedAuctions"
        >
          <template #title>
            {{ $t("Password Policy") }}
          </template>
          <div>
            <p class="font-weight-bold">
              {{
                $t("Your password must be at least {x} characters long and contain", { x: 8 })
              }}
            </p>
            <ul class="pl-4">
              <li class="">
                {{ $t("At least one digit") }}
              </li>
              <li class="">
                {{ $t("At least one lowercase letter") }}
              </li>
              <li class="">
                {{ $t("At least one uppercase letter") }}
              </li>
              <li class="">
                {{ $t("At least one special character") }}
              </li>
            </ul>
          </div>
        </b-popover>

        <input-widget
          id="input-password-repeat"
          v-model="model.password_repeat"
          :error="errors.password_repeat"
          type="password"
          :label="$t('Repeat Password')"
          rules="required|"
        >
        </input-widget>

        <loading-button
          block
          :loading="loading"
          :disabled="invalid"
        >
          {{ $t("Set New Password") }}
        </loading-button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LoadingButton from "../../components/core/LoadingButton.vue";
import InputWidget from "../../components/core/InputWidget.vue";
import FormAlert from "../../components/core/FormAlert";
export default {
  components: {FormAlert, LoadingButton, InputWidget },
  name: "ResetPassword",
  data() {
    return {
      loading: false,
      errorMessage: "",
      successMessage: "",
      model: {
        password: "",
        password_repeat: "",
      },
      errors: {
        password: null,
        password_repeat: null,
      }
    };
  },
  methods: {
    ...mapActions(["validatePasswordResetToken", "resetPassword"]),
    onSubmit() {
      this.loading = true;
      this.resetPassword({...this.model, 'token': this.$route.params.token})
        .then((message) => {
          this.loading = false;
          this.$router.push({name: 'Login', params: {successMessage: message}});
          this.errorMessage = "";
        })
        .catch((error) => {
          this.loading = false;

          this.successMessage = "";
          this.errorMessage = error;
        });
    },
  },
  beforeMount() {
    // console.log(this.$route.params);
    // this.validatePasswordResetToken(this.$route.params)
    //   .then(() => {
    //     console.log("Success");
    //   })
    //   .catch((error) => {
    //     console.log("Invalid");
    //     this.errorMessage = error;
    //   });
  },
};
</script>

<style lang="scss" scoped></style>
