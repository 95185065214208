<template>
  <b-modal id="confirmationDialog"
           @hide="hideModal(false)"
           :visible="show"
           :no-close-on-backdrop="loading"
           :no-close-on-esc="loading"
           static
           lazy>
    <template v-slot:modal-header>
      <h5 class="modal-title">
        {{ title }}
      </h5>
      <button type="button" :disabled="loading" @click="hideModal(false)" aria-label="Close" class="close">×
      </button>
    </template>
    <template v-slot:modal-footer>
      <b-button variant="primary" :text="submitBtnText" :disabled="disableSubmitBtn" @click="onSubmitClick">
        {{$t('Yes')}}
      </b-button>
      <div v-if="extraHandlers">
        <b-button v-for="extraHandler in extraHandlers" :key="extraHandler.submitBtnText" variant="primary"
                  :disabled="disableSubmitBtn"
                  :show-loader="extraHandlersLoading[extraHandler.submitBtnText]"
                  @click="onSubmitClick($event, extraHandler)"
        >
          {{extraHandler.submitBtnText}}
        </b-button>
      </div>
      <b-button variant="danger" :text="cancelBtnText" :disabled="disableSubmitBtn" :show-loader="loading"
                @click="hideModal(false)">
        {{$t('No')}}
      </b-button>
    </template>
    <span v-html="message"/>
  </b-modal>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import EventBus from "../event-bus";
import {ON_CONFIRM_DIALOG_HIDE, ON_CONFIRM_DIALOG_OPEN} from "../event-bus/event-bus-names";

const {mapState} = createNamespacedHelpers('confirmModal');

export default {
  name: "ConfirmModal",
  computed: {
    disableSubmitBtn() {
      let extraHandlersLoading = false;
      if (this.extraHandlers) {
        extraHandlersLoading = this.extraHandlers.reduce((previousVal, currenVal) =>
          previousVal || this.extraHandlersLoading[currenVal.submitBtnText], false);
      }
      return extraHandlersLoading || this.loading;
    }
  },
  data() {
    return {
      loading: false,
      show: false,
      message: null,
      successMessage: null,
      handler: null,
      handlerParams: null,
      title: null,
      submitBtnText: null,
      cancelBtnText: null,
      extraHandlers: null,
      extraHandlersLoading: {},
    }
  },
  methods: {
    getMessageHTML(message) {
      // If given message is array, convert it to Unordered List
      if (Array.isArray(message) && message.length > 0) {
        if (message.length === 1) {
          message = message[0]
        } else {
          let list = document.createElement('ul')
          message.forEach((s) => {
            let item = document.createElement('li')
            item.appendChild(document.createTextNode(s))
            list.appendChild(item)
          })
          return list.innerHTML
        }
      }
      return message
    },
    hideModal(param) {
      EventBus.$emit(ON_CONFIRM_DIALOG_HIDE, param);
      this.clearParams();
    },
    clearParams() {
      this.message = null;
      this.loading = false;
      this.successMessage = null;
      this.handler = null;
      this.handlerParams = null;
      this.title = null;
      this.show = false;
      this.submitBtnText = null;
      this.cancelBtnText = null;
      this.extraHandlers = null;
      this.extraHandlersLoading = {};
    },
    async onSubmitClick(_, extraHandler) {
      if (this.handler || extraHandler) {
        const _handle = extraHandler || {
          successMessage: this.successMessage,
          handler: this.handler,
          handlerParams: this.handlerParams,
        };
        this.setLoading(_handle, true);
        let response = await _handle.handler(_handle.handlerParams);
        this.setLoading(_handle, false);
        if (response.success) {
          this.$toast('success', this.successMessage);
          this.hideModal(true);
        } else {
          if (response.toastError) {
            this.$errorDialog(response.toastError);
          } else {
            if (response.body && response.body.non_field_errors) {
              if (typeof response.body.non_field_errors === 'object' && response.body.non_field_errors.length > 1) {
                const listItemsArr = [];
                response.body.non_field_errors.forEach(err => listItemsArr.push(this.$createElement('li', {}, err)))
                this.$errorDialog(this.$createElement('ul', {class: "mb-0"}, listItemsArr), this.$i18n.t('Validation Error'));
              } else {
                this.$errorDialog(response.body.non_field_errors[0]);
              }
            }
          }
          this.hideModal(false);
        }
      } else {
        this.hideModal(true);
      }
    },
    setLoading(handler, loading) {
      if (handler.submitBtnText) {
        this.extraHandlersLoading[handler.submitBtnText] = loading;
        this.extraHandlersLoading = {...this.extraHandlersLoading};
      } else {
        this.loading = loading;
      }
    }
  },
  beforeMount() {
    EventBus.$on(ON_CONFIRM_DIALOG_OPEN, ({
                                            message,
                                            successMessage,
                                            handler,
                                            handlerParams,
                                            title,
                                            submitBtnText,
                                            cancelBtnText,
                                            extraHandlers
                                          }) => {
      this.clearParams();
      this.message = this.getMessageHTML(message);
      this.successMessage = successMessage;
      this.handler = handler;
      this.handlerParams = handlerParams;
      this.title = title;
      this.show = true;
      this.submitBtnText = submitBtnText;
      this.cancelBtnText = cancelBtnText;
      this.extraHandlers = extraHandlers;
      if (extraHandlers) {
        extraHandlers.forEach(eh => {
          this.extraHandlersLoading[eh.submitBtnText] = false;
        });
      }
    });
  },
  beforeDestroy() {
    EventBus.$off(ON_CONFIRM_DIALOG_OPEN);
  }
}
</script>

<style scoped>

</style>
