<template>
  <div class="ta-page-settlements">
    <PageHeader :title="$t('Settlements')"></PageHeader>
    <div class="d-flex justify-content-start">
      <div class="ta-settlement-list">
        <div class="ta-settlement-list-header">
          <div class="ta-per-page-wrapper">
            {{ $t("Show") }}
            <b-form-select
              class="mx-2"
              v-model="limit"
              @change="updatePagination"
              :options="perPageOptions"
            ></b-form-select>
            {{ $t("entries") }}
          </div>
          <div class="ta-settlement-search-wrapper d-flex align-items-center">
            {{ $t("Search") }}:
            <b-form-input
              :placeholder="$t('Search for settlements')"
              class="ml-2"
              v-model="filters.q"
              v-debounce:300ms="updateRoute"
            ></b-form-input>
          </div>
        </div>
        <div v-if="settlements.loading" class="ta-settlement-loader">
          <b-spinner label="Spinning"></b-spinner>
          <span class="mt-2">{{ $t("Loading...") }}</span>
        </div>
        <template v-if="!settlements.loading">
          <div class="ta-settlement-list-wrapper">
            <table class="table table-sm">
              <thead>
              <tr>
                <th scope="col">
                  {{ $t("Settlement No") }}
                </th>
                <th scope="col">
                  {{ $t("Sale No") }}
                </th>
                <th scope="col">
                  {{ $t("Total") }}
                </th>
                <th scope="col">
                  {{ $t("Type") }}
                </th>
                <th scope="col">
                  {{ $t("Items") }}
                </th>
                <th scope="col">
                  {{ $t("Actions") }}
                </th>
              </tr>
              </thead>
              <tbody v-if="settlements.results.length">
              <tr v-for="settlement in settlements.results" :key="settlement.id">
                <td>
                  <a href="javascript:void(0)" class="btn-settlement-view" @click="view(settlement)">
                    {{ settlement.settlement_no }}
                  </a>
                </td>
                <td>
                  {{ settlement.auction ? settlement.auction.sale_no : '' }}
                </td>
                <td>
                  {{ settlement.net | asCurrency(settlement.currency.code) }}
                  / {{ settlement.paid_amount | asCurrency(settlement.currency.code) }}
                  / {{ settlement.outstanding_amount | asCurrency(settlement.currency.code) }}
                </td>
                <td>
                  {{settlement.type}}
                </td>
                <td>
                  {{ settlement.total_financial_items }}
                </td>
                <td>
                  <button class="btn btn-sm btn-primary btn-pdf-view mr-2" @click="view(settlement)">
                    <svg xmlns="http://www.w3.org/2000/svg" style="width: 16px; display: inline-block" fill="none"
                         viewBox="0 0 24 24"
                         stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                      <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                    </svg>
                    {{ $t("View") }}
                  </button>
                  <button class="btn btn-sm btn-primary btn-pdf-download" @click="download(settlement)">
                    <svg xmlns="http://www.w3.org/2000/svg" style="width: 16px; display: inline-block" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                    </svg>
                    {{ $t("Download") }}
                  </button>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="15">
                  <p class="text-muted text-center py-3">
                    {{ $t(`You don't have data`) }}
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="ta-settlement-list-footer">
            <div class="ta-settlement-pagination-summary">
              {{
                $t("Showing {start} to {finish} of {total} entries", {
                  start:
                    (settlements.pagination.page - 1) * settlements.pagination.limit +
                    1,
                  finish:
                    settlements.pagination.page * settlements.pagination.limit >
                    settlements.totalRows
                      ? settlements.totalRows
                      : settlements.pagination.page * settlements.pagination.limit,
                  total: settlements.totalRows,
                })
              }}
            </div>
            <b-pagination
              v-model="page"
              :total-rows="settlements.totalRows"
              :per-page="settlements.pagination.limit"
              @input="updatePagination"
              class="ta-settlement-list-pagination"
            ></b-pagination>
          </div>
        </template>
      </div>
    </div>
    <b-modal
      :title="modal.title"
      v-model="modal.show"
      size="xl"
      static
      :ok-only="true"
      lazy
    >
      <iframe class="ta-base64-viewer-iframe" :src="modal.base64"></iframe>
    </b-modal>

  </div>
</template>

<script>
import i18n from '@/i18n';
import {mapState, mapGetters, mapActions} from "vuex";
import {
  SETTLEMENT_PAGINATION_PAGE,
  SETTLEMENT_PAGINATION_LIMIT,
} from "../constants";
import logger from "../logger";
import PageHeader from "@/components/PageHeader";

export default {
  name: "Settlements",
  components: {PageHeader},
  data: () => {
    return {
      limit: SETTLEMENT_PAGINATION_LIMIT,
      page: SETTLEMENT_PAGINATION_PAGE,
      filters: {
        q: null,
      },
      modal: {
        show: false,
        title: '',
        base64: ''
      },
      delayedRequest: null,
      query: null,
      normalizedQuery: null
    };
  },

  computed: {
    ...mapState(["perPageOptions", "settlements"]),
    ...mapGetters(["currentLocale"]),
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newVal) {
        // make actions with newVal.page
        if (newVal.page) this.page = newVal.page;
        if (newVal.limit) this.limit = newVal.limit;

        for (const key in this.filters) {
          if (newVal[key]) this.filters[key] = newVal[key];
        }

        this.getUpdatedSettlements();
      },
    },
  },

  methods: {
    ...mapActions(["getSettlements", "setSettlementPagination", "getSettlementPdf"]),

    filtersChanged(filters) {
      this.filters = filters;
      this.updateRoute();
    },

    updatePagination() {
      this.updateRoute();
    },

    updateRoute() {
      this.$router
        .push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: this.page,
            limit: this.limit,
            ...this.filters,
          },
        })
        .catch((err) => {
          logger.error("Failed to update route for settlements", err);
        });
    },

    getUpdatedSettlements() {
      this.getSettlements({
        pagination: {page: this.page, limit: this.limit},
        filters: this.filters,
      });
    },
    async download(settlement) {
      const result = await this.getSettlementPdf({uuid: settlement.uuid});
      if (result) {
        const link = document.createElement('a');
        link.href = `data:application/pdf;name=${settlement.settlement_no}.pdf;base64,` + result;
        link.download = `${settlement.settlement_no}.pdf`;
        link.style.display = 'none';
        document.body.appendChild(link);
        console.log(link);
        link.click();
      }
    },
    async view(settlement) {
      const result = await this.getSettlementPdf({uuid: settlement.uuid});
      if (result) {
        this.modal.base64 = `data:application/pdf;name=${settlement.settlement_no}.pdf;base64,` + result;
        this.modal.title = `${settlement.settlement_no}.pdf`;
        this.modal.show = true;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.ta-settlement-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ta-settlement-loader {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ta-per-page-wrapper {
  display: flex;
  align-items: center;
}

.ta-settlement-list {
  flex: 1;
}

.ta-settlement-list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-uc-first {
  text-transform: capitalize;
}

.pagination.ta-settlement-list-pagination {
  margin-bottom: 0 !important;
}

.ta-base64-viewer-iframe {
  width: 100%;
  min-height: 600px;
}
</style>
