<template>
  <div class="ta-lot-keyword-search">
    <b-form-input
      :placeholder="$t('Search for lots') + '...'"
      :value="lotQueryParams.q"
      @change.native="updateFilters({q: $event.target.value})"></b-form-input>
  </div>
</template>

<script>
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import {updateLotsRoute} from "../../../helpers";
import {mapMutations, mapState} from "vuex";

export default {
  name: "LotKeywordSearch",
  computed: {
    ...mapState(['lotQueryParams'])
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    updateFilters(filters){
      filters.page = LOT_LIST_DEFAULT_PAGE;
      this.setLotFilters(filters)
      updateLotsRoute()
    },
  }
}
</script>

<style lang="scss" scoped>
.ta-lot-keyword-search {
  flex: 1;
  margin-bottom: 1rem;
}
</style>
