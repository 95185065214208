export default {
  computed: {
    auctionViewRoute() {
      if (this.$route.name === 'PrivateTreatyAuctions') {
        return {name: 'PrivateTreatyAuctionView', params: {uuid: this.auction.uuid}}
      }
      return {name: 'AuctionViewTimed', params: {uuid: this.auction.uuid}, query: {lot_status: 'all'}}
    },
    imageSrc() {
      if (!this.auction.images || !this.auction.images.length) {
        return 'https://cdn.artisio.co/no_image.jpg';
      }
      return this.auction.images[0].sm.url;
    },
    title() {
      return this.$options.filters.stripTags(this.$options.filters.currentLocale(this.auction.title)).trim().replace(/^&nbsp;/, '')
    }
  },
}
