<template>
  <div class="col-md-6 mb-2">
    <b-modal
      id="increments-modal"
      ref="incrementsModal"
      :title="$t('Sale Increments')"
      :visible="displayModal"
      @hidden="displayModal = false"
      static
      lazy
    >

      <table class="table table-sm table-bordered">
        <thead>
        <tr>
          <th>{{ $t('Increment') }}</th>
          <th>{{ $t('Up to') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(inc, ind) of auction.increments" :key="ind">
          <td>{{ inc.increment | asCurrency(auction.currency.code) }}</td>
          <td>
          <span v-if="inc.up_to">
            {{ inc.up_to | asCurrency(auction.currency.code) }}
          </span>
          </td>
        </tr>
        </tbody>
      </table>
    </b-modal>
    <link-button @click="showModal()" icon="graph-up">
      <span class="ml-1 pr-5">{{ $t(`Auction Increments`) }}</span>
    </link-button>
  </div>
</template>

<script>
import LinkButton from "@/components/LinkButton";
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";

export default {
  name: "AuctionIncrementsLink",
  components: {LinkButton},
  mixins: [auctionViewMixin],
  data: () => ({
    displayModal: false,
  }),
  methods: {
    showModal() {
      this.displayModal = true;
    }
  }
}
</script>

<style scoped>

</style>
