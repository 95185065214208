<template>
    <input-widget
      id="idSellAnItemCategoryInput"
      type="select"
      v-model="model"
      :rules="required ? 'required' : ''"
      :error="sellAnItemModal.errors.category_uuid"
      :options="categoryOptions"
      :label="$t('Category')"
      @change="onCategoryChange"
    />
</template>

<script>
import {mapMutations, mapState} from "vuex";
import InputWidget from "@/components/core/InputWidget.vue";
import baseInputMixin from "@/components/sell-an-item-modal/baseInputMixin";

export default {
  name: "CategoryInput",
  components: {InputWidget},
  mixins: [baseInputMixin('', 'category_uuid')],
  computed: {
    ...mapState({
      sellAnItemModal: state => state.sellAnItemModal,
      categoriesMap: state => state.categoriesMap,
      department_uuid: state => state.sellAnItemModal.model.department_uuid
    }),
    categoryOptions() {
      const placeholder = {value: "", text: this.$t('Please select category')};
      if (!this.department_uuid) {
        return [placeholder];
      }
      return [
        placeholder,
        ...this.categoriesMap[this.department_uuid].map(category => ({
          value: category.key,
          text: this.$options.filters.currentLocale(category.name)
        }))
      ]
    }
  },
  methods: {
    ...mapMutations(['setSellAnItemModalFieldInput', 'resetSellAnItemErrors']),
    onCategoryChange(val) {
      if (val)
        this.resetSellAnItemErrors('category_uuid')
      this.setSellAnItemModalFieldInput({field: 'category_uuid', value: val})
    }
  }
}
</script>

<style scoped>

</style>
