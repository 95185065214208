import Vue from "vue";
import userConfig from "@/user-config";

export function asCurrency(amount, currency) {
  let format = Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    maximumSignificantDigits: 20,
  }).format(amount);

  const currencyFormat = userConfig.currencyFormat;
  const regEx = /\{number:\d{3}([\s,\.\‘'])\d{3}([\.\,])\d{2}\}/

  const matches = currencyFormat.match(regEx)
  if (!matches || matches.length < 3) {
    console.error('Invalid \"currencyFormat\"');
    return format;
  }

  const [, thousandSeparator, decimalSeparator] = matches;

  const symbolIdx = format.match(/[\d\.]+/).index;
  const symbol = format.slice(0, symbolIdx);
  format = format.replace(symbol, '');

  let decimals = "";
  const decimalsIdx = format.indexOf('.');
  if (decimalsIdx !== -1) {
    decimals = format.slice(decimalsIdx).replace('.', decimalSeparator);
    format = format.slice(0, decimalsIdx);
  }

  const thousands = format.split(',').join(thousandSeparator);

  return currencyFormat
    .replace(/\{symbol}/g, symbol)
    .replace(/\{code}/g, currency)
    .replace(regEx, (thousands + decimals));
}

Vue.filter('asCurrency', asCurrency)
