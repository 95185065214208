<template>
  <h1 class="ta-lot-title"
      v-html="$options.filters.stripTags($options.filters.currentLocale(lot.title))"></h1>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotViewTitle",
  computed: {
    ...mapState({
      lot: state => state.lotObject.data
    }),
  }
}
</script>

<style scoped>

</style>
