import { render, staticRenderFns } from "./LotListSwitchView.vue?vue&type=template&id=71cb309a&scoped=true&"
import script from "./LotListSwitchView.vue?vue&type=script&lang=js&"
export * from "./LotListSwitchView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71cb309a",
  null
  
)

export default component.exports