<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      id="input-place-of-issue"
      v-model="model"
      :error="errors"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
    />
  </div>
</template>

<script>
import AbstractSignupTextInput from "../core/AbstractSignupTextInput";
import BaseInputMixin from "../../../../mixins/signupInputMixin";
import InputWidget from "@/components/core/InputWidget.vue";

export default {
  name: "PlaceOfIssue",
  components: {InputWidget, AbstractSignupTextInput},
  mixins: [BaseInputMixin('identification.place_of_issue')],

  data() {
    return {
      defaultLabel: this.$t("Place of Issue")
    }
  },
}
</script>

<style scoped>

</style>
