<script>
import {mapState} from "vuex";
import lotBiddingMixin from "../../mixins/lotBiddingMixin";
import thumbnailMixin from "@/mixins/thumbnailMixin";
import ModalLotDetails from "@/components/core/ModalLotDetails";
import BiddingStartPrice from "@/components/configuration/lot-bidding/BiddingStartPrice";
import BiddingInput from "@/components/configuration/lot-bidding/BiddingInput";
import BiddingActions from "@/components/configuration/lot-bidding/BiddingActions";
import BiddingBuyNowButton from "@/components/configuration/lot-bidding/BiddingBuyNowButton";
import BiddingMaxBidInput from "@/components/configuration/lot-bidding/BiddingMaxBidInput";
import ModalCloseButton from "@/components/core/ModalCloseButton";
import templatable from "@/mixins/templatable";

export default {
  name: "PlaceBidModal",
  components: {
    ModalCloseButton,
    ModalLotDetails,
    BiddingStartPrice,
    BiddingActions,
    BiddingInput,
    BiddingMaxBidInput,
    BiddingBuyNowButton
  },
  mixins: [lotBiddingMixin, templatable('placeBidModalTemplate'), thumbnailMixin],
  data: () => ({
    template: `
      <b-modal id="placeBidModal" static ok-only no-close-on-backdrop no-close-on-esc :ok-title="$t('Close')" @close="$emit('input', false)">
        <template #modal-header="{ close }">
          <h5 class="modal-title">{{ $t('Place a Bid') }}</h5>
          <ModalCloseButton @click="close"/>
        </template>
        <div id="mobile_lotViewBidding">
            [[template]]
        </div>
      </b-modal>
    `
  }),
  computed: {
    ...mapState(['mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data
    }),
    latestBids() {
      return this.lot.last_bids ? this.lot.last_bids.slice(0, 5) : []
    }
  }
}
</script>

<style scoped lang="scss">
.ta-lot-latest-bids {
  font-size: 90%;
}

.ta-automatic-bid-explanation {
  display: block;
  margin: 1rem 0;
}

.ta-latest-bids-lot-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.ta-latest-bids-lot-image {
  width: 120px;
  border-radius: 10px;
}

.lot-bidding-layout-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
</style>

<style lang="scss">
@import "../../bootstrap/import";

#artisioTimedAuctions {
  #mobile_lotViewBidding {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  @include media-breakpoint-down(md) {
    #placeBidModal {
      .ta-lot-bid-input {
        display: block;
      }
    }
  }
}
</style>
