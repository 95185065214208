<template>
  <small class="ta-lot-lotno text-nowrap d-block mb-1">
    <label v-if="label === true" class="ta-lot-view-lotno-label">{{ $t('Lot') }}: &nbsp;</label>
    <label v-else-if="label"  class="ta-lot-view-lotno-label">{{ label }}</label>
    <span class="ta-lot-ID">{{ lot.lot_no | lotno }}</span>
  </small>
</template>

<script>
export default {
  name: "LotNo",
  props: {
    label: {
      type: [Boolean, String],
      default: true
    },
    lot: {
      required: true,
      type: Object
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

  .ta-lot-ID {
    color: $primary;
    font-weight: bold;
  }
</style>
