<template>
  <SocialShareIcons v-if="!mainConfig.sharesInSidebar" :lot="lot"/>
</template>

<script>
import {mapState} from "vuex";
import SocialShareIcons from "../../lot-view/SocialShareIcons";

export default {
  name: "LotViewSocialShare",
  components: {SocialShareIcons},
  computed: {
    ...mapState(['mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data
    })
  }
}
</script>

<style scoped>

</style>
