<template>
  <div class="row" v-if="category_uuid">
    <template v-for="dynamicField in dynamicFields">
      <div
        :class="dynamicField.css_class ? dynamicField.css_class : {
                'col-6': dynamicField.type !== 'ckeditor',
                'col-12': dynamicField.type === 'ckeditor',
              }"
        :key="dynamicField.name"
      >
        <input-widget
          :type="typeMap[dynamicField.type]"
          :label="$t(dynamicField.label)"
          :id="'idSellAnItem' + dynamicField.name"
          :rules="getRules(dynamicField)"
          :options="getOptions(dynamicField)"
          v-model="model[dynamicField.name]"
          @change="onChange(dynamicField, $event)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import InputWidget from "@/components/core/InputWidget.vue";

export default {
  name: "DynamicFieldInputs",
  components: {InputWidget},
  data: () => ({
    model: {},
    typeMap: {
      'text': 'text',
      'number': 'number',
      'select2': 'select',
      'ckeditor': 'textarea',
      'checkbox': 'checkbox',
    }
  }),
  computed: {
    ...mapState({
      category_uuid: state => state.sellAnItemModal.model.category_uuid,
      department_uuid: state => state.sellAnItemModal.model.department_uuid,
      sellAnItemModal: state => state.sellAnItemModal,
      dynamicFieldsMap: state => state.dynamicFieldsMap
    }),

    dynamicFields() {
      return (this.dynamicFieldsMap[this.category_uuid] || []).filter(f => !f.hide_on_website)
    },
  },
  watch: {
    'sellAnItemModal.flag': {
      handler() {
        this.model = {
          ...this.sellAnItemModal.model.dynamicFields
        }
        // for (let key in this.sellAnItemModal.model) {
        //   if (key.indexOf('dynamic_fields-') === 0) {
        //     this.model[key] = this.sellAnItemModal.model[key] || "";
        //   }
        // }
      },
    }
  },
  methods: {
    ...mapMutations(['setSellAnItemModalFieldInput']),
    getRules(dynamicField) {
      let rules = [];
      for (let i in dynamicField.rules) {
        if (i === "required" && dynamicField.rules[i]) {
          rules.push("required");
        } else if (i === 'min_value' || i === 'max_value') {
          rules.push(`${i}:${dynamicField.rules[i]}`);
        } else if (i === 'min_length' || i === 'max_length') {
          rules.push(`${i === 'min_length' ? 'min' : 'max'}:${dynamicField.rules[i]}`);
        } else if (i === 'choices') {
          rules.push('oneOf:' + dynamicField.rules[i].map(({value}) => value).join(','))
        }
      }
      return rules.join("|");
    },
    getOptions(dynamicField) {
      if (dynamicField.type !== 'select2') {
        return [];
      }
      return [
        {value: "", text: this.$t("Please Select")},
        ...dynamicField.rules.choices.map((choice) => {
          return {
            value: choice.value,
            text: typeof choice.key === 'object' ? this.$options.filters.currentLocale(choice.key) : choice.key
          }
        })
      ];
    },
    onChange(field, val) {
      this.setSellAnItemModalFieldInput({
        field: 'dynamicFields',
        value: this.model
      })
    }
  }
}
</script>

<style scoped>

</style>
