<template>
  <div v-if="isPagingTypeLoadMore" class="ta-load-more-wrapper mt-3 text-center">
    <button class="btn btn-primary" @click="updatePagination">
      {{ $t('Load More') }}
    </button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {updateAuctionsRoute} from "../../helpers";

export default {
  name: "AuctionListLoadMore",
  computed: {
    ...mapState(['auctionQueryParams']),
    ...mapGetters(['isPagingTypeLoadMore'])
  },
  methods: {
    ...mapActions(['getAuctions']),
    ...mapMutations(['setAuctionFilters', 'setPreserveOldAuctions']),
    updatePagination() {
      this.setPreserveOldAuctions(true);
      this.setAuctionFilters({page: parseInt(this.auctionQueryParams.page) + 1});
      this.getAuctions();
    }
  }
}
</script>

<style scoped>

</style>
