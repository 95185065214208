<script>
import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
import i18n from '../i18n';
import logger from "../logger";
import InputWidget from "./core/InputWidget.vue";
import ImagePicker from "./core/ImagePicker";
import {saveTemporaryItem} from "../store/actions";
import store from "../store"
import DepartmentInput from "@/components/sell-an-item-modal/DepartmentInput.vue";
import CategoryInput from "@/components/sell-an-item-modal/CategoryInput.vue";
import DynamicFieldInputs from "@/components/sell-an-item-modal/DynamicFieldInputs.vue";
import QuantityInput from "@/components/sell-an-item-modal/QuantityInput.vue";
import ReserveInput from "@/components/sell-an-item-modal/ReserveInput.vue";
import ImageInput from "@/components/sell-an-item-modal/ImageInput.vue";
import {resetSellAnItemModel} from "@/store/mutations";
import templatable from "@/mixins/templatable";

export default {
  name: "SellAnItemModal",
  components: {
    ImageInput,
    ReserveInput, QuantityInput, DynamicFieldInputs, CategoryInput, DepartmentInput, ImagePicker, InputWidget
  },
  mixins: [templatable('sellAnItemModalTemplate')],
  data: () => ({
    template: `
      <ValidationObserver v-slot="{invalid}" ref="form">
        <b-modal
          :title="$t('Sell an item')"
          v-model="sellAnItemModal.show"
          @hide="onModalHide"
          size="xl"
          static
          scrollable
          lazy
        >
          <form @submit.stop.prevent>
              [[template]]
          </form>
          <template #modal-footer>
            <div class="d-flex justify-content-end">
              <b-button variant="primary" class="mr-2" @click="save(false)" :disabled="invalid">
                {{ $t("Save") }}
              </b-button>
              <b-button variant="success" class="mr-2" @click="save(true)" :disabled="invalid">
                {{ $t("Save and add new") }}
              </b-button>
              <b-button variant="secondary" @click="close">
                {{ $t("Close") }}
              </b-button>
            </div>
          </template>
        </b-modal>
      </ValidationObserver>
    `
  }),
  computed: {
    ...mapState([
      "sellAnItemModal",
    ]),
    ...mapGetters(["currentLocale"]),
  },
  methods: {
    ...mapMutations(["toggleSellAnItemModal", 'showNotification', 'resetSellAnItemModel', 'setSellAnItemErrors']),
    ...mapActions(["getDepartmentsForSellAnItem", "saveTemporaryItem", 'getTemporaryItems']),
    close() {
      this.toggleSellAnItemModal(false);
    },
    onModalHide() {
      this.resetForm();
    },
    resetForm() {
      this.resetSellAnItemModel()
      // Wait until the models are updated in the UI
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    async save(addNew) {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }
      const formData = new FormData();
      let model = {
        ...this.sellAnItemModal.model,
        language: store.getters.currentLocale,
      };
      delete model.dynamicFields;

      for (let key in this.sellAnItemModal.model.dynamicFields) {
        formData.append(`dynamic_fields-${key}`, this.sellAnItemModal.model.dynamicFields[key])
      }

      for (let key in model) {
        if (key !== 'images') {
          if (model[key] !== '' && model[key] !== null) {
            formData.append(key, (model[key] || ''));
          }
        } else {
          for (let img of model.images) {
            formData.append('images', img)
          }
        }
      }

      const response = await this.saveTemporaryItem(formData)
      if (response.status === 201) {
        this.showNotification({
          title: i18n.t('Item saved.'),
          text: i18n.t("Your item has been submitted."),
          variant: 'success',
          duration: 5000
        });
        this.getTemporaryItems();
        if (addNew) {
          this.resetForm()
        } else {
          this.close();
        }
      } else if (response.status === 400 || response.status === 422) {
        this.setSellAnItemErrors(response.data.errors)
      } else {
        this.showNotification({
          title: i18n.t('Server error.'),
          text: i18n.t("Server error. Please try again."),
          variant: 'danger',
          duration: 5000
        });
      }
    },
  },
  mounted() {
    this.getDepartmentsForSellAnItem(false);
  },
};
</script>
<style scoped lang="scss">
</style>
