<template>
  <div v-if="lot.currency" class="ta-lot-start-price">
    <label v-if="label" class="ta-start-price-label">{{ label }}</label>
    <span class="ta-current-price-amount">
      {{ lot.start_price | asCurrency(lot.currency.code) }}
    </span>
  </div>
</template>

<script>
export default {
  name: "LotStartPrice",
  props: {
    lot: {
      required: true,
      type: Object
    },
    label: {
      required: false,
      type: [String, Boolean],
      default() {
        return this.$t('Start Price')
      }
    },
  },

}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

.ta-lot-start-price {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ta-current-price-amount {
    color: $primary;
    font-weight: bold;
    font-size: $font-size-base;
  }
}
</style>
