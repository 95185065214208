<template>
  <div class="ta-auction-countdown" v-if="auction.status !== 'completed'">
    <span v-if="auction.type === 'timed' && closing" class="ta-closing-right-now-caption">{{$t('Closing Right Now')}}</span>
    <count-down-timer v-else-if="mainConfig.showCountdown && auction.start_date"
                      :start_date="auction.start_date"
                      :end_date="auction.end_date"
                      :label="label"/>
  </div>
  <div v-else>
    <span v-if="auction.status === 'completed'" class="badge badge-secondary">{{ $t('Ended') }}</span>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CountDownTimer from "@/components/CountDownTimer";
import moment from "moment/moment";

export default {
  name: "AuctionCountdownTimer",
  components: {CountDownTimer},
  props: {
    auction: {
      required: true,
      type: Object
    },
    label: {
      type: [Boolean],
      default: true
    }
  },
  data: () => ({
    interval: null,
    closing: false
  }),
  computed: {
    ...mapState(['mainConfig'])
  },
  methods: {
    updateTimeDifference() {
      let endDate = this.auction.end_date;
      if (this.auction.type === 'live') {
        endDate = this.auction.start_date
      }

      let eventDate = typeof endDate === "string" ? moment(endDate).toDate().getTime() : endDate.getTime();
      endDate = moment(eventDate).add(this.timeDiff);

      this.closing = endDate < Date.now()
    },
  },
  mounted() {
    if (this.auction.type === 'timed' && !this.auction.end_date || this.auction.type === 'live' && !this.auction.start_date) {
      return;
    }
    this.updateTimeDifference();
    const interval = 1000;

    this.interval = setInterval(() => {
      this.updateTimeDifference();
    }, interval);
  },

  destroyed() {
    clearInterval(this.interval);
  },
}
</script>

<style lang="scss" scoped>
.ta-auction-countdown {
  display: inline-block;
}
.ta-closing-right-now-caption {
  white-space: nowrap;
}
</style>
