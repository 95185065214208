<template>
  <div>
    <div class="d-flex ta-checkbox-list-item">
      <span class="ta-checkbox-list-item-caret" v-b-toggle="`checkbox-list-child-${getTextSlug(option.value)}`"
            :class="{'invisible': !option.children.length}">
        <svg xmlns="http://www.w3.org/2000/svg" style="width: 16px;" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7"/>
        </svg>
      </span>
      <b-form-checkbox
        :value="true"
        v-model="selected[option.value]"
        @change="onCheckboxChange(option)"
      >
        {{ option.text | currentLocale }} <small v-if="option.count !== undefined">({{ option.count }})</small>
      </b-form-checkbox>
    </div>
    <b-collapse :visible="option.expanded" :id="`checkbox-list-child-${getTextSlug(option.value)}`"
                class="ta-checkbox-list-children">
      <checkbox-list-item v-for="child of computedOptions" :key="child.value"
                          :option="child"
                          v-model="selected"
                          @change="onChildCheckboxChange(option, child)"/>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "CheckboxListItem",
  props: {
    option: Object,
    options: Array,
    value: [Object]
  },
  data: () => ({
    selected: {},
    childrenSelected: {},
    childrenExpanded: {}
  }),
  computed: {
    computedOptions() {
      if (!this.filterText) {
        return this.option.children;
      }
      const cl = this.$options.filters.currentLocale;
      return this.option.children.filter((op) => cl(op.text).toLowerCase().includes(this.filterText.toLowerCase()));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.childrenExpanded[this.option.value] = this.option.expanded
        this.updateSelected()
      }
    },
    computedOptions: {
      immediate: true,
      handler() {
        this.expandChildren()
      }
    }
  },
  methods: {
    onCheckboxChange(option) {
      for (const op of option.children) {
        this.selected[op.value] = this.selected[option.value];
      }
      this.emitEvents();
    },
    onChildCheckboxChange(option, childOption) {
      this.selected[option.value] = this.hasAllChildrenSelected(option)
      this.emitEvents();
    },
    emitEvents() {
      const selected = Object.fromEntries(Object.entries(this.selected).filter(([key, value]) => value));

      this.$emit('input', selected);
      this.$emit('change', selected);
    },
    updateSelected() {
      this.selected = {...this.value}
    },
    getTextSlug(text) {
      return text.toLowerCase().replace(/\s+/g, '-')
    },
    hasChildSelected(option) {
      for (const op of option.children) {
        if (this.selected[op.value]) return true;
      }

      return false;
    },
    hasAllChildrenSelected(option) {
      for (const op of option.children) {
        if (!this.selected[op.value]) return false;
      }

      return true;
    },
    expandChildren() {
      for (const option of this.option.children) {
        if (this.hasChildSelected(option) && !this.selected[option.value]) {
          this.childrenExpanded[option.value] = true;
        }
      }
    }
  },
  mounted() {
    // this.updateSelected();
  }
};
</script>

<style lang="scss" scoped>
.ta-checkbox-list {
  max-height: 300px;
  overflow: auto;
}

.ta-checkbox-list-item-caret {
  > svg {
    position: relative;
    top: 1px;
    margin-right: 0.5rem;
    transform-origin: center;
    transition: transform 0.3s;
  }

  &.not-collapsed > svg {
    transform: rotate(90deg);
  }
}

.ta-checkbox-list-children {
  padding-left: 2rem;
}

</style>
