<template>
  <div>
    <div class="ta-buyer-premium-terms-wrapper alert alert-info">
      <p class="ta-buyer-premium-statement">{{ $t('The following buyer premiums will apply') }}</p>
      <div>
        <div v-for="(premium, ind) of lot.auction.premium_terms" :key="ind" class="ta-buyer-premium-tier">
          <div>{{ premium.percent }}% {{ $t('premium') }}</div>
          <div>{{ $t('Over') }} {{ premium.amount_over | asCurrency(lot.currency.code) }}</div>
        </div>
      </div>
    </div>

    <div class="ta-tax-rate-info-message">
      <div v-if="!lot.is_vat_applicable_for_hammer_price">
        {{ $t('VAT does not apply on the Hammer Price') }}
      </div>
      <div v-else>
        {{ $t('VAT - {vat_rate} applies on the Hammer Price', {vat_rate: lot.tax_rate + "%"}) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaxRateAndPremiums",
  props: {
    lot: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.ta-buyer-premium-tier {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.ta-tax-rate-info-message {
  margin-bottom: 1rem;
}

#artisioTimedAuctions {
  .ta-buyer-premium-statement {

  }
}
</style>
