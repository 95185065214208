<template>
  <b-modal id="taMsgModal"
           v-model="modalMsgBox.visible"
           :title="modalMsgBox.title"
           :header-bg-variant="modalMsgBox.headerBgVariant"
           :header-text-variant="modalMsgBox.headerTextVariant"
           ok-only
           ok-variant="secondary"
           :ok-title="$t('Close')"
           static
           centered>
    <template #modal-header="{ close }">
      <h5 class="modal-title" v-html="modalMsgBox.title"></h5>
      <ModalCloseButton @click="close()" />
    </template>
    <span v-html="modalMsgBox.content"></span>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import ModalCloseButton from "@/components/core/ModalCloseButton";

export default {
  name: "AtaMsgModal",
  components: {ModalCloseButton},
  computed: {
    ...mapState(['modalMsgBox'])
  },
}
</script>

<style lang="scss" scoped>

</style>
