<template>
  <div>
    <div v-if="toast.visible" class="alert" :class="`alert-${toast.variant}`">
      <h6>{{toast.title}}</h6>
      <small>{{toast.text}}</small>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ToastNotification",
  computed: {
    ...mapState(['toast'])
  }
}
</script>

<style scoped>

</style>
