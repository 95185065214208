const PRESETS = {
  lite: {
    showPrevNextList: false,
    jumpToLot: false,
    alwaysShowCategories: true,
    defaultPage: '/lots',
    showLatestBids: true,
    filterLotStatusOptions: ['open', 'upcoming', 'published', 'passed'],
    templates: {
      homePageTemplate: `
        <home-departments />

        <lot-list-items />
      `,
      auctionListPageTemplate: `
          <auction-list-filters />
          <div class="ta-auction-section">
            <div class="ta-page-header">
                <h3 class="ta-page-title">Auctions</h3>
            </div>
            <div class="ta-auction-list-header border bg-white py-2 px-3 mb-4">
              <per-page-select />
              <div class="ta-auction-search-wrapper">
                <sorting-dropdown/>
                <switch-view/>
              </div>
            </div>
            <auction-list-items />
          </div>
      `,
      lotFiltersTemplate: `
          <lot-keyword-search/>
          <!-- Lot filtering by status radio list -->
          <filter-lot-status />
          <!-- Category list to filter lots based on. This component is only displayed if department is selected and categories are filtered by department -->
          <filter-category-list />
          <!-- Min/Max input fields to filter lots by price range -->
          <filter-price-range />
          <!-- Checkbox field to filter lots whether it has image or not -->
          <filter-with-image />
      `,
      lotListItemTemplate: `
        <div class="ta-lot-list-item-image-wrapper">
            <lot-list-item-image :lot="lot" />
            <lot-add-to-watchlist :lot="lot" />
        </div>
        <div class="ta-lot-list-item-content">
          <div class="ta-lotno-bids-info">
            <lot-no :lot="lot" />
            <lot-number-of-bids :lot="lot" />
          </div>
          <div class="ta-lot-list-item-desc">
            <lot-list-item-title :lot="lot" />
            <lot-collection-information :lot="lot" />
          </div>
          <div class="ta-lot-list-item-buttons">
            <div class="text-left">
              <label class="d-block">{{ $t('Current Bid') }}</label>
              <LotActualPrice :lot="lot" />
            </div>
            <div class="text-right">
              <lot-countdown-timer :lot="lot" />
            </div>
          </div>
        </div>
      `,
      favoriteLotListItemTemplate: `
          <div class="ta-lot-list-item-image-wrapper">
              <lot-list-item-image :lot="lot" />
              <lot-add-to-watchlist :lot="lot" />
          </div>
          <div>
            <div class="ta-lot-list-item-desc">
                <lot-list-item-title :lot="lot" />
            </div>
            <div class="ta-lot-list-item-buttons">
              <div class="text-left">
                <label class="d-block">{{ $t('Current Bid') }}</label>
                <LotActualPrice :lot="lot" />
              </div>
              <lot-countdown-timer :lot="lot" />
            </div>  
          </div>
      `,
      lotListTemplate: `
        <lot-list-and-filters>
            <lot-list-filters />
            <div class="ta-lot-section">
                <div class="ta-page-header">
                    <h3 class="ta-page-title">Lots</h3>
                </div>
                <div class="ta-lot-list-header border bg-white py-2 px-3 mb-4">
                    <lot-list-per-page-select />
                    <lot-list-items-count />
                    <div class="ta-lot-search-wrapper">
                      <sorting-dropdown/>
                      <lot-list-switch-view/>
                      <lot-list-filter-mobile-button/>
                    </div>
                </div>
                <lot-list-items />
            </div>
        </lot-list-and-filters>
    `,
      favoriteLotListTemplate: `
          <lot-list-and-filters>
              <div class="ta-lot-section">
                <div class="ta-page-header">
                  <h3 class="ta-page-title">Watchlist</h3>
                </div>
                  <favorite-lot-list-items />
              </div>
          </lot-list-and-filters>
      `,
      lotViewTemplate: `
          <lot-view-header>
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="ta-lot-breadcrumbs">
                  <lot-view-breadcrumbs />
                </div>
              </div>
            </div>
          </lot-view-header>
          <div class="ta-lot-title-wrapper">
              <lot-view-title/>
              <h6 class="ta-lot-subtitle">{{dynamicFieldValues.subtitle}}</h6>
          </div>
          <div class="row ta-lot-view-wrapper">
              <div class="col-lg-8 ta-lot-view-content">
                  <lot-view-images/>
                  <lot-view-social-share/>

                  <lot-view-description />
                  <lot-add-to-watchlist :lot="lot" />
              </div>
              <div class="col-lg-4 ta-lot-view-bidding py-4">
                  <lot-view-bidding />
              </div>
          </div>

          <div class="ta-bidding-actions-buttons-on-mobile">
              <trigger-bid-buy-modal-button />
              <social-share-popup />
          </div>
      `,
      lotBiddingTemplate: `
        <div class="ta-lot-bidding d-flex flex-column">
          <div class="ta-lot-bidding-content flex-grow-1">
            <bidding-latest-notification/>
            <bidding-reserve-not-met/>
            <hr>
            <div class="lot-bidding-layout-grid">
              <lot-view-lot-no :label="$t('Lot Number')"/>
              <lot-countdown-timer :lot="lot"/>
              <bidding-start-price/>
              <div class="mb-2">
                  <label class="muted-label">{{ $t('Current Bid') }}</label>
                  <LotLastBidAmount :lot="lot" />
              </div>
              <div class="mb-2">
                  <label class="muted-label">{{$t('Buy now')}}</label>
                  <LotBuyNowPrice :lot="lot" :label="false" :show-empty="true" />
              </div>
              <div class="mb-2">
                  <label class="muted-label">{{$t('No of bids')}}</label>
                  <LotNumberOfBids :lot="lot" />
              </div>
              <div class="mb-2">
                  <label class="muted-label">{{ $t('Watchlists') }}</label>
                  <div class="text-black-600">
                    <WatchlistCount :lot="lot" label="are watching" />
                  </div>
              </div>
              <div class="mb-2">
                  <label class="muted-label">{{ $t('Bid History') }}</label>
                  <ShowAllBidsLink />
              </div>
            </div>
            <bidding-actions>
              <bidding-input/>
              <bidding-max-bid/>
              <bidding-buy-now-button/>
            </bidding-actions>
            <login-signup-buttons/>
            <hr>
            <LatestBids/>
          </div>
        </div>
      `,
      auctionViewPageTemplate: `
        <auction-info/>
        <lot-list-and-filters>
            <lot-list-filters/>
            <div class="ta-lot-section">
              <div class="ta-page-header">
                    <h3 class="ta-page-title">Lots</h3>
                </div>
                <div class="ta-lot-list-header border bg-white py-2 px-3 mb-4">
                    <lot-list-per-page-select/>
                    <div class="ta-lot-search-wrapper">
                        <sorting-dropdown/>
                        <lot-list-switch-view/>
                        <lot-list-filter-mobile-button/>
                    </div>
                </div>
                <lot-list-items/>
            </div>
        </lot-list-and-filters>
      `,
      signupInputsTemplate: `
        <fieldset class="mb-4">
          <legend>
            {{ $t("Account Details") }}
          </legend>
          <input-signup-email v-model="model.email" :errors="errors.email" />
          <div class="row">
            <div class="col-12 col-md-6 mb-3">
              <input-signup-password v-model="model.password" :errors="errors.password"></input-signup-password>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-password-confirmation v-model="model.password_confirmation" :errors="errors.password_confirmation"></input-signup-password-confirmation>
            </div>
          </div>
        </fieldset>
    
        <fieldset class="mb-4">
          <legend>
            {{ $t("Profile information") }}
          </legend>
    
          <div class="row">
            <div class="col-12 col-md-6 mb-3">
              <input-signup-first-name v-model="model.first_name" :errors="errors.first_name"></input-signup-first-name>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-last-name v-model="model.last_name" :errors="errors.last_name"></input-signup-last-name>
            </div>
          </div>
    
          <div class="row">
            <div class="col-12 col-md-6 mb-3">
              <input-signup-title v-model="model.title" :errors="errors.title"></input-signup-title>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-personal-id v-model="model.personal_id" :errors="errors.personal_id"></input-signup-personal-id>
            </div>
          </div>
    
          <div class="row">
            <div class="col-12 col-md-6 mb-3">
              <input-signup-company-name v-model="model.company_name" :errors="errors.company_name"></input-signup-company-name>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-phone v-model="model.phone" :errors="errors.phone"></input-signup-phone>
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-is-company v-model="model.is_company"></input-signup-is-company>
            </div>
          </div>
        </fieldset>
    
        <fieldset class="mb-4">
          <legend>
            {{ $t("Billing Address") }}
          </legend>
          <div class="row">
            <div class="col-12 col-md-6 mb-3">
              <input-signup-address1 v-model="model.billing_address.address_1" :errors="errors.addresses[0][0][0].address_1" type="billing" />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-address2 v-model="model.billing_address.address_2" :errors="errors.addresses[0][0][0].address_2" type="billing" />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-country v-model="model.billing_address.country_code" :errors="errors.addresses[0][0][0].country_code" type="billing" />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-state v-model="model.billing_address.state" :errors="errors.addresses[0][0][0].state" type="billing" />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-city v-model="model.billing_address.city" :errors="errors.addresses[0][0][0].city" type="billing" />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-zip-code v-model="model.billing_address.zip_code" :errors="errors.addresses[0][0][0].zip_code" type="billing" />
            </div>
          </div>
        </fieldset>
    
        <fieldset class="mb-4">
          <div class="d-flex justify-content-between align-items-center">
            <legend>
              {{ $t("Shipping Address") }}
            </legend>
            <input-signup-same-as-billing-address v-model="sameAsBilling" />
          </div>
    
          <div class="row">
            <div class="col-12 col-md-6 mb-3">
              <input-signup-address1 v-model="model.shipping_address.address_1" :errors="errors.addresses[0][1][0].address_1" type="shipping" />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-address2 v-model="model.shipping_address.address_2" :errors="errors.addresses[0][1][0].address_2" type="shipping" />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-country v-model="model.shipping_address.country_code" :errors="errors.addresses[0][1][0].country_code" type="shipping" />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-state v-model="model.shipping_address.state" :errors="errors.addresses[0][1][0].state" type="shipping" />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-city v-model="model.shipping_address.city" :errors="errors.addresses[0][1][0].city" type="shipping" />
            </div>
            <div class="col-12 col-md-6 mb-3">
              <input-signup-zip-code v-model="model.shipping_address.zip_code" :errors="errors.addresses[0][1][0].zip_code" type="shipping" />
            </div>
          </div>
        </fieldset>
      `,
    },
  },
};

export default PRESETS
