var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mainConfig.showPrevNextList)?_c('div',{staticClass:"ta-lot-next-previous d-flex justify-content-between align-items-center text-sm"},[_c('a',{class:{
      'd-flex align-items-center': true,
      'disabled-anchor': !_vm.lotObject || !_vm.lotObject.data || !_vm.lotObject.data.previous,
      'text-muted': !_vm.lotObject || !_vm.lotObject.data || !_vm.lotObject.data.previous,
      },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.previousLot()}}},[_c('svg',{staticClass:"w-4 mr-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 19l-7-7 7-7"}})]),_vm._v(" "+_vm._s(_vm.$t('Previous'))+" ")]),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.backToList()}}},[_vm._v(" "+_vm._s(_vm.$t('Back to List'))+" ")]),_c('a',{class:{
      'd-flex align-items-center': true,
      'disabled-anchor': !_vm.lotObject || !_vm.lotObject.data || !_vm.lotObject.data.next,
      'text-muted': !_vm.lotObject || !_vm.lotObject.data || !_vm.lotObject.data.next,
      },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.nextLot()}}},[_vm._v(" "+_vm._s(_vm.$t('Next'))+" "),_c('svg',{staticClass:"w-4 ml-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }