import {mapState, mapMutations} from "vuex";
import {updateFavoriteLotsRoute, updateLotsRoute} from "../helpers";

export default {
  computed: {
    ...mapState(['lotQueryParams', 'favoriteLotQueryParams']),
    fields() {
      const mapper = {
        'FavoriteLots': {
          queryParams: this.favoriteLotQueryParams,
          setFilters: this.setFavoriteLotFilters,
          updateRoute: updateFavoriteLotsRoute
        },
        'default': {
          queryParams: this.lotQueryParams,
          setFilters: this.setLotFilters,
          updateRoute: updateLotsRoute
        }
      }

      return this.$route.name in mapper ? mapper[this.$route.name] : mapper['default'];
    },
  },
  methods: {
    ...mapMutations(['setLotFilters', 'setFavoriteLotFilters'])
  },
}
