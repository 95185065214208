<template>
  <fieldset v-if="mainConfig.bankAccountDetails" class="mb-4">
    <slot></slot>
  </fieldset>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BankAccountDetails",
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style scoped>

</style>
