import Vue from 'vue'
import VueI18n from 'vue-i18n';
import messages from './messages.json'
import CONFIG from './user-config'
import axios from "./services/axios";
import logger from "@/logger";

Vue.use(VueI18n);

Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: CONFIG.lang,
  fallbackLocale: 'en',
  messages: messages
});

if (CONFIG.translations) {
  let msgs = CONFIG.translations
  for (let i in msgs) {
    i18n.setLocaleMessage(i, {
      ...messages[i],
      ...msgs[i]
    });
  }
}

if (CONFIG.translationsUrl) {
  axios.get(CONFIG.translationsUrl)
    .then(response => {
      let msgs = response.data
      try {
        if (typeof msgs === 'string') {
          msgs = JSON.parse(msgs)
        }
        for (let i in msgs) {
          i18n.setLocaleMessage(i, {
            ...messages[i],
            ...msgs[i]
          });
        }
      } catch(e) {
        logger.error('Unable to load and populate translation messages based on "translationsUrl". ' + e.message)
      }
    })
}
export default i18n;
