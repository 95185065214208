<template>
  <div class="mt-4">
    <a v-for="link in mainConfig.links"
       @click="linkHandler({event: $event, link})"
       :href="link.url"
       :key="link.url"
       :target="link.target || '_self'"
       class="btn-link d-flex justify-content-between align-items-center  btn-block mb-3">
      {{ link.text }}
      <svg style="width: 20px;" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
           stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
      </svg>
    </a>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "BiddingAdditionalLinks",
  computed: {
    ...mapState(['mainConfig'])
  },
  methods: {
    ...mapActions(['linkHandler'])
  }
}
</script>

<style scoped>

</style>
