<template>
  <div v-if="mainConfig.showBiddingLimit && lot && lot.auction && lot.auction.bidding_limit" class="ta-bidding-limit">
    <span>{{ $t('Your Bidding Limit') }}</span>
    <span class="ta-bidding-limit-amount">
      {{ lot.auction.bidding_limit | asCurrency(lot.currency.code) }}
    </span>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BiddingLimit",
  computed: {
    ...mapState(['mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data,
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

.ta-bidding-limit {
  margin: 1rem 0;
  display: flex;
  align-items: end;
  justify-content: space-between;
}

.ta-bidding-limit-amount {
  color: $primary;
  font-size: 110%;
  font-weight: bold;
}
</style>
