import {PAGINATION_LIMIT, PAGINATION_PAGE} from "../constants";
import logger from "../logger";

/**
 * Created by Zura on 2/24/2022.
 */
export default {
  data: () => ({
    limit: PAGINATION_LIMIT,
    page: PAGINATION_PAGE,
    filters: {
      q: null
    }
  }),
  watch: {
    "$route.query": {
      immediate: true,
      handler(newVal) {
        // make actions with newVal.page
        if (newVal.page) this.page = newVal.page;
        if (newVal.limit) this.limit = newVal.limit;

        for (const key in this.filters) {
          if (newVal[key]) this.filters[key] = newVal[key];
        }


        this.mutatePagination({page: this.page, limit: this.limit});
        this.getUpdatedItems();
      },
    },
  },
  methods: {

    updatePagination() {
      this.updateRoute();
    },
    updateRoute() {
      this.$router
        .push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: this.page,
            limit: this.limit,
            ...this.filters,
          },
        })
        .catch((err) => {
          logger.error("Failed to update route for items", err);
        });
    },
  }
}
