<template>
  <div v-if="lot.winning_bid" class="d-flex align-items-end justify-content-between">
    <span>{{ $t('Current Bid') }}</span>
    <span class="text-primary text-large font-weight-bold">
      {{ lot.winning_bid.amount | asCurrency(lot.currency.code) }}
    </span>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BiddingCurrentBid",
  computed: {
    ...mapState({
      lot: state => state.lotObject.data,
    })
  }
}
</script>

<style scoped>

</style>
