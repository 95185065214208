<template>
  <div v-if="lot.third_party_url" class="ta-third-party-url-wrapper">
    <a :href="lot.third_party_url" class="btn btn-primary btn-block btn-third-party-url">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gavel"
           class="svg-inline--fa fa-gavel fa-w-16" style="width: 16px;" role="img" xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 512 512">
        <path fill="currentColor"
              d="M504.971 199.362l-22.627-22.627c-9.373-9.373-24.569-9.373-33.941 0l-5.657 5.657L329.608 69.255l5.657-5.657c9.373-9.373 9.373-24.569 0-33.941L312.638 7.029c-9.373-9.373-24.569-9.373-33.941 0L154.246 131.48c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l5.657-5.657 39.598 39.598-81.04 81.04-5.657-5.657c-12.497-12.497-32.758-12.497-45.255 0L9.373 412.118c-12.497 12.497-12.497 32.758 0 45.255l45.255 45.255c12.497 12.497 32.758 12.497 45.255 0l114.745-114.745c12.497-12.497 12.497-32.758 0-45.255l-5.657-5.657 81.04-81.04 39.598 39.598-5.657 5.657c-9.373 9.373-9.373 24.569 0 33.941l22.627 22.627c9.373 9.373 24.569 9.373 33.941 0l124.451-124.451c9.372-9.372 9.372-24.568 0-33.941z"></path>
      </svg>
      {{ $t('Click here to bid') }}
    </a>
  </div>
</template>

<script>
import lotBiddingMixin from "@/mixins/lotBiddingMixin";

export default {
  name: "LotThirdPartyUrlButton",
  mixins: [lotBiddingMixin]
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

.ta-third-party-url-wrapper {
  padding: 2rem 0.5rem;
  background-color: $gray-100;
}

.btn-third-party-url {
  svg {
    transform: scaleX(-1);
    margin-right: 0.5rem;
    width: 16px;
  }
}
</style>
