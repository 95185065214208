<script>

import templatable from "@/mixins/templatable";
import lotBiddingMixin from "@/mixins/lotBiddingMixin";
import dynamicFieldsMixin from "@/mixins/dynamicFieldsMixin";
import StreamBidButton from "@/components/lot-view/sidebar/StreamBidButton.vue";
import BiddingLimit from "../lot-bidding/BiddingLimit.vue";
import WatchlistButton from "@/components/lot-view/sidebar/WatchlistButton.vue";
import BiddingActions from "@/components/configuration/lot-bidding/BiddingActions.vue";
import BiddingMaxBid from "@/components/configuration/lot-bidding/BiddingMaxBid.vue";
import LoginSignupButtons from "@/components/lot-view/sidebar/LoginSignupButtons.vue";
import BiddingAdditionalLinks from "@/components/configuration/lot-bidding/BiddingAdditionalLinks.vue";
import BiddingCommissionBidInput from "@/components/configuration/lot-bidding/BiddingCommissionBidInput.vue";
import BiddingLatestNotification from "@/components/configuration/lot-bidding/BiddingLatestNotification.vue";
import BiddingStartPrice from "@/components/configuration/lot-bidding/BiddingStartPrice.vue";

export default {
  name: "LotViewBiddingLive",
  components: {
    StreamBidButton,
    BiddingLimit,
    WatchlistButton,
    BiddingActions,
    BiddingMaxBid,
    BiddingCommissionBidInput,
    LoginSignupButtons,
    BiddingAdditionalLinks,
    BiddingLatestNotification,
    BiddingStartPrice
  },
  mixins: [lotBiddingMixin, templatable('lotBiddingTemplateLive'), dynamicFieldsMixin],
  data() {
    return {
      newBidAmount: null,
      interval: null,
      template: `<div id="lotViewBidding" class="ta-lot-bidding">
                      [[template]]
                  </div>
    `
    }
  },
}
</script>

<style lang="scss">
#artisioTimedAuctions {

  .ta-btn-watchlist {
    position: static !important;
  }
}
</style>

<style scoped lang="scss">
@import "../../../bootstrap/import";


.ta-lot-bidding-content {
  position: relative;
  background-color: $gray-100;
  padding: 1rem;
}
</style>
