<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      :id="`input-billing_address_zip_code`"
      v-model="model"
      :error="errors"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
      rules="required"
    />
  </div>
</template>

<script>
import BaseSignupTextInput from "../core/AbstractSignupTextInput";
import AbstractSignupTextInput from "../core/AbstractSignupTextInput";
import BaseInputMixin from "@/mixins/signupInputMixin";
import InputWidget from "@/components/core/InputWidget.vue";

export default {
  name: "BillingZipCode",
  components: {InputWidget, AbstractSignupTextInput, BaseSignupTextInput},
  mixins: [BaseInputMixin('billing_address.zip_code')],

  data() {
    return {
      name: 'billing_address.zip_code',
      defaultLabel: this.$t("Zip Code")
    }
  }
}
</script>

<style scoped>

</style>
