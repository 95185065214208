<template>
  <small v-if="mainConfig.enableBidding" class="ta-lot-no-of-bids">{{ lot.num_of_bids || 0 }} {{ $t('bids') }}</small>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotNumberOfBids",
  props: {
    lot: {
      required: true,
      type: Object
    },
  },
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

.ta-lot-no-of-bids {
  color: $text-muted
}
</style>
