export default {
  props: ['value'],
  data: () => ({
    model: null
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    }
  },
  methods: {
    inputChange() {
      this.$emit('input', this.model);
      this.$emit('change', this.model);
    }
  }
}
