<template>
  <div>
    <b-form-input
      size="sm"
      v-if="filterable"
      v-model="filterText"
      :placeholder="$t('Type to Filter')"
    />
    <div v-if="computedOptions.length" class="ta-checkbox-list mt-1">
      <checkbox-list-item v-for="option of computedOptions" :key="option.value"
                          :option="option"
                          v-model="selected"
                          @change="onCheckboxChange"/>
    </div>
    <div v-else class="text-center text-muted pt-3 pb-1">
      {{ $t('No Data') }}
    </div>
  </div>
</template>

<script>
import CheckboxListItem from "@/components/core/CheckboxListItem";

export default {
  name: "NestedCheckboxList",
  components: {CheckboxListItem},
  props: {
    options: Array,
    filterable: {
      type: Boolean,
      default: true,
    },
    value: [Array]
  },
  data: () => ({
    filterText: null,
    selected: {},
    childrenSelected: {},
    childrenExpanded: {}
  }),
  computed: {
    computedOptions() {
      if (!this.filterText) {
        return this.options;
      }

      return this.mapToNewTreeBasedOnFilter(
        JSON.parse(JSON.stringify(this.options))
      )

    },
    keyword() {
      return this.filterText.toLowerCase()
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.updateSelected()
      }
    },
    // computedOptions: {
    //   immediate: true,
    //   handler(){
    //     this.expandChildren()
    //   }
    // }
  },
  methods: {
    mapToNewTreeBasedOnFilter(options) {
      const cl = this.$options.filters.currentLocale;
      return options.filter((op) => {
        const text = cl(op.text).toLowerCase()
        const checkOption = text.includes(this.keyword)
        if (!op.children.length) return checkOption;
        op.children = this.mapToNewTreeBasedOnFilter(op.children)
        if (op.children.length) {
          op.expanded = true;
          return true;
        }
        return checkOption;
      });
    },
    onCheckboxChange(selected) {
      this.$emit('input', Object.keys(selected));
      this.$emit('change', Object.keys(selected));
    },
    updateSelected() {
      this.selected = Object.fromEntries(this.value.map(v => ([v, true])))
    },
  },
  mounted() {
    this.updateSelected();
  }
};
</script>

<style lang="scss" scoped>
.ta-checkbox-list {
  max-height: 300px;
  overflow: auto;
}

.ta-checkbox-list-item-caret {
  > svg {
    position: relative;
    top: 1px;
    margin-right: 0.5rem;
    transform-origin: center;
    transition: transform 0.3s;
  }

  &.not-collapsed > svg {
    transform: rotate(90deg);
  }
}

.ta-checkbox-list-children {
  padding-left: 2rem;
}

</style>
