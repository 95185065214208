<template>
  <div v-if="error.code" class="text-center">
    <h3>{{error.code}}, {{error.message}}</h3>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "PageComponent",
  computed: {
    ...mapState(['error'])
  }
}
</script>

<style scoped>

</style>
