<template>
  <a href="javascript:void(0)" v-b-modal.latestBidsModal class="ta-show-all-bids-link">
    {{$t('View All Bids')}}
  </a>
</template>

<script>

export default {
  name: "LatestBids",
}
</script>

<style scoped lang="scss">
</style>
