<template>
  <div class="col-sm-12 col-md">
    <InputWidget
      v-model="model"
      :error="errors"
      id="input-bank_account_account_name"
      type="text"
      :label="computedLabel"
      :placeholder="computedPlaceholder"
      @change="inputChange"
      @input="onInput"
      :rules="`${bankAccountFilledInProfile ? 'required' : ''}`"
    />
  </div>
</template>

<script>
import InputWidget from "../../../core/InputWidget";
import BaseInputMixin from "@/mixins/profileInputMixin";
import {mapGetters} from "vuex";

export default {
  name: "BankAccountBeneficiary",
  components: {InputWidget},
  mixins: [BaseInputMixin('bank_account.account_name')],

  data() {
    return {
      name: 'account_name',
      defaultLabel: this.$t("Account Name/Beneficiary")
    }
  },
  computed: {
    ...mapGetters(['bankAccountFilledInProfile'])
  }
}
</script>

<style scoped>

</style>
