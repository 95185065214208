import { render, staticRenderFns } from "./ShowAllBidsLink.vue?vue&type=template&id=dae5b5be&scoped=true&"
import script from "./ShowAllBidsLink.vue?vue&type=script&lang=js&"
export * from "./ShowAllBidsLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dae5b5be",
  null
  
)

export default component.exports