<template>
  <div class="ta-page-opt-out">
    <div v-if="!loading && !error" class="alert alert-success">
      {{message}}
    </div>
    <div v-else-if="!loading && error" class="alert alert-danger">
      {{message}}
    </div>
    <div v-else>
      <b-spinner size="sm" />
    </div>
  </div>

</template>

<script>
import axiosClient from "@/services/axios";

export default {
  name: "OptOut",
  data: () => ({
    loading: false,
    message: null,
    error: false
  }),
  mounted() {
    this.loading = true;
    axiosClient.post('/website/opt-out', {opt_out_token: this.$route.params.token})
      .then(res => {
        this.loading= false;
        this.message = res.data.message
      }).catch((e) => {
        this.message = e.response?.data?.message
        this.error = true;
        this.loading = false;
    })
  }
}
</script>

<style scoped>

</style>
