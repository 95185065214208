<template>
  <div class="ta-auction-keyword-search">
    <b-form-input
      :placeholder="$t('Search for auctions') + '...'"
      :value="auctionQueryParams.q"
      @change.native="updateFilters({q: $event.target.value})"></b-form-input>
  </div>
</template>

<script>
import {AUCTION_LIST_DEFAULT_PAGE} from "../../constants";
import {updateAuctionsRoute} from "../../helpers";
import {mapMutations, mapState} from "vuex";
import {auctionChangeFilters} from "@/triggers";

export default {
  name: "Auction",
  computed: {
    ...mapState(['auctionQueryParams'])
  },
  methods: {
    ...mapMutations(['setAuctionFilters']),
    updateFilters(filters){
      filters.page = AUCTION_LIST_DEFAULT_PAGE;
      this.setAuctionFilters(filters)
      updateAuctionsRoute()
      auctionChangeFilters()
    },
  }
}
</script>

<style lang="scss" scoped>
.ta-auction-keyword-search {
  flex: 1;
  margin-bottom: 1rem;
}
</style>
