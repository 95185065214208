<template>
  <div v-if="lot.buy_now_price || showEmpty" class="ta-lot-buy-now-price-wrapper">
    <div v-if="lot.buy_now_price && label" class="ta-lot-buy-now-price-label">
      {{ label }}
    </div>
    <span class="ta-lot-list-item-price ta-lot-buy-now-price">
      <template v-if="lot.buy_now_price">{{ lot.buy_now_price | asCurrency(lot.currency.code) }}</template>
      <template v-else>({{ $t('Empty')}})</template>
    </span>
  </div>
</template>

<script>
export default {
  name: "LotBuyNowPrice",
  props: {
    label: {
      type: [String, Boolean],
      default() {
        return this.$t('Buy now')
      }
    },
    lot: {
      required: true,
      type: Object
    },
    showEmpty: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>

</style>
