<template>
  <router-link :to="{ name: 'Login' }">
    <slot/>
  </router-link>
</template>

<script>
export default {
  name: "LinkToLogin"
}
</script>

<style scoped>

</style>
