<template>
  <div>
<!--    <div class="lot-info p-4" v-if="lotDescription">-->
<!--      <h4>{{ $t('Description') + ':' }}</h4>-->
<!--      <p v-html="lotDescription"></p>-->
<!--    </div>-->

<!--    <generic-collapse class="mt-3" object-attr="Collection Information" icon-name="compass">-->
<!--          <span>{{ lot.collection_info_city }}, {{ lot.collection_info_address_1 }}, {{-->
<!--              lot.collection_info_address_2-->
<!--            }}</span>-->
<!--    </generic-collapse>-->

<!--    <generic-collapse class="mt-3" object-attr="Auction Information" icon-name="menu-down">-->
<!--      <auction-dates :auction="auction">-->
<!--        <h6 class="font-weight-bolder">{{ $t('Dates') + ':' }}</h6>-->
<!--      </auction-dates>-->

<!--      <auction-description :auction="auction" class="mt-4">-->
<!--        <h5 class="font-weight-bolder">{{ $t('Description') + ':' }}</h5>-->
<!--      </auction-description>-->

<!--      <auction-viewing-dates :auction="auction" class="mt-4">-->
<!--        <h5 class="font-weight-bolder">{{ $t('Viewing Dates') + ':' }}</h5>-->
<!--      </auction-viewing-dates>-->

<!--      <auction-buyer-premiums :auction="auction" class="mt-4">-->
<!--        <h5 class="font-weight-bolder">{{ $t('Buyer Premiums') + ':' }}</h5>-->
<!--      </auction-buyer-premiums>-->

<!--      <auction-catalog-link :auction="auction" class="mt-4"/>-->
<!--    </generic-collapse>-->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "LotInfo",
  components: {
},
  props: {
  },

  computed: {
    ...mapState(['mainConfig' ]),
    ...mapState({
      lot: state => state.lotObject.data
    }),
    lotDescription() {
      return this.$options.filters.currentLocale(this.lot.dynamic_fields).description;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../bootstrap/import";

.lot-info {
  background-color: #f6f6f6;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
</style>
