<template>
  <div v-if="lot && lot.has_my_bid" class="ta-lot-winning-status">
    <template v-if="mainConfig.showReserveStatus">
      <div v-if="isCurrentUserLastBidder && reserveNotMet" class="ta-is-customer-winner ta-reserve-not-met">
        {{ $t('You are the highest bidder') }} ({{ $t('Reserve not met') }})
      </div>
      <div v-else-if="isCurrentUserLastBidder && reserveMet" class="ta-is-customer-winner">
        {{ $t('You are the highest bidder') }} ({{ $t('Reserve met') }})
      </div>
      <div v-else class="ta-is-customer-not-winner">
        {{ $t('You have been outbid') }}
      </div>
    </template>
    <template v-else>
      <div v-if="isCurrentUserLastBidder" class="ta-is-customer-winner">
        {{ $t('You are the highest bidder') }}
      </div>
      <div v-else class="ta-is-customer-not-winner">
        {{ $t('You have been outbid') }}
      </div>
    </template>
  </div>
</template>

<script>
import lotHelperMixin from "@/mixins/lotHelperMixin";
import {mapState} from "vuex";

export default {
  name: "LotWinningStatus",
  mixins: [lotHelperMixin],
  props: {
    lot: {
      required: true,
      type: Object
    },
  },
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";
.ta-lot-winning-status {
  font-size: 13px;
}
.ta-is-customer-winner {
  background-color: $success;
  padding: 0.25rem 1rem;
  color: white;

  &.ta-reserve-not-met {
    background-color: $warning;
  }
}

.ta-is-customer-not-winner {
  background-color: $danger;
  padding: 0.25rem 1rem;
  color: white;
}
</style>
