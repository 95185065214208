<template>
  <LotNo :lot="lot" :label="label" />
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "LotViewLotNo",
  props: {
    label: {
      type: [Boolean, String],
      default: true
    }
  },
  computed: {
    ...mapState({
      lot: state => state.lotObject.data
    }),
  }
}
</script>

<style lang="scss" scoped>

</style>
