<template>
  <b-pagination
    v-if="isPagingTypePagination && auctions.totalRows > fields.queryParams.limit"
    v-model="page"
    :total-rows="auctions.totalRows"
    :per-page="fields.queryParams.limit"
    @input="updatePagination"
    class="ta-lot-list-pagination"
  ></b-pagination>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "../../constants";
import auctionListFilterMixin from "../../mixins/auctionListFilterMixin";

export default {
  name: "PaginationButtons",
  mixins: [auctionListFilterMixin],
  data: () => ({
    page: LOT_LIST_DEFAULT_PAGE,
  }),
  computed: {
    ...mapState(['auctions']),
    ...mapGetters(['isPagingTypePagination'])
  },
  methods: {
    updatePagination() {
      this.fields.setFilters({page: this.page});
      this.fields.updateRoute();
    },
  },
  mounted() {
    this.page = this.fields.queryParams.page;
  }
}
</script>

<style scoped>

</style>
