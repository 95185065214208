const logger = {
  log(...args) {
    console.error.log(console, args)
  },
  info(...args) {
    console.error.info(console, args)
  },
  warning(...args) {
    console.error.warning(console, args)
  },
  error(...args) {
    console.error.apply(console, args)
  }
}

export default logger;
