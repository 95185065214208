<template>
  <div>
    <div v-if="!fromTransfer">
      <div v-if="lot.is_nft">
        <p v-for="(item, index) in lot.nft_data" :key="index">
          <span class="badge badge-warning">NFT</span>
          <a :href="getLink(item.chain_id, item.tx_hash)" target="_blank">
            {{ getText(item.tx_hash) }}
          </a>
        </p>
      </div>
    </div>
    <div v-else-if="lot.inventory.is_nft">
      <p v-for="(inventory_nft, index) in lot.inventory.inventory_nft" :key="index">
        <template v-if="inventory_nft.transfer && inventory_nft.transfer.length > 0 && inventory_nft.transfer[0].customer_uuid === customerMeta.uuid">
          <span class="badge badge-warning">NFT</span>
          <a :href="getLink(inventory_nft.chain_id, inventory_nft.transfer[0].tx_hash)" target="_blank">
            {{ getText(inventory_nft.transfer[0].tx_hash) | truncate(8)}}
          </a>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import i18n from "../../../i18n";

  export default {
    name: "LotViewNft",
    data() {
      return {
        chainMap: {
          "0x1": {name: i18n.t("Ethereum Main Network (Mainnet)"), url: 'https://etherscan.io'},
          "0x3": {name: i18n.t("Ropsten Test Network"), url: 'https://ropsten.etherscan.io'},
          "0x4": {name: i18n.t("Rinkeby Test Network"), url: 'https://rinkeby.etherscan.io'},
          "0x5": {name: i18n.t("Goerli Test Network"), url: 'https://goerli.etherscan.io'},
          "0x2a": {name: i18n.t("Kovan Test Network"), url: 'https://kovan.etherscan.io'},
        }
      }
    },
    methods: {
      getLink(chain_id, tx_hash) {
        if (this.chainMap[chain_id] && tx_hash) {
          return this.chainMap[chain_id].url + '/tx/' + tx_hash;
        }
        return '#';
      },
      getText(tx_hash) {
        if (tx_hash && tx_hash.length) {
          return tx_hash;
        }
        return '';
      },
    },
    computed: {
      ...mapGetters(['customerMeta']),
    },
    props: {
      lot: {
        type: Object,
      },
      fromTransfer: {
        type: Boolean,
        default: false
      }
    },
  }
</script>

<style scoped>

</style>
