<template>
  <b-button v-if="is_authorized && (auction.manual_bidder_approval || auction.initial_deposit_amount || auction.type === 'live') && !auction.is_bidder && auction.status === 'published'"
            @click="onClick"
            variant="primary"
            :block="block">
    <b-icon-person-plus-fill></b-icon-person-plus-fill>
    {{ $t('Register as Bidder') }}
  </b-button>
</template>

<script>
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import store from "@/store";
import i18n from "@/i18n";
import {asCurrency} from "@/filters/currency";

export default {
  name: "RegisterAsBidderButton",
  mixins: [],
  props: {
    auction: {
      type: Object
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['is_authorized']),
  },
  methods: {
    ...mapActions(['registerAsBidder']),
    ...mapMutations(['showNotification', 'showModalMsgBox']),
    async onClick() {
      try {
        const agreed = await this.$confirm(this.$t('Are you sure you want to register on this auction as a bidder?'))
        console.log(agreed);
        if (agreed) {
          const res = await this.registerAsBidder({auction: this.auction})
          if (!res.success) {
            if (res.error_code === 'DEPOSIT_REQUIRED') {
              const result = await this.$confirm(this.$t(res.error, {
                ...res.params,
                deposit_amount: this.$options.filters.asCurrency(res.params.deposit_amount, this.auction.currency.code)
              }))
              if (result) {
                const res = await this.registerAsBidder({auction: this.auction, force: true})
                if (res.success) {
                  this.showSuccess(i18n.t('You have successfully registered as bidder. Please check your email for farther instructions.'))
                } else {
                  this.showError(i18n.t(res.message || 'Something went wrong. Contact website support.'))
                }
              }
            } else {
              this.showError(i18n.t(res.error || 'Something went wrong. Contact website support.'))
            }
          } else {
            if (!res.status) {
              this.showSuccess(i18n.t('You have successfully registered as bidder.'))
              return
            }
            if (res.status === 'pending') {
              this.showWarning(i18n.t('Your bidder registration status is pending, you will be notified once you have been approved.'))
            } else if (res.status === 'rejected') {
              this.showError(i18n.t('You have been rejected from participating on this auction. Please contact our support if you have any questions.'))
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    showSuccess(message) {
      // show notification
      this.showNotification({
        title: i18n.t("Success!"),
        text: message,
        variant: "success",
      });
    },
    showWarning(message) {
      // show notification
      this.showModalMsgBox({
        title: i18n.t('Your status is pending'),
        content: i18n.t(message)
      });
    },
    showError(content) {
      this.showModalMsgBox({
        title: i18n.t('You have been rejected'),
        content: i18n.t(content)
      });
    }
  }
}
</script>

<style scoped>

</style>
