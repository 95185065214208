<template>
  <ValidationProvider :rules="rules" v-slot="slotData" :name="label" :vid="vid">
    <div class="mb-3">
      <template v-if="['text','number', 'password', 'select', 'textarea', 'email', 'phone', 'date'].indexOf(type) > -1">
        <label :for="id" v-bind:class="{ 'form-label': true, 'required': rules.indexOf('required') !== -1 }">{{
            label
          }}</label>
        <b-icon :icon="passwordHidden ? 'eye-fill' : 'eye-slash-fill'" v-if="showPasswordToggle && type === 'password'"
                class="ml-2" font-scale="1.2" @click="revealPassword"></b-icon>
        <b-input-group>
          <b-form-input
            v-if="['text', 'number', 'password', 'email', 'date'].indexOf(type) > -1"
            :state="getState(slotData)"
            v-model="model"
            :type="type"
            class="form-control"
            :id="id"
            :placeholder="placeholder"
            @blur="clearServerError"
            :autocomplete="autocomplete"
          />
          <PhoneInput v-if="type === 'phone'"
                      v-model="model"
                      :id="id"
                      :state="getState(slotData)"
                      :class="{'is-invalid': getState(slotData) === false, 'is-valid': getState(slotData) === true}"
                      @blur="clearServerError"/>
          <b-form-select
            v-else-if="type === 'select'"
            :state="getState(slotData)"
            v-model="model"
            :options="options"
          ></b-form-select>
          <b-form-textarea
            v-else-if="type === 'textarea'"
            :state="getState(slotData)"
            v-model="model"
            :type="type"
            class="form-control"
            :id="id"
            :placeholder="placeholder"
          />
          <template v-if="$slots.append" #append>
            <slot name="append"></slot>
          </template>
        </b-input-group>
        <b-form-invalid-feedback :state="getState(slotData)">
          {{ getError(slotData) }}
        </b-form-invalid-feedback>
      </template>
      <template v-else-if="type==='checkbox'">
        <b-form-checkbox
          :id="id"
          v-model="model"
          :value="checkbox_checked_value"
          :unchecked-value="checkbox_unchecked_value"
        >
          {{ label }}
        </b-form-checkbox>
      </template>
    </div>
  </ValidationProvider>
</template>

<script>
import { integer } from "vee-validate/dist/rules";
import PhoneInput from "@/components/core/PhoneInput";
import uuid from "uuid";

export default {
  name: "InputWidget",
  components: {PhoneInput},
  props: {
    value: [String, Array, Number, Boolean],
    type: {
      type: String,
      default: "text",
    },
    id: String,
    vid: {
      type: String,
      default() {
        return `vid-${this.attribute}-${uuid.v4()}`
      }
    },
    label: String,
    rules: {
      type: [String, Object],
      default: ''
    },
    placeholder: String,
    options: Array,
    maxRows: {
      type: Number,
      default: 6,
    },
    rows: {
      type: Number,
      default: 3,
    },
    showPasswordToggle: {
      type: Boolean,
      default: false
    },
    checkbox_checked_value: {
      required: false,
      default: "checked"
    },
    checkbox_unchecked_value: {
      required: false,
      default: ""
    },
    error: {
      type: [String, Array]
    },
    autocomplete: {
      type: String,
      required: false,
      default: "on",
    }
  },
  watch: {
    model(newValue) {
      this.$emit('input', newValue);
      this.$emit('change', newValue);
    },
    value: {
      immediate: true,
      handler() {
        if (this.value !== undefined && this.value !== this.model) {
          this.model = this.value;
        }
      }
    },
    error(newValue) {
      this.error_ = newValue
    }
  },
  data: () => ({
    model: "",
    error_: null,
    passwordHidden: true
  }),
  methods: {
    getState(slotData) {
      if (this.error_) {
        return false;
      }
      if (slotData.errors.length > 0) return false;
      if (!slotData.validated) return null;
      return slotData.valid;
    },
    getError(slotData) {
      if (this.error_) {
        if (Array.isArray(this.error_)) return this.error_.join(', ')
        return this.error_;
      }

      return slotData.errors[0]
    },
    revealPassword() {
      if (this.showPasswordToggle) {
        this.passwordHidden = !this.passwordHidden;
        document.querySelector(`#${this.id}`).type = this.passwordHidden ? 'password' : 'text';
      }
    },
    clearServerError() {
      this.error_ = null;
    }
  },
  mounted() {
    this.error_ = this.error;
  }
};
</script>

<style scoped>
.required:after {
  content: " *";
  color: red;
}
</style>
