<template>
  <small class="text-nowrap d-block mb-1">
    <span class="ta-auction-no">{{ auction.auction_no }}</span>
  </small>
</template>

<script>
export default {
  name: "AuctionNo",
  props: {
    auction: {
      required: true,
      type: Object
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import.scss";

  .ta-auction-no {
    font-weight: bold;
    color: $primary;
  }
</style>
