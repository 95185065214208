<template>
  <div class="ta-auth-card ta-signup-form">
    <div class="text-center">
      <h3 class="font-weight-bold mt-3">{{ $t("Personal Profile") }}</h3>
    </div>

    <FormAlert :success-message="successMessage" :error-message="errorMessage"/>

    <ValidationObserver ref="signupValidationObserver" v-slot="{handleSubmit }">
      <b-overlay :show="loading" rounded="sm">
        <form ref="profileForm" @submit.prevent="validateForm(handleSubmit)" autocomplete="off">
          <input autocomplete="false" name="hidden" type="text" style="display:none;">
          <profile-body />
          <LoadingButton :loading="loading" class="mr-3 ta-btn-submit">
            {{ $t("Save") }}
          </LoadingButton>
        </form>
      </b-overlay>
    </ValidationObserver>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import LoadingButton from "../components/core/LoadingButton.vue";
import InputWidget from "../components/core/InputWidget";
import {getGenericData} from "../store/actions";
import FormAlert from "../components/core/FormAlert";
import {deepClone} from "../helpers";
import ProfileBody from "@/components/configuration/profile/ProfileBody.vue";
import {profileFieldErrors, resetProfileModel, setProfileModel} from "@/store/mutations";

export default {
  name: "Profile",
  data() {
    return {
      loading: true,
      sameAsBilling: false,
      countries: null,
      titles: null,
      errorMessage: null,
      successMessage: null,
      bankAccountFilled: false,
    };
  },
  watch: {
    // sameAsBilling(newValue) {
    //   switch (newValue) {
    //     case "accepted":
    //       this.model.shipping_address = Object.assign({}, this.model.shipping_address, {...this.model.billing_address});
    //       break;
    //     default:
    //       Object.keys(this.model.shipping_address).forEach(key => this.model.shipping_address[key] = "");
    //       break;
    //   }
    // },
    // 'model.bank_account': {
    //   deep: true,
    //   handler(newValue) {
    //     this.bankAccountFilled = Object.keys(newValue).filter(key => newValue[key] !== '').length > 0
    //   }
    // },
    // passwordUpdateFields(newValue) {
    //   this.passwordUpdateFilled = newValue.split('|').reduce((prev, curr) => prev || curr, false);
    // }
  },
  computed: {
    ...mapGetters(["currentLocale", "availableLanguages", 'profilePasswordUpdateFilled']),
    ...mapState(["customer"]),
    ...mapState({
      model: state => state.profile.model,
      errors: state => state.profile.errors
    }),
    //
    // passwordUpdateFields() {
    //   return `${this.model.old_password}|${this.model.password}|${this.model.password_confirmation}`;
    // }
  },
  methods: {
    ...mapActions(["profileUpdate", "getGenericData", "getCustomerMeta", "socketLoginCallback"]),
    ...mapMutations(['setProfileModel', 'resetProfileModel', 'profileFieldErrors']),
    // eraseBankAccount() {
    //   Object.keys(this.model.bank_account).forEach(key => { this.model.bank_account[key] = "" });
    // },

    scrollToTop() {
      const invalidInput = this.$refs.profileForm.querySelector('.is-invalid');
      if (invalidInput) {
        invalidInput.scrollIntoView();
      }
    },

    constructPayload() {
      let payload = deepClone(this.model)

      if (!this.profilePasswordUpdateFilled) {
        delete payload.old_password;
        delete payload.password;
        delete payload.password_confirmation;
      }
      // if (!this.bankAccountFilled) {
      //   payload.bank_account = {};
      // }
      payload.is_company = !!payload.is_company;
      payload.subscribe_to_newsletter = !!payload.subscribe_to_newsletter;

      return payload;
    },

    onSubmit() {
      this.loading = true;
      this.profileUpdate({'payload': this.constructPayload()})
        .then(() => {
          this.loading = false;
          this.successMessage = this.$t('Profile updated successfully');
          this.setProfileModel({field: 'old_password', value: ''})
          this.setProfileModel({field: 'password', value: ''})
          this.setProfileModel({field: 'password_confirmation', value: ''})
          this.scrollToTop();
        })
        .catch((errors) => {
          this.loading = false;
          this.successMessage = null;

          let billing_address = [];
          let shipping_address = [];
          if (errors.addresses && errors.addresses[0]) {
            if (errors.addresses[0][0]) {
              billing_address = [...errors.addresses[0][0]]
            }
            if (errors.addresses[0][1]) {
              shipping_address = [...errors.addresses[0][1]]
            }
          }

          delete errors.addresses;
          this.profileFieldErrors({
            ...errors,
            billing_address,
            shipping_address
          })
          setTimeout(() => {
            this.scrollToTop();
          } ,500)
        });
    },

    async validateForm(handleSubmit) {
      // before validate
      await handleSubmit(this.onSubmit)
      // after validate
      this.scrollToTop()
    },
  },
  components: {ProfileBody, FormAlert, InputWidget, LoadingButton},
  async beforeMount() {
    this.countries = (await this.getGenericData('website/countries')).map(x => {
      const text = x.name | this.currentLocale
      return {'value': x.code, 'text': text ? text : x.name.en} //Fallback to "en"
    });
    this.countries.unshift({'text': 'Select Country...', 'value': null});

    this.titles = (await this.getGenericData('website/titles')).titles.map(title => {
      return {'value': title, 'text': title[0].toUpperCase() + title.slice(1)}
    });
    this.titles.unshift({'text': 'Select title...', 'value': null});

    this.socketLoginCallback()
      .then((customer) => {
        this.setProfileModel(customer)
        this.$nextTick(() => {
          this.$refs.signupValidationObserver.reset()
        })
        // Object.keys(this.model).forEach(i => {
        //   this.model[i] = !([undefined, null].includes(this.customer[i])) ? this.customer[i] : this.model[i]
        // });

        // const addressObj = {'billing_address': 'billing', 'shipping_address': 'shipping'}
        // for (const i in addressObj) {
        //   this.model[i] = this.customer.addresses.filter(address => address.type === addressObj[i] && address.is_primary)[0] ?? this.model[i];
        //   delete this.model[i].is_primary;
        //   delete this.model[i].type;
        // }
        this.loading = false;
      });
  },
  destroyed() {
    this.resetProfileModel()
  }
};
</script>

<style lang="scss" scoped>
@import "../bootstrap/import.scss";

#artisioTimedAuctions {
  .ta-signup-form {
    width: 800px;
    padding: 2rem;
  }

  .ta-signup-form {
    button {
      width: 140px;
    }
  }
}
</style>
