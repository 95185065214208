/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {[key]: {}});
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {[key]: source[key]});
      }
    }
  }

  return mergeDeep(target, ...sources);
}

/**
 * Converts global handler string into function
 *
 * @param handlerStr
 * @returns {Function}
 */
export function convertHandlerToFn(handlerStr) {
  if (typeof handlerStr === "function") {
    return handlerStr;
  }
  const parts = handlerStr.split(".");
  let fn = window;
  for (let part of parts) {
    fn = fn[part];
    if (!fn) {
      throw new Error(`"${handlerStr}" is not a valid function`);
    }
  }
  if (typeof fn !== "function") {
    throw new Error(`"${handlerStr}" is not a valid function`);
  }
  return fn;
}
