
<script>
import templatable from "@/mixins/templatable";

export default {
  name: "LotFilterList",
  mixins: [templatable('lotFiltersTemplate')],
  data: () => ({
    template: `
        <div>
            [[template]]
        </div>
    `
  }),
}
</script>

<style scoped>

</style>
