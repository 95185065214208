<template>
  <div class="col-md-6 mb-2">
    <link-button :href="auction.catalog && auction.catalog.url" target="_blank">
      <b-icon-collection/>
      <span class="ml-1">{{ $t('View Auction Catalog') }}</span>
    </link-button>
  </div>
</template>

<script>
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";
import LinkButton from "@/components/LinkButton";

export default {
  name: "AuctionCatalogLink",
  components: {LinkButton},
  mixins: [auctionViewMixin],
}
</script>

<style scoped>

</style>
