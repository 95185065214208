<template>
  <div class="ta-lot-watchlist-count">
    <span>{{ lot.watchlist_count || 0 }}</span>
    <label v-if="label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "WatchlistCount",
  props: {
    lot: {
      required: true,
      type: Object,
    },
    label: {
      type: String,
      default() {
        return this.$t('watching')
      }
    }
  },
}
</script>

<style lang="scss" scoped>
#artisioTimedAuctions {
  .ta-lot-watchlist-count {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
    span {
      display: inline-block;
    }

    label {
      margin-bottom: 0;
    }
  }
}
</style>
