<template>
  <filter-group :title="$t('Lot Status')"
                id="collapse-lot-statuses"
                class="ta-filter-group"
  >
    <div v-for="(label, status) in lotStatuses" :key="status">
      <b-form-radio name="lot-status" v-model="model" @change="filtersChanged" :value="status">
        {{ label }}
      </b-form-radio>
    </div>
    <b-form-checkbox v-if="model === 'sold' && is_authorized" v-model="secondaryModel" @change="filtersChanged"
                     value="yes" :unchecked-value="null" class="ml-2 mt-1">
      {{ $t('Sold To Me') }}
    </b-form-checkbox>
  </filter-group>
</template>

<script>
import FilterGroup from "../../FilterGroup";
import {mapGetters, mapMutations, mapState} from "vuex";
import {LOT_DEFAULT_STATUS, LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import {updateLotsRoute} from "../../../helpers";
import userConfig from "@/user-config";

export default {
  name: "FilterLotStatus",
  components: {FilterGroup},
  data: () => ({
    model: LOT_DEFAULT_STATUS,
    secondaryModel: null
  }),
  computed: {
    ...mapState(['lotQueryParams']),
    ...mapGetters(['is_authorized', 'lotStatuses'])
  },
  watch: {
    lotQueryParams() {
      this.model = this.lotQueryParams.lot_status;
      this.secondaryModel = this.lotQueryParams.sold_to_me;
    },
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged() {
      this.setLotFilters({
        lot_status: this.model,
        sold_to_me: this.secondaryModel,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      if (userConfig.autoApplyFilters) { // TODO We need to also check if the filters are active on mobile devices
        updateLotsRoute();
      }
    }
  },
  mounted() {
    this.model = this.lotQueryParams.lot_status ?? LOT_DEFAULT_STATUS;
    this.secondaryModel = this.lotQueryParams.sold_to_me;
    // this.filtersChanged();
  },
  destroyed() {
    this.setLotFilters({
      lot_status: LOT_DEFAULT_STATUS,
    });
  }
}
</script>

<style scoped>

</style>
