import Vue from 'vue';
import {mapState} from "vuex";
import {TEMPLATE_KEYWORD_COMPONENT_MAP} from "@/constants";

export default (templateName) => ({
  data: () => ({
    templateRenderer: null,
    // template: `
    //   <div>
    //     [[template]]
    //   </div>
    // `,
    components: {}
  }),
  computed: {
    ...mapState(['mainConfig']),
  },
  watch: {
    [`mainConfig.templates.${templateName}`]: {
      immediate: true,
      handler() {
        if(!this.template) {
          console.error(`Please provide template in "${this.$options.name}" component`);
          return;
        }
        // I decided to use [[template]] and not {{template}} because later one might be a variable in actual this.template
        let TEMPLATE = this.template.replace('[[template]]', this.mainConfig.templates[templateName]);

        // Merge global configurable components with local ones
        let componentShorthands = {...TEMPLATE_KEYWORD_COMPONENT_MAP, ...this.components};
        for (let key in componentShorthands) {
          TEMPLATE = TEMPLATE.replace(`{{${key}}}`, componentShorthands[key])
        }

        const res = Vue.compile(TEMPLATE);

        this.templateRender = res.render;

        // staticRenderFns belong into $options,
        // appearantly
        this.$options.staticRenderFns = []

        // clean the cache of static elements
        // this is a cache with the results from the staticRenderFns
        this._staticTrees = []

        // Fill it with the new staticRenderFns
        for (const i in res.staticRenderFns) {
          //staticRenderFns.push(res.staticRenderFns[i]);
          this.$options.staticRenderFns.push(res.staticRenderFns[i])
        }

      }
    }
  },
  render(h, context) {
    if (!this.templateRender) {
      return h('div', 'Loading...')
    }
    return this.templateRender();
  }
})
