<template>
  <div v-if="auctions.loading" class="ta-auction-list" :class="['view-type-' + (auctionListViewType)]">
    <div v-for="i in auctionQueryParams.limit" v-bind:key="`skeleton-${i}`" class="mb-2">
      <div class="ta-auction-list-item">
        <div class="ta-auction-list-item-inner">

          <div class="ta-auction-list-item-thumb">
            <b-skeleton-img no-aspect width="240px" height="180px"></b-skeleton-img>
          </div>
          <div class="ta-auction-list-item-desc">
            <b-skeleton class="mb-2" animation="wave" width="100%" height="30px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="200px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="60px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="80px"></b-skeleton>
            <b-skeleton class="mb-2" animation="wave" width="120px" height="30px"></b-skeleton>
          </div>
          <div class="ta-auction-list-item-buttons">
            <b-skeleton class="rounded-circle" animation="wave" width="30px" height="30px"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="auctions.results.length" class="ta-auction-list-items">
    <div  class="ta-auction-list" :class="['view-type-' + (auctionListViewType)]">
      <auction-list-item v-for="auction in auctions.results" :key="auction.uuid" :auction="auction">
      </auction-list-item>
    </div>
    <auction-list-load-more />
  </div>
  <div v-else>
    <p class="ta-no-auctions-text">
      {{ $t('There are no auctions') }}
    </p>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import AuctionListItem from "./AuctionListItem";
import AuctionListLoadMore from "./AuctionListLoadMore";

export default {
  name: "AuctionListItems",
  components: {AuctionListLoadMore, AuctionListItem},
  computed: {
    ...mapState(['auctions', 'auctionListViewType', 'auctionQueryParams']),
    ...mapGetters(['isPagingTypeLoadMore'])
  }
}
</script>

<style lang="scss" scoped>
@import "../../bootstrap/import.scss";
@import "../../scss/mixins";

.ta-no-auctions-text {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: $gray-600
}

.ta-auction-list {
  display: grid;
  grid-template-columns: 1fr;

  &.view-type-grid {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
  }
}

.ta-auction-list-items {
  overflow-y: auto;
  @include scrollbars();
}

@include media-breakpoint-down(xl) {
  .ta-auction-list.view-type-grid {
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  }
}

@include media-breakpoint-down(md) {
  .ta-auction-list {
    flex-direction: column;
  }
}

@include media-breakpoint-down(xs) {
  .ta-auction-list {
    column-gap: 1rem;
    row-gap: 1rem;
  }
}
</style>
