<template>
  <h4 class="ta-lot-list-item-title font-weight-bold">
    <router-link :to="lotInnerPageRoute" v-html="title">
    </router-link>
  </h4>
</template>

<script>
import lotHelperMixin from "@/mixins/lotHelperMixin";

export default {
  name: "LotListItemTitle",
  mixins: [lotHelperMixin],
  props: {
    lot: {
      required: true,
      type: Object
    },
  },
}
</script>

<style scoped>

</style>
