export default (defaultValue, field) => {
  return {
    props: {
      required: {
        type: Boolean,
        default: true
      }
    },
    data: () => ({
      model: defaultValue
    }),
    watch: {
      [`sellAnItemModal.model.${field}`]: {
        handler() {
          this.model = this.sellAnItemModal.model[field]
        },
        deep: true
      }
    },
  }
}
